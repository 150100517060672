import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
  } from "@ant-design/icons";
  import axios from "axios";
  import { ReactElement, useCallback, useEffect, useState } from "react";
  import { Avatar, Button, Drawer, Empty, Popconfirm, Switch } from "antd";
  import BreadCrumb from "../Layouts/Breadcrumb";
  import { responseNotification } from "../../utils/notify";
  import { getPage, getParamValue } from "../../utils";
  import { useLocation } from "react-router";
  import styles from "../../styles/tailwind/List.module.css";
  import { useSelector } from "react-redux";
  import SingleServiceType from "./Details";
  import AddServiceType from "./AddServiceType";
  import moment from "moment";
  import React from "react";
  import { AddBtn, Loader, Pagination } from "../common";
  
  const ServiceTypeList = (): ReactElement => {
    const { type, token } = useSelector((state) => (state as any)?.authReducer);
    const loc = useLocation();
    const page = getParamValue(loc.search, "page");
    const [limit, setLimit] = useState(16);
    const [status, setStatus] = useState("");
    const [showSearch, setShowSearch] = useState(true);
    const [isForLanding, setIsForLanding] = useState<boolean>(false);
    const [visible, setVisible] = useState<any>(undefined);
    const [selectedServiceType, setSelectedServiceType] = useState(undefined);
    const [selectedServiceTypeForEdit, setSelectedServiceTypeForEdit] =
      useState(undefined);
    const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showPopconfirm = (id: any) => {
      setVisiblePopconfirm(id);
    };
    const [serviceTypeData, setServiceTypeData] = useState<any>({
      loading: false,
      data: null,
    });
  
    const getServiceTypes = useCallback(async () => {
      setServiceTypeData({ loading: true, data: null });
  
      const encodedUri = `${process.env.REACT_APP_CATALOG_WRITER_API}`;
      axios
        .get(
          `${encodedUri}/shop/service-type-list?name=${''}` + 
            (page
              ? `&page=${
                  page == serviceTypeData.data?.currentPageNumber ? 0 : page || 0
                }`
              : ``)+
             (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setServiceTypeData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setServiceTypeData({ loading: false, data: [] });
          console.error("ServiceTypes: Error", err);
        });
    }, [limit,page]);
  
    //
    const onDelete = async (id: any, val: any) => {
      if (id) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            deleted: !val,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.statusCode === 200) {
              responseNotification("Status Updated Successfully", "success");
              getServiceTypes();
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    };
    const onServiceTypeRemove = async (serviceType: any) => {
      if (serviceType) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/banner`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            deleted: true,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.statusCode === 200) {
              responseNotification(
                "Service Type Remove Successfully",
                "success"
              );
              getServiceTypes();
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
    };
    useEffect(() => {
      getServiceTypes();
    }, [getServiceTypes]);
  
    const onClose = () => {
      getServiceTypes();
      setVisible(undefined);
      setSelectedServiceType(undefined);
      setSelectedServiceTypeForEdit(undefined);
    };
    const handleCancel = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setVisiblePopconfirm(undefined);
    };
  
    return (
      <>
        <BreadCrumb
          title="Assign Service Type List"
          subTitle={`${
            serviceTypeData?.data?.totalElements ||
            serviceTypeData?.data?.businessTypes?.length ||
            0
          } ServiceType(s)`}
          extra={[
            <AddBtn onClick={() => setVisible(true)} key={2} />,
          ]}
        />
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {serviceTypeData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Business Type</th>
                        <th scope="col">Business Value</th>
                        <th scope="col">Status</th>
                        <th scope="col">Updated By</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {serviceTypeData?.data?.businessTypes?.length ? (
                        serviceTypeData?.data?.businessTypes?.map(
                          (serviceType: any, index: any) => (
                            <tr
                              className="border-t hover:bg-gray-100"
                              key={index}
                              onClick={() => setSelectedServiceType(serviceType)}
                            >
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {serviceType?.name}
                                </span>
                              </td>
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {serviceType?.value}
                                </span>
                              </td>
                              <td>
                                <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={!serviceType?.deleted}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onDelete(serviceType?.id, val);
                                  }}
                                />
                              </td>
                             
                              <td>
                                <span className="font-medium text-gray-500 ml-2">
                                  {serviceType?.updatedBy?.name ||
                                    serviceType?.updatedBy?.mobileNumber}
                                </span>
                              </td>
                              <td>
                                <div className="flex gap-2">
                                  {/* <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={() =>
                                      setSelectedServiceTypeForEdit(serviceType)
                                    }
                                  >
                                    <EditOutlined />
                                  </Button> */}
                                  <Popconfirm
                                    placement="left"
                                    title="Are you sure to remove?"
                                    visible={serviceType?.id === visiblePopconfirm}
                                    onConfirm={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onServiceTypeRemove(serviceType);
                                    }}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                      className: "bg-blue-400",
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      className="bg-white"
                                      type="dashed"
                                      danger
                                      shape="round"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showPopconfirm(serviceType?.id);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </Button>
                                  </Popconfirm>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
  
          <Pagination
            {...serviceTypeData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
  
        <Drawer
          title={
            visible
              ? "Add Assign Service Type"
              : selectedServiceTypeForEdit
              ? "Edit Service Type"
              : "Service Type Details"
          }
          placement="right"
          onClose={onClose}
          open={visible || selectedServiceType || selectedServiceTypeForEdit}
          width={450}
        >
          {visible || selectedServiceTypeForEdit ? (
            <AddServiceType
              onCloseMethod={onClose}
              visibleData={selectedServiceTypeForEdit}
            />
          ) : (
            <SingleServiceType ServiceTypeDetails={selectedServiceType} />
          )}
        </Drawer>
      </>
    );
  };
  
  export default ServiceTypeList;
  