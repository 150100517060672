import { Button, Checkbox, DatePicker, Drawer, Form, Input, Modal, Select } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getPage, getParamValue, jcTypeArray } from "../../../utils/index";
import Empty from "../../common/Empty";
import moment from "moment";
import { responseNotification } from "../../../utils/notify";
import { useSelector } from "react-redux";
import ShopLayout from "../Layout";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination/Pagination";
import styles from "../../../styles/tailwind/List.module.css";
import OrderExtra from "./extraOrder";
import { SwapOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const ShopUnsettleOrderList = () => {
  const { driverInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [orderVisible, setOrderVisible] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const [selectedOrderId, setSelectedOrderId] = useState<any>([]);
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [usersData, setUsersData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [selectedUser, setSelectedUser] = useState(undefined);
  const route = useParams();

  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const showDrawer = () => {
    setOrderVisible(true);
  };
  const getUnsettledOrders = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setUsersData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/settlement/unsettle-order-for-shop` +
          `?shopId=${(route as any)?.shopId || ``}` +
          (type ? `&type=${type}` : ``) +
          (from ? `&startAt=${from}` : ``) +
          (to ? `&endAt=${to}` : ``) +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUsersData({ loading: false, data: res.data });
        setCheckedList(res.data.orders.map((order: any) => order?.orderId));
      })
      .catch((err) => {
        setUsersData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [type, range, route, page, limit]);

  const onCheckAllChange = (e: any) => {
    setSelectedOrderId(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  const onChangeCheckbox = (e: any) => {
    setSelectedOrderId((prevVal: any) => {
      const newVal = e;
      let newArr = Array.from(prevVal);
      const found = prevVal.find((item: any) => item === newVal);
      if (!found) {
        newArr.push(newVal);
      } else {
        newArr = newArr.filter((item) => item !== newVal);
      }
      return newArr;
    });

    setIndeterminate(
      !!checkedList.length &&
        checkedList.length <= selectedOrderId.length
    );
    setCheckAll(checkedList.length === selectedOrderId.length);
  };

  const onCashCollect = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      amount: data.amount * 1,
      note: data.note,
      deliveryManId: (route as any)?.shopId as string,
    };

    await fetch(
      `${process.env.REACT_APP_RIDER_API}/admin/transaction/cash-collection`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Cash Recieved Successfully!!!", "success");
          setVisible(!visible);
          form.resetFields();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  const reseAllFieldData = () => {
    form?.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };

  const onClose = () => {
    setOrderVisible(false);
  };
  useEffect(() => {
    getUnsettledOrders();
  }, [getUnsettledOrders]);

  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId as string}
        title="Unsettled Orders"
        extra={[
          usersData?.data?.orders?.length && (
            <Button
              shape="round"
              type="primary"
              onClick={() => showDrawer()}
              disabled={!selectedOrderId.length}
              title="Clone Product"
              className="bg-blue-600"
            >
              <SwapOutlined /> Action
            </Button>
          ),
        ]}
      >
        {showSearch && (
          <div className={`${styles.searchBox}`}>
            <Form form={form} layout="inline" className={styles.formInline}>
              <Form.Item initialValue={type}>
                <Select
                  placeholder="Shop Type"
                  onChange={(val) => setType(val)}
                  value={type}
                  style={{ width: 250 }}
                >
                  {Object.values(jcTypeArray)?.map((type, i) => (
                    <Option value={type} key={i}>
                      {type.replace("_", " ")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="range">
                <RangePicker
                  defaultValue={
                    range.from != null && range.to != null
                      ? [moment(range.from), moment(range.to)]
                      : null
                  }
                  onChange={(e, f) => {
                    setRange({
                      from: moment.utc(f[0])?.toISOString(),
                      to: moment.utc(f[1])?.toISOString(),
                    });
                  }}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss"),
                    ],
                  }}
                  inputReadOnly
                  allowClear
                />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              danger
              size="large"
              htmlType="reset"
              onClick={reseAllFieldData}
            >
              Reset
            </Button>
          </div>
        )}

        <div className={`content-body`}>
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-2">
            <table className={styles.mainTable}>
              <thead className="bg-white border-b">
                <tr>
                  <th scope="col">
                    <div className="checkbox-large">
                      <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={
                          checkAll ||
                          selectedOrderId?.length === checkedList?.length
                        }
                        className="mr-3"
                        disabled={!usersData?.data?.orders?.length}
                      >
                        Check all
                      </Checkbox>
                    </div>
                  </th>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Order ID</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              {usersData?.loading ? (
                <Loader />
              ) : (
                <tbody>
                  {usersData?.data?.orders?.length ? (
                    usersData?.data?.orders?.map((order: any, index: any) => (
                      <tr
                        className="border-t hover:bg-gray-100"
                        key={index}
                        onClick={() => setSelectedUser(order)}
                      >
                        <td>
                          <div className="checkbox-large flex items-center gap-2">
                            <Checkbox
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onChangeCheckbox(order?.orderId);
                              }}
                              checked={selectedOrderId?.find(
                                (item: any) => order?.orderId === item
                              )}
                              value={order?.orderId}
                            ></Checkbox>
                          </div>
                        </td>

                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {moment(order?.createdAt).format("lll")}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {order?.orderId}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            &#2547;{order?.total}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {order?.status}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        <Empty />
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
            <Pagination
              {...usersData?.data}
              limit={limit}
              page={getPage(loc.search)}
            />
          </div>
          <Drawer
        title={"Settlement Request"}
        placement="right"
        onClose={onClose}
        open={orderVisible}
        width={550}
      >
        <OrderExtra orderIds={selectedOrderId} shopId={(route as any)?.shopId} />
      </Drawer>
      
        </div>
      </ShopLayout>

      <Modal
        title="Cash Collect"
        visible={!!visible}
        //okText="Recieve Cash"
        //onOk={onCashCollect}
        confirmLoading={loading}
        destroyOnClose={true}
        onCancel={() => {
          setVisible(!visible);
        }}
        footer={false}
      >
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          onFinish={onCashCollect}
        >
          <Form.Item
            label="Enter Amount"
            name="amount"
            rules={[
              { required: true },
              {
                validator: async (_, value) => {
                  if (value > driverInfo?.balance) {
                    return Promise.reject(
                      new Error("Amount Must be Equal or less than Balance")
                    );
                  }
                },
              },
            ]}
          >
            <Input type="number" placeholder="Enter Collected Amount" />
          </Form.Item>

          <Form.Item name="note" label="Note" rules={[{ required: true }]}>
            <Input.TextArea
              placeholder="Please write some note or description..."
              rows={5}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Recieve Cash
          </Button>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ShopUnsettleOrderList;
