import {
  Alert,
  AutoComplete,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Switch,
} from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { ImageUploader, SubmitResetBtn, TreeSelectSlugCat } from "../common";
import axios from "axios";
import { debounce } from "lodash";
import { jcTypeArray } from "../../utils";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const AddReturnOrder = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cropImage, setCropImage] = useState(true);
  const [bannerData, setBannerData] = useState<any>(undefined);
  const [bannerType, setBannerType] = useState<any>(null);
  const [serviceType, setServiceType] = useState<any>(null);
  const [shopSlug, setShopSlug] = useState<any>();
  const [typeId, setTypeId] = useState<any>();
  const [mobileNumber, setMobileNumber] = useState("");
  const [isForLanding, setIsForLanding] = useState<boolean>(
    visibleData?.type === "JC_LANDING"
  );
  const [key, setKey] = useState("");
  const [dataOptions, setDataOptions] = useState({
    loading: false,
    list: [],
  });
  const [driversOptions, setDriversOptions] = useState({
    loading: false,
    list: null,
  });
  const [orderProductOptions, setOrderProductOptions] = useState({
    loading: false,
    list: [],
  });
  const [singleOrderInfo, setSingleOrderInfo] = useState<any>({
    loading: false,
    data: null,
  });
  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      deliveryMenId: mobileNumber,
      orderId: bannerData.orderId,
      orderNote: data.orderNote,
      products: data?.orders?.map((product: any, i: string) => ({
        productId: product.productId,
        quantity: parseInt(product.quantity),
        returnReason: product.returnReason,
      })),
    };

    await fetch(`${process.env.REACT_APP_ORDER_API}/admin/return-order`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(readyData),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Return Create Successfully", "success");
          form.resetFields();
          if (onCloseMethod) {
            onCloseMethod();
          }
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const fetchOrderDetails = useCallback((getOrderId: any) => {
    if (getOrderId) {
      try {
        setOrderProductOptions({ loading: true, list: [] });
        axios
          .get(
            `${process.env.REACT_APP_ORDER_API}/admin/order/details?orderId=${getOrderId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setOrderProductOptions({
                loading: false,
                list: data.data?.order?.products?.map(
                  (product: { name: any; id: any }) => ({
                    label: product.name,
                    value: product.id,
                  })
                ),
              });
            } else {
              setOrderProductOptions({ loading: false, list: [] });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setOrderProductOptions({ loading: false, list: [] });
          });
      } catch (error) {
        setOrderProductOptions({ loading: false, list: [] });
      }
    }
  }, []);
  const getDriversOptions = useCallback(
    async (mobileNumber: string) => {
      setDriversOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUri}/admin/driver?` +
            (mobileNumber
              ? `&mobileNumberOrName=${mobileNumber?.replace("+88", "")}`
              : ``) +
            (status ? `&status=${status}` : ``) +
            `&page=${0}` +
            `&limit=${20}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setDriversOptions({
            loading: false,
            list: res.data?.drivers?.map((driver: any) => ({
              label: `${driver.mobileNumber.replace("+88", "")} - ${
                driver.name
              }`,
              value: driver.mobileNumber.replace("+88", ""),
            })),
          });
        })
        .catch((err) => {
          setDriversOptions({ loading: false, list: null });
          console.error("Drivers: Error", err);
        });
    },
    [status]
  );

  useEffect(() => {
    getDriversOptions("");
  }, [getDriversOptions]);

  useEffect(() => {
    fetchOrderDetails(bannerData?.orderId);
  }, [fetchOrderDetails, bannerData]);

  useEffect(() => {
    if (visibleData) {
      setBannerData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (bannerData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [bannerData, form, visibleData]);

  const fetchRef = useRef(0);
  const getDriversOptionsDebounce = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setDriversOptions({ loading: false, list: null });
      if (fetchId !== fetchRef.current) {
        return;
      }
      getDriversOptions(value);
    };

    return debounce(loadOptions, 800);
  }, [getDriversOptions]);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        //if (field === "shops") getShopOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, []);

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...bannerData,
        }}
      >
        <Form.Item name="mobile">
          <AutoComplete
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={250}
            style={{ width: 250 }}
            onSearch={getDriversOptionsDebounce}
            onSelect={(val: any) => setMobileNumber(val?.toString())}
            options={driversOptions?.list || undefined}
            defaultActiveFirstOption={false}
            notFoundContent={
              driversOptions?.loading ? <Spin size="small" /> : null
            }
          >
            <Input.Search
              placeholder="Search by ID"
              onSearch={(val) => setMobileNumber(val)}
              enterButton
              loading={driversOptions.loading}
              size="large"
            />
          </AutoComplete>
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Order Note"
          rules={[
            {
              required: false,
              message: "Name is Required!",
            },
          ]}
          name="orderNote"
        >
          <Input id="orderNote" type="text" placeholder="Enter Order Note" />
        </Form.Item>

        <Form.List name="orders">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Card
                  title="Slect Return Order Product"
                  style={{ width: "100%", padding: "4" }}
                  className="mt-4"
                >
                  <Form.Item
                    {...restField}
                    hasFeedback
                    label="Product"
                    name={[name, "productId"]}
                    rules={[{ required: false, message: "Slect Product" }]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder={`Filter by product`}
                      optionFilterProp="children"
                      //onChange={(val) => setTypeId(val)}
                      // onSearch={(e) => handleSearch(e, bannerType?.toLowerCase())}
                      filterOption={() => {
                        return true;
                      }}
                      options={orderProductOptions?.list}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    hasFeedback
                    label="Quantity"
                    name={[name, "quantity"]}
                    rules={[{ required: false, message: "Missing quantity" }]}
                  >
                    <Input placeholder="Product Quantity" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    hasFeedback
                    label="Return Reason"
                    name={[name, "returnReason"]}
                    rules={[{ required: false, message: "Return Reason" }]}
                  >
                    <Input placeholder="Product Return Reason" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Card>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Return Product
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddReturnOrder;
