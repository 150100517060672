import React from "react";
import { ReactElement } from "react";
import ShopUnsettleOrderList from "../../../components/Shop/Order/UnsettleOrder";
import MainLayout from "../../../components/Layouts";

const ShopUnsettleOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <ShopUnsettleOrderList />
    </MainLayout>
  );
};

export default ShopUnsettleOrderPage;
