import {
  ApartmentOutlined,
  CheckCircleFilled,
  CopyOutlined,
  DeleteFilled,
  IssuesCloseOutlined,
  QuestionCircleOutlined,
  SoundOutlined,
  UserOutlined,
  WarningFilled,
} from "@ant-design/icons";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Switch,
  Drawer,
  Radio,
  Alert,
  Space,
} from "antd";
import axios from "axios";
import { TextLoop } from "react-text-loop-next";
import { responseNotification } from "../../../utils/notify";
import { getParamValue } from "../../../utils";
import { useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { SHOW_SEARCHBAR } from "../../../redux/search/searchType";
import React from "react";
import moment from "moment";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons";

const ProductExtra = ({
  driverId,
  orderIds,
}: {
  driverId: any;
  orderIds?: [];
}): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [showSearch, setShowSearch] = useState(true);
  const [form] = Form.useForm();
  const route = useParams();
  const dispatch = useDispatch();
  const [submitForm, setSubmitForm] = useState(false);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const pageType: any = getParamValue(loc.search, "page-type");
  const [key, setKey] = useState("");
  const [orderVisible, setOrderVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changeStatus, setChangeStatus] = useState<any>(undefined);
  const [visible, setVisible] = useState(undefined);

  const [productBarcodeOptions, setProductBarcodeOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const onClose = () => {
    setSubmitForm(false);
    setOpen(false);
    setOrderVisible(false);
  };

  const onSubmitShopToShop = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      deliveryManId: driverId,
      orderIds: orderIds,
    };
    await fetch(
      `${process.env.REACT_APP_ORDER_API}/admin/settlement/settlement-v2-deliveryMan`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification(
            "Settlement Request Successfully Done",
            "success"
          );
          onClose();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const onChildrenDrawerClose = () => {
    setChangeStatus(undefined);
    setChangeStatus(undefined);
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmitShopToShop}
        form={form} // like ref
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item label="Order ID">
          <Input.TextArea value={orderIds} disabled rows={8} />
        </Form.Item>
        <Button
          type="primary"
          danger
          title="Clone Selected Items"
          htmlType="submit"
          size="large"
          loading={(loading ? "loading" : undefined) as any}
        >
          Submit
        </Button>
      </Form>
    </>
  );
};

export default ProductExtra;
