import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Drawer,
  Empty,
  Form,
  Input,
  Popconfirm,
  Select,
  Spin,
  Switch,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getPage, getParamValue, jcTypeArray } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import SingleDirectory from "./Details";
import AddDirectory from "./AddDirectory";
import moment from "moment";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";
import { debounce } from "lodash";

const DirectoryList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [status, setStatus] = useState("");
  const [form] = Form.useForm();
  const [showSearch, setShowSearch] = useState(true);
  const [isForLanding, setIsForLanding] = useState<boolean>(false);
  const [visible, setVisible] = useState<any>(undefined);
  const [key, setKey] = useState("");
  const [selectedDirectory, setSelectedDirectory] = useState(undefined);
  const [selectedDirectoryForEdit, setSelectedDirectoryForEdit] =
    useState(undefined);
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [type, setType] = useState(initialState?.type);
  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [directoryData, setDirectoryData] = useState<any>({
    loading: false,
    data: null,
  });

  const [directoryOptions, setDirectoryOptions] = useState({
    loading: false,
    list: [],
  });

  const getDirectorys = useCallback(async () => {
    setDirectoryData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/file-directory/all?limit=${limit}` +
          (page
            ? `&page=${
                page == directoryData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (key ? `&key=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setDirectoryData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setDirectoryData({ loading: false, data: [] });
        console.error("Directorys: Error", err);
      });
  }, [limit, page, key]);

  const getDirectoryOptions = useCallback(
    async (key: any) => {
      setDirectoryOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url =
        `?page=0&limit=20` + (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/file-directory/all${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDirectoryOptions({
            loading: false,
            list: res.data?.fileDirectories?.map((directorie: { name: any,id:any }) => ({
              label: directorie.name,
              value: directorie.name,
            })),
          });
        })
        .catch((err) => {
          setDirectoryOptions({ loading: false, list: [] });
          console.error("Directorys: Error", err);
        });
    },
    [key, page, limit]
  );
  const onDirectoryRemove = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/file-directory`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            deleted: true,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Directory Remove Successfully", "success");
            getDirectorys();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "directory") getDirectoryOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getDirectoryOptions]);

  useEffect(() => {
    if (showSearch) {
      getDirectoryOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    getDirectorys();
  }, [getDirectorys]);

  const onClose = () => {
    getDirectorys();
    setVisible(undefined);
    setSelectedDirectory(undefined);
    setSelectedDirectoryForEdit(undefined);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
  };

  return (
    <>
      <BreadCrumb
        title="Directory List"
        subTitle={`${
            directoryData?.data?.totalElements ||
            directoryData?.data?.fileDirectories?.length ||
          0
        } Directory(s)`}
        extra={[<AddBtn onClick={() => setVisible(true)} key={2} />]}
      />
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search" className="pb-4">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "directory")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val?.toString())
                }
                options={directoryOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                    directoryOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={directoryOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {directoryData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {directoryData?.data?.fileDirectories?.length ? (
                      directoryData?.data?.fileDirectories?.map(
                        (fileDirectory: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                            onClick={() => setSelectedDirectory(fileDirectory)}
                          >
                          
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {fileDirectory?.name}
                              </span>
                            </td>
                            
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    fileDirectory?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(fileDirectory?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(fileDirectory?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {fileDirectory?.updatedBy?.name ||
                                  fileDirectory?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="bg-white"
                                  onClick={() =>
                                    setSelectedDirectoryForEdit(fileDirectory)
                                  }
                                >
                                  <EditOutlined />
                                </Button>
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={fileDirectory?.id === visiblePopconfirm}
                                  onConfirm={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onDirectoryRemove(fileDirectory?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(fileDirectory?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...directoryData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Directory"
            : selectedDirectoryForEdit
            ? "Edit Directory"
            : "Directory Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedDirectory || selectedDirectoryForEdit}
        width={450}
      >
        {visible || selectedDirectoryForEdit ? (
          <AddDirectory
            onCloseMethod={onClose}
            visibleData={selectedDirectoryForEdit}
          />
        ) : (
          <SingleDirectory DirectoryDetails={selectedDirectory} />
        )}
      </Drawer>
    </>
  );
};

export default DirectoryList;
