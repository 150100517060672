import {
    AutoComplete,
    Badge,
    Button,
    DatePicker,
    Drawer,
    Empty,
    Form,
    Input,
    Modal,
    Select,
    Spin,
  } from "antd";
  import {
    EditOutlined,
    EyeOutlined,
    FilePdfOutlined,
    SearchOutlined,
    SnippetsOutlined,
  } from "@ant-design/icons";
  import {
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from "react";
  import { useLocation, useNavigate } from "react-router";
  import axios from "axios";
  import moment from "moment";
  import BreadCrumb from "../Layouts/Breadcrumb";
  import {
    getPage,
    getParamValue,
    jcOrderTypeArray,
    jcTypeArray,
    OrderTypeArray,
    ReturnOrderTypeArray,
  } from "../../utils";
  import { debounce } from "lodash";
  import styles from "../../styles/tailwind/List.module.css";
  import { useSelector } from "react-redux";
  import SingleOrder from "./Details";
  import { isAllowedService } from "../../utils/services";
  import React from "react";
  import { PaginationThree, Loader } from "../common";
  import cn from "classnames";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faBiking, faDownload } from "@fortawesome/free-solid-svg-icons";
  import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
  import ShopOrderInvoice from "./shopOrderInvoice";
  import AddBanner from "./ReturnOrder";
  const { RangePicker } = DatePicker;
  
  const OrderList = ({
    inShop = false,
    inCampaign = false,
    getShopId,
    getCampaignId,
  }: {
    inShop?: boolean;
    inCampaign?: boolean;
    getShopId?: string;
    getCampaignId?: string;
  }): ReactElement => {
    const { token } = useSelector((state) => (state as any)?.authReducer);
    const initialState = useSelector((state) => (state as any)?.authReducer);
    const [type, setType] = useState(initialState?.type);
    const [orderType, setOrderType] = useState(initialState?.orderType);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const loc = useLocation();
    const page = getParamValue(loc.search, "page");
    const limit = getParamValue(loc.search, "limit");
    const [status, setStatus] = useState("");
    const [orderId, setOrderId] = useState("");
    const [shopId, setShopId] = useState<any>();
    const [campaignId, setCampaignId] = useState<any>();
    const [mobileNumber, setMobileNumber] = useState("");
    const [showSearch, setShowSearch] = useState(true);
    const [secondDrawer, setSecondDrawer] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<any>();
    const [pmethodId, setPmethodId] = useState("");
    const [visible, setVisible] = useState<any>(undefined);
    const [selectedBannerForEdit, setSelectedBannerForEdit] = useState(undefined);
    //shop order invoice
    const [showShopOrderInoice, setShowShopOrderInvoice] = useState(false);
    const [singleOrderInfo, setSingleOrderInfo] = useState<any>();
  
    const [paymentMethod, setPaymentMethod] = useState<any>({
      loading: false,
      list: [],
    });
    const [campaignOptions, setCampaignOptions] = useState({
      loading: false,
      list: [],
    });
    const [range, setRange] = useState<any>({
      from: null,
      to: null,
    });
  
    const [returnOrderData, setReturnOrderData] = useState<any>({
      loading: false,
      data: null,
    });
    const [shopOrderData, setShopOrderData] = useState<any>({
      loading: false,
      data: null,
    });
  
    const [orderOptions, setOrderOptions] = useState({
      loading: false,
      list: [],
    });
  
    const [shopOptions, setShopOptions] = useState({
      loading: false,
      list: [],
    });
  
    const [usersOptions, setUsersOptions] = useState({
      loading: false,
      list: [],
    });
  
    const [driversOptions, setDriversOptions] = useState({
      loading: false,
      list: [],
    });
  
    const onClose = () => {
      setSelectedOrder(undefined);
      setSecondDrawer(false);
      setSelectedOrder(undefined);
      setVisible(undefined);
      setSelectedBannerForEdit(undefined);
    };
    const getOrders = useCallback(async () => {
      const from = moment(range.from)?.toISOString();
      const to = moment(range.to)?.toISOString();
      setReturnOrderData({ loading: true, data: null });
  
      const encodedUrl = `${process.env.REACT_APP_ORDER_API}`;
      axios
        .get(
          `${encodedUrl}/admin/return-order/customer-returns?type=${type}` +
            (orderType ? `&orderType=${orderType}` : ``) +
            (orderId ? `&orderIdOrBaseOrderId=${orderId}` : ``) +
            (mobileNumber ? `&mobileNumber=${mobileNumber}` : ``) +
            (shopId
              ? `&shopId=${shopId}`
              : getShopId
              ? `&shopId=${getShopId}`
              : ``) +
            (campaignId
              ? `&campaignId=${shopId}`
              : getCampaignId
              ? `&campaignId=${getCampaignId}`
              : ``) +
            // (campaignId ? `&campaignId=${campaignId}` : ``) +
            (status ? `&status=${status}` : ``) +
            (pmethodId ? `&paymentMethod=${pmethodId}` : ``) +
            (from ? `&from=${from}` : ``) +
            (to ? `&to=${to}` : ``) +
            (page
              ? `&page=${
                  page == returnOrderData?.data?.currentPageNumber ? 0 : page || 0
                }`
              : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setReturnOrderData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setReturnOrderData({ loading: false, data: [] });
          console.error("Order: Error", err);
        });
    }, [
      limit,
      page,
      type,
      orderType,
      shopId,
      orderId,
      mobileNumber,
      status,
      pmethodId,
      range,
    ]);
  
    const getShopOrderInvoice = useCallback(async () => {
      setShopOrderData({ loading: true, data: null });
  
      const encodedUrl = `${process.env.REACT_APP_ORDER_API}`;
      axios
        .get(
          `${encodedUrl}/admin/order/invoice-by-shop` +
            (shopId
              ? `?shopId=${shopId}`
              : getShopId
              ? `?shopId=${getShopId}`
              : ``) +
            (status ? `&status=${status}` : ``) +
            (page
              ? `&page=${
                  page == shopOrderData?.data?.currentPageNumber ? 0 : page || 0
                }`
              : ``) +
            (limit ? `&limit=${limit}` : ``),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setShopOrderData({ loading: false, data: res.data });
        })
        .catch((err) => {
          setShopOrderData({ loading: false, data: [] });
          console.error("Order: Error", err);
        });
    }, [limit, page, shopId, status]);
  
    const getOrderOptions = useCallback(
      async (getOrderId: any) => {
        setOrderOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
        return axios
          .get(
            `${encodedUri}/admin/order/v2?page=${page || 0}` +
              (getOrderId ? `&orderIdOrBaseOrderId=${getOrderId}` : ``) +
              (type ? `&type=${type}` : ``) +
              (status ? `&status=${status}` : ``) +
              (limit ? `&limit=${limit}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setOrderOptions({
              loading: false,
              list: res.data?.orders?.map(
                (order: { baseOrderId: string; orderId: string }) => ({
                  label: `${order?.baseOrderId} || ${order?.orderId}`,
                  value: order?.baseOrderId,
                })
              ),
            });
          })
          .catch((err) => {
            setOrderOptions({ loading: false, list: [] });
            console.error("Categories: Error", err);
          });
      },
      [limit, page, status, type]
    );
  
    const getShopOptions = useCallback(
      async (key: any) => {
        setShopOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
        return axios
          .get(
            `${encodedUri}/shop/admin/search?page=0&limit=20` +
              (key ? `&key=${key}` : ``) +
              (type ? `&type=${type}` : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setShopOptions({
              loading: false,
              list: res.data?.shops?.map((shop: { id: any; name: any }) => ({
                value: shop.id,
                label: shop.name,
              })),
            });
          })
          .catch((err) => {
            setShopOptions({ loading: false, list: [] });
            console.error("Shops: Error", err);
          });
      },
      [type]
    );
    // const getCampaignOptions = useCallback(async (key?: string) => {
    //   setCampaignOptions({ loading: true, list: [] });
    //   const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    //   const res = await axios.get(
    //     `${encodedUri}/admin/campaign/by-type?type=${type}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
  
    //   setCampaignOptions({
    //     loading: false,
    //     list: res?.data?.campaigns?.map((campaign: any) => ({
    //       label: campaign.name,
    //       value: campaign.id,
    //     })),
    //   });
    // }, []);
    const getUsersOptions = useCallback(
      async (mobileNumber: any) => {
        setUsersOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
        axios
          .get(
            `${encodedUri}/admin/user?` +
              `page=${0}` +
              `&limit=${20}` +
              (status ? `&status=${status}` : ``) +
              (mobileNumber
                ? `&mobileNumber=%2B88${mobileNumber?.replace("+88", "")}`
                : ``),
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setUsersOptions({
              loading: false,
              list: res.data?.users?.map(
                (user: { fullName: any; mobileNumber: string }) => ({
                  label: user.fullName,
                  value: user.mobileNumber.replace("+88", ""),
                })
              ),
            });
          })
          .catch((err) => {
            setUsersOptions({ loading: false, list: [] });
            console.error("Users: Error", err);
          });
      },
      [status]
    );
  
    const getDriversOptions = useCallback(
      async (mobileNumber: string) => {
        setDriversOptions({ loading: true, list: [] });
        const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
        axios
          .get(
            `${encodedUri}/admin/driver?` +
              (mobileNumber
                ? `&mobileNumberOrName=${mobileNumber?.replace("+88", "")}`
                : ``) +
              (status ? `&status=${status}` : ``) +
              `&page=${0}` +
              `&limit=${20}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setDriversOptions({
              loading: false,
              list: res.data?.drivers?.map((driver: any) => ({
                label: `${driver?.mobileNumber.replace("+88", "")} - ${
                  driver.name
                }`,
                value: driver?.id,
              })),
            });
          })
          .catch((err) => {
            setDriversOptions({ loading: false, list: [] });
            console.error("Drivers: Error", err);
          });
      },
      [status]
    );
  
    const fatchPaymentMethod = async () => {
      const data = await fetch(
        `${process.env.REACT_APP_PAY_API}/payment-method/all?` +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      const response = await data.json();
  
      setPaymentMethod({
        loading: false,
        list: response?.methods?.map((method: { name: any }) => ({
          value: method.name,
          label: method.name,
        })),
      });
    };
  
    useEffect(() => {
      fatchPaymentMethod();
    }, []);
  
    const fetchRef = useRef(0);
    const handleSearch = useMemo(() => {
      const loadOptions = (value: string, field: string) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
  
        if (fetchId !== fetchRef.current) {
          return;
        }
        if (value) {
          if (field === "order") getOrderOptions(value);
          if (field === "shop") getShopOptions(value);
          // if (field === "campaign") getCampaignOptions(value);
          if (field === "user") getUsersOptions(value);
          if (field === "driver") getDriversOptions(value);
        }
      };
  
      return debounce(loadOptions, 800);
    }, [getOrderOptions, getUsersOptions, getDriversOptions]);
  
    useEffect(() => {
      getOrders();
    }, [getOrders]);
  
    useEffect(() => {
      getShopOrderInvoice();
    }, [getShopOrderInvoice]);
  
    useEffect(() => {
      if (showSearch) {
        getOrderOptions("");
        getShopOptions("");
        // getCampaignOptions("");
      }
    }, [showSearch]);
  
    useEffect(() => {
      if (secondDrawer) {
        getDriversOptions("");
        // form.resetFields();
      }
    }, [secondDrawer, form]);
  
    const reseAllFieldData = () => {
      form?.resetFields();
      setStatus("");
      setOrderId("");
      setPaymentMethod("");
      setPmethodId("");
      setMobileNumber("");
      setShopId("");
      setCampaignId("");
      setOrderType("");
      setRange({
        from: null,
        to: null,
      });
    };
  
    return (
      <>
        <BreadCrumb
          title="Return Order List"
          subTitle={`${returnOrderData?.data?.totalElements} Order(s)`}
          childComponent={inShop ? true : false}
          extra={[
            <Button
              type="dashed"
              shape="circle"
              onClick={() => setShowSearch(!showSearch)}
              key={1}
            >
              <SearchOutlined />
            </Button>,
          ]}
        />
        {showSearch && (
          <div className={`${styles.searchBox} ${inShop ? `mx-0` : ``}`}>
            <Form form={form} layout="inline" className={styles.formInline}>
              <Form.Item initialValue={type}>
                <Select
                  placeholder="Shop Type"
                  onChange={(val) => setType(val)}
                  value={type}
                  style={{ width: 250 }}
                >
                  {Object.values(jcTypeArray)?.map((type, i) => (
                    <Option value={type} key={i}>
                      {type.replace("_", " ")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item initialValue={orderType}>
                <Select
                  placeholder="Order Type"
                  onChange={(val) => setOrderType(val)}
                  value={orderType}
                  style={{ width: 250 }}
                >
                  <Select.Option value={undefined}>ALL</Select.Option>
                  {Object.values(ReturnOrderTypeArray)?.map((type, i) => (
                    <Option value={type} key={i}>
                      {type.replace("_", " ")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="baseOrderIdSearch" initialValue={orderId}>
                <AutoComplete
                  backfill
                  allowClear
                  onSearch={(e) => handleSearch(e, "order")}
                  onSelect={(val: any) => {
                    setOrderId(val);
                  }}
                  options={orderOptions?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    orderOptions?.loading ? <Spin size="small" /> : null
                  }
                  dropdownMatchSelectWidth={335}
                >
                  <Input.Search
                    placeholder="Order ID or Base Order ID"
                    onSearch={(val) => {
                      setOrderId(val);
                    }}
                    enterButton
                  />
                </AutoComplete>
              </Form.Item>
  
              {/* <Form.Item name="orderId" initialValue={orderId}>
                <AutoComplete
                  dropdownMatchSelectWidth={250}
                  style={{ width: 250 }}
                  onSearch={(e) => handleSearch(e, "order")}
                  onSelect={(val: any) => {
                    setOrderId(val);
                  }}
                  options={orderOptions?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    orderOptions?.loading ? <Spin size="small" /> : null
                  }
                >
                  <Input.Search
                    placeholder="Shop Order ID"
                    onSearch={(val) => {
                      setOrderId(val);
                    }}
                    enterButton
                  /> 
                </AutoComplete>
              </Form.Item> */}
  
              <Form.Item name="phone_search" initialValue={mobileNumber}>
                <AutoComplete
                  style={{ width: 250 }}
                  onSearch={(e) => handleSearch(e, "user")}
                  onSelect={(val: { toString: () => SetStateAction<string> }) =>
                    setMobileNumber(val?.toString())
                  }
                  options={usersOptions?.list}
                  defaultActiveFirstOption={false}
                  notFoundContent={
                    usersOptions?.loading ? <Spin size="small" /> : null
                  }
                >
                  <Input.Search
                    placeholder="Search by User Phone"
                    onSearch={(val) => setMobileNumber(val)}
                    enterButton
                    loading={usersOptions.loading}
                    maxLength={11}
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </AutoComplete>
              </Form.Item>
  
              {!inShop && (
                <Form.Item name="shop_search" initialValue={shopId}>
                  <Select
                    allowClear
                    showSearch
                    placeholder="Filter by Shop"
                    optionFilterProp="children"
                    onChange={(val) => setShopId(val)}
                    onSearch={(e) => handleSearch(e, "shop")}
                    filterOption={(input, option) => {
                      return true;
                    }}
                    options={shopOptions?.list}
                  ></Select>
                </Form.Item>
              )}
              {/* {!inCampaign && (
                <Form.Item name="campaign_search" initialValue={shopId}>
                  <Select
                    allowClear
                    showSearch
                    placeholder="Filter by Campaign"
                    optionFilterProp="children"
                    onChange={(val) => setCampaignId(val)}
                    onSearch={(e) => handleSearch(e, "campaign")}
                    filterOption={(input, option) => {
                      return true;
                    }}
                    options={campaignOptions?.list}
                  ></Select>
                </Form.Item>
              )} */}
  
              <Form.Item name="status_search" initialValue={status}>
                <Select
                  showSearch
                  placeholder="Filter by Status"
                  onChange={(val) => setStatus(val)}
                >
                  <Select.Option value="">ALL STATUS</Select.Option>
                  {Object.values(ReturnOrderTypeArray)?.map((type, i) => (
                    <Select.Option value={type} key={i}>
                      {type?.split("_").join(" ")}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
  
              <Form.Item name="payment_method">
                <Select
                  allowClear
                  placeholder="Filter by Payment Method"
                  optionFilterProp="children"
                  onChange={(val) => setPmethodId(val)}
                  filterOption={() => {
                    return true;
                  }}
                  options={paymentMethod?.list}
                ></Select>
              </Form.Item>
  
              <Form.Item name="range">
                <RangePicker
                  defaultValue={
                    range.from != null && range.to != null
                      ? [moment(range.from), moment(range.to)]
                      : null
                  }
                  onChange={(e, f) => {
                    setRange({
                      from: moment.utc(f[0])?.toISOString(),
                      to: moment.utc(f[1])?.toISOString(),
                    });
                  }}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss"),
                    ],
                  }}
                  inputReadOnly
                  allowClear
                />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              danger
              size="large"
              htmlType="reset"
              onClick={reseAllFieldData}
            >
              Reset
            </Button>
          </div>
        )}
  
        <div className={styles.contentWrapper}>
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div
                className={
                  showSearch ? `content-body-withSearch` : `content-body`
                }
              >
                {returnOrderData?.loading ? (
                  <Loader />
                ) : (
                  <table className={styles.mainTable}>
                    <thead className="bg-white border-b">
                      <tr>
                        <th scope="col">Order ID</th>
                        <th scope="col">Date</th>
                        {!inShop && <th scope="col">Shop</th>}
                        <th scope="col">Method</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Revenue</th>
                        <th scope="col">Delivery Man</th>
                        <th scope="col">Status</th>
                        <th scope="col">Return</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {returnOrderData?.data?.returnOrders?.length ? (
                        returnOrderData?.data?.returnOrders?.map((order: any, index: any) => (
                          <tr
                            className={cn(
                              `border-t hover:bg-gray-100 cursor-pointer`,
                              {
                                ["bg-red-50 border-b border-slate-50"]:
                                  order?.isPreOrder,
                              }
                            )}
                            key={index}
                            onClick={() =>
                              navigate(
                                `/orders/${
                                  order?.id
                                }/return-order-details`
                              )
                            }
                            
                          >
                            <td>
                              <span className="font-medium text-slate-700">
                                {order?.orderId}
                              </span>
                              <br />
                              <span className="font-light text-slate-600">
                                {order?.baseOrderId}
                              </span>
                            </td>
                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(order?.createdAt, "minutes") >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(order?.createdAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(order?.createdAt).format("lll")}
                              </span>
                            </td>
                            {!inShop && (
                              <td>
                                <div className="flex">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 mr-1 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                    />
                                  </svg>
                                  {order?.shop?.name}
                                </div>
                              </td>
                            )}
                            <td>{order?.refundPaymentMethod}</td>
                            <td>&#2547;{parseInt(order?.total)}</td>
                            <td>&#2547;{parseInt(order?.revenueAmount)}</td>
                            <td>
                              {order?.deliveryMan?.name ? (
                                <span className="name">
                                  {order?.deliveryMan?.name}
                                  <br />
                                  {order?.deliveryMan?.mobileNumber.split("+88")}
                                </span>
                              ) : (
                                <span className="radius-btn text-muted text-nowrap">
                                  <FontAwesomeIcon
                                    icon={faBiking}
                                    className="text-danger mr-2"
                                  />
                                  UNASSIGNED
                                </span>
                              )}
                            </td>
  
                            <td>
                              <span className="border p-1 px-5 rounded-2xl bg-white">
                                {order?.status}
                              </span>
                            </td>
                            <td>
                              <Button
                                shape="round"
                                type="dashed"
                                className="bg-white"
                                onClick={() => setSelectedBannerForEdit(order)}
                              >
                                <EditOutlined />
                              </Button>
                            </td>
                            <td>
                              <Button
                                title="View Order"
                                onClick={() => {
                                  navigate(
                                    `${
                                      inShop ? `/shops/${getShopId}` : ``
                                    }/orders/${order?.orderId}/details`
                                  );
                                }}
                                className="py-2 px-4 rounded-full mr-1 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                              >
                                <EyeOutlined />
                              </Button>
  
                              {order?.isPreOrder ? (
                                <Badge.Ribbon
                                  text="Pre Order"
                                  color="red"
                                  placement="end"
                                >
                                  <div style={{ minWidth: 150 }}>
                                    <Button
                                      shape="round"
                                      type="dashed"
                                      className="px-6"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSelectedOrder(order);
                                      }}
                                    >
                                      <SnippetsOutlined />
                                    </Button>
                                  </div>
                                </Badge.Ribbon>
                              ) : order?.isGroupBuyOrder ? (
                                <Badge.Ribbon
                                  text="Group Buy Order"
                                  color="red"
                                  placement="end"
                                >
                                  <div style={{ minWidth: 150 }}>
                                    <Button
                                      shape="round"
                                      type="dashed"
                                      className="px-6"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSelectedOrder(order);
                                      }}
                                    >
                                      <SnippetsOutlined />
                                    </Button>
                                  </div>
                                </Badge.Ribbon>
                              ) : (
                                <Button
                                  shape="round"
                                  type="dashed"
                                  className="px-6"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelectedOrder(order);
                                  }}
                                >
                                  <SnippetsOutlined />
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>
                            <Empty />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
  
          <PaginationThree
            {...returnOrderData?.data}
            limit={limit}
            page={getPage(loc.search)}
          />
        </div>
  
        <Drawer
          title={
            visible
              ? "Add Order Return"
              : selectedBannerForEdit
              ? "Order Return"
              : "Details"
          }
          placement="right"
          onClose={onClose}
          open={visible || selectedBannerForEdit}
          width={450}
        >
          {visible || selectedBannerForEdit ? (
            <AddBanner
              onCloseMethod={onClose}
              visibleData={selectedBannerForEdit}
            />
          ) : (
            ""
          )}
        </Drawer>
        <Modal
          width={`80%`}
          title={`ORDER DETAILS - ${selectedOrder?.orderId}`}
          open={selectedOrder}
          onCancel={onClose}
          okButtonProps={{ type: "primary", danger: true }}
          footer={false}
        >
          <SingleOrder inShop={true} selectedOrder={selectedOrder} />
        </Modal>
  
        <Modal
          centered
          title={
            <div className="d-flex-l">
              <FontAwesomeIcon
                icon={faDownload}
                className="mr-2"
                style={{ color: "#e73d42" }}
              />{" "}
              Download Invoice
            </div>
          }
          width={800}
          visible={!!showShopOrderInoice}
          // onOk={() => print(['1'])}
          okText={
            <PDFDownloadLink
              document={<ShopOrderInvoice shopOrderData={shopOrderData} />}
              fileName="shop-order-invoice.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download PDF"
              }
            </PDFDownloadLink>
          }
          cancelButtonProps={{
            type: "primary",
            danger: true,
          }}
          // confirmLoading={loading}
          onCancel={() => {
            setShowShopOrderInvoice(false);
          }}
          destroyOnClose={true}
        >
          <div>
            <PDFViewer
              showToolbar={false}
              style={{ width: "100%", minHeight: 600, border: 0 }}
            >
              <ShopOrderInvoice shopOrderData={shopOrderData} />
            </PDFViewer>
          </div>
        </Modal>
      </>
    );
  };
  
  export default OrderList;
  