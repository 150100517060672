import {
  EditOutlined,
  SearchOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useState, useRef } from "react";
import React from "react";
import { Avatar, Button, Drawer, Empty, Form, Select, Switch } from "antd";
import { responseNotification } from "../../../utils/notify";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { getImgUrl, getPage, getParamValue, jcTypeArray } from "../../../utils";
import { useLocation } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import AddPopUp from "./AddPopUp";
import SinglePopUp from "./Details";
import { AddBtn, Loader, Pagination } from "../../common";

const PopUpModalList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const fetchRef = useRef(0);
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [visible, setVisible] = useState<any>(undefined);
  const [selectedPopupForEdit, setSelectedPopupForEdit] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isActive, setIsActive] = useState<any>(undefined);
  const [promotionOptions, setPromotionOptions] = useState({
    loading: false,
    list: [],
  });
  const [popupData, setPopupData] = useState<any>({
    loading: false,
    data: null,
  });
  const getPopUpLists = useCallback(async () => {
    setPopupData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUri}/admin/popup?page=${page || 0}&limit=${limit || 16}` +
          (type ? `&type=${type}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPopupData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setPopupData({ loading: false, data: [] });
        console.error("Promotion: Error", err);
      });
  }, [limit, page, isActive, type]);



  useEffect(() => {
    getPopUpLists();
  }, [getPopUpLists]);

  const onClose = () => {
    getPopUpLists();
    setVisible(undefined);
    setSelectedPopupForEdit(undefined);
  };

  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/admin/popup`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getPopUpLists();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  

  const reseAllFieldData = () => {
    setType("JC_COMMERCE");
    setIsActive("");
    form.resetFields();
  };


  return (
    <>
      <BreadCrumb
        title={`Popup List`}
        subTitle={`${
          popupData?.data?.totalElements || popupData?.data?.pop?.length || 0
        } Popup(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <AddBtn onClick={() => setVisible(true)} key={2} />,
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form}>
            <Form.Item initialValue={type}>
              <Select
                placeholder="Shop Type"
                onChange={(val) => setType(val)}
                value={type}
                style={{ width: 250 }}
              >
                {Object.values(jcTypeArray)?.map((type, i) => (
                  <Option value={type} key={i}>
                    {type.replace("_", " ")}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="isActive_search" initialValue={isActive}>
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}

      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {popupData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">App Image</th>
                      <th scope="col">Web Image</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {popupData?.data?.popUps?.length ? (
                      popupData?.data?.popUps?.map((popup: any, index: any) => (
                        <tr
                          className="border-t hover:bg-gray-100"
                          key={index}
                        >
                          <td>
                            <span className="font-medium text-gray-500 ml-2">
                              {popup?.name}
                            </span>
                          </td>
                          <td>
                            <Avatar
                              size={40}
                              src={getImgUrl(popup?.appImage)}
                            />{" "}
                          </td>
                          <td>
                            <Avatar size={40} src={getImgUrl(popup?.image)} />{" "}
                          </td>

                          <td>
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              defaultChecked={popup?.isActive}
                              onChange={(val, e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onStatusChange(popup?.id, val);
                              }}
                              loading={
                                confirmLoading && popup?.id === confirmLoading
                              }
                            />
                          </td>

                          <td>
                            <Button
                              shape="round"
                              type="dashed"
                              className="bg-white"
                              onClick={() => setSelectedPopupForEdit(popup)}
                            >
                              <EditOutlined />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...popupData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

      <Drawer
        title={
          visible
            ? "Add Pop-Up"
            : selectedPopupForEdit
            ? "Edit Pop-Up"
            : "Pop-Up Details"
        }
        placement="right"
        onClose={onClose}
        open={visible  || selectedPopupForEdit}
        width={selectedPopupForEdit ? 600 : 500}
      >
        {visible || selectedPopupForEdit ? (
          <AddPopUp
            onCloseMethod={onClose}
            visibleData={selectedPopupForEdit}
          />
        ) : (
          ''
        )}
      </Drawer>
    </>
  );
};

export default PopUpModalList;
