import { Alert, Button, Divider, Form, Input, Switch } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import React from "react";
import { ImageUploader, SubmitResetBtn } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCropAlt, faXmark } from "@fortawesome/free-solid-svg-icons";

const AddService = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [serviceData, setServiceData] = useState<any>(undefined);
  const [isActive, setIsActive] = useState<any>();
  const [startUpload, setStartUpload] = useState<
  "Initiated" | "Uploading" | "Uploaded"
>();  
const [cropImage, setCropImage] = useState(true);
const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
useEffect(() => {
  if (startUpload === "Uploaded") {
    form.submit();
  }
}, [form, startUpload]);
  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      value: data.value, 
      url: data.url, 
      image: generatedImgUrl,
      isActive: isActive 
    };

    if (serviceData) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/service-type`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: serviceData?.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Service Updated Successfully",
              "success"
            );
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/service-type`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Business Type Create Successfully",
              "success"
            );
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setServiceData(visibleData);
      setIsActive(visibleData?.isActive);
      setGeneratedImgUrl(visibleData?.image);
    }
  }, [visibleData]);
  
  useEffect(() => {
    if (serviceData) {
      form.resetFields(Object.keys(visibleData));
      setIsActive(serviceData?.isActive);
      setGeneratedImgUrl(serviceData?.image);
    }
  }, [serviceData, form, visibleData]);
  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...serviceData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Service Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input
            id="name"
            type="text"
            placeholder="Enter service Name"
          />
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Service Value"
          rules={[
            {
              required: true,
              message: "Value is Required!",
            },
          ]}
          name="value"
        >
          <Input
            id="value"
            type="text"
            disabled={serviceData}
            placeholder="Enter service Name"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="URL"
          rules={[
            {
              required: false,
              message: "URL is Required!",
            },
          ]}
          name="url"
        >
          <Input
            id="url"
            type="text"
            placeholder="Enter service url"
          />
        </Form.Item>
        <Form.Item hasFeedback label="Is Active" name="isActive">
          <Switch
            checkedChildren={`true`}
            unCheckedChildren={`false`}
            defaultChecked={isActive}
            onChange={() => setIsActive(!isActive)}
          />
        </Form.Item>

        <Divider orientation="left">Image</Divider>
        <div className="flex items-center pb-4">
          <ImageUploader
            imgUrl={generatedImgUrl || ""}
            startUpload={startUpload}
            setStartUpload={(val: any) => setStartUpload(val)}
            setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
            // crop={cropImage ? 2.5 : 0}
          />
          <div className=" pr-8">
            <Switch
              onChange={() => setCropImage(!cropImage)}
              defaultChecked={cropImage}
              checkedChildren={<FontAwesomeIcon icon={faCropAlt} />}
              unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            />
          </div>
        </div>

        {cropImage && (
          <Alert
            message="Image ratio must be 2.5 (1600/640 PX)"
            type="info"
            showIcon
            className="mb-4 rounded-md shadow-sm"
            banner
          />
        )}
        
        <div className="buttons-container">
        <SubmitResetBtn
          onClickSubmit={(e) => {
            e.preventDefault();
            setStartUpload("Uploading");
          }}
          onClickReset={() => {
            form?.resetFields();
            setGeneratedImgUrl(undefined);
            setStartUpload(undefined);
            // setBannerUrl(undefined);
          }}
          disabled={loading || !!error}
          loading={loading}
        />
        </div>
      </Form>
    </>
  );
};

export default AddService;
