import React from "react";
import { ReactElement } from "react";
import DriverSettleOrderList from "../../../components/Delivery/DeliveryHero/SettleRequestOrder";
import MainLayout from "../../../components/Layouts";

const DriverSettleOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <DriverSettleOrderList />
    </MainLayout>
  );
};

export default DriverSettleOrderPage;
