import React from "react";
import MainLayout from "../../components/Layouts";
import UnsetteledRequestList from "../../components/DeliveryShipping/CarrerUnsettleOrder";

const UnsettledRequest = () => {
  return (
    <MainLayout>
      <UnsetteledRequestList />
    </MainLayout>
  );
};

export default UnsettledRequest;
