import React from "react";
import MainLayout from "../../components/Layouts";
import CustomerService from "../../components/Users/Service/index";

const CustomerServicePage = () => {
  return (
    <MainLayout>
      <CustomerService />
    </MainLayout>
  );
};

export default CustomerServicePage;
