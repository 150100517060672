import React from "react";
import MainLayout from "../../components/Layouts";
import MerchantShopEdit from "../../components/Shop/MerchantShopEdit";

const MerchantShopAddPage = () => {
  return (
    <MainLayout>
      <MerchantShopEdit />
    </MainLayout>
  );
};

export default MerchantShopAddPage;
