import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import ShopLayout from "../Layout";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  AddBtn,
  Loader,
  LoaderFull,
  PaginationThree,
  SubmitResetBtn,
} from "../../common";
import moment from "moment";
import axios from "axios";
import styles from "../../../styles/tailwind/List.module.css";
import { getPage, getParamValue } from "../../../utils";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import classNames from "classnames";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  SearchOutlined,
  SnippetsOutlined,
  SwapOutlined,
  ToTopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { error } from "console";
import { responseNotification } from "../../../utils/notify";
import packagePic from "../../../images/Group.svg";
import individulPic from "../../../images/individul.svg";
import caseUnitPic from "../../../images/case.svg";
import GroupUniPic from "../../../images/group.svg";

const ShopPackagesAdd = (): ReactElement => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [showSearch, setShowSearch] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [size, setSize] = useState<SizeType>("large");
  const [loading, setLoading] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState<any>(false);
  const [individulUnit, setIndividulUnit] = useState<any>(false);
  const [caseUnit, setCaseUnit] = useState<any>(false);
  const [groupUnit, setGroupUnit] = useState<any>(false);
  const [shippingDeliveryType, setShippingDeliveryType] = useState<any>();
  const [packageUnit, setPackageUnit] = useState<any>();
  const [packagePerUnit, setPackagePerUnit] = useState<any>();
  const [packingUnitType, setPackingUnitType] = useState<any>();
  const [whoPrepsUnit, setWhoPrepsUnit] = useState<any>(undefined);
  const [whoLabelUnits, setWhoLabelUnits] = useState<any>(undefined);
  const [defaultSellerPackage, setDefaultSellerPackage] = useState(false);
  const [packingType, setPackingType] = useState<any>();
  const route = useParams();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const [shopData, setShopData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [packageData, setPackageData] = useState<any>({
    loading: false,
    data: null,
  });


  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      packageUnitType: packingUnitType,
      packageType: packingType,
      isCustomPackage: true,
      shopId: (route as any)?.shopId,
      whoLabelsUnits: whoLabelUnits,
      whoPrepsUnits: whoPrepsUnit,
      packageSizeAndWeight: {
        unitPerBox: parseInt(data.unitPerBox),
        height: parseInt(data.height),
        length: parseInt(data.length),
        totalBoxWeight: parseInt(data.totalBoxWeight),
        unitType: packagePerUnit ,
        sizeUnitType: packageUnit,
        width: parseInt(data.width),
      },
      isMakeThisTheDefaultSellerPackage: defaultSellerPackage,
    };
    if (packageData.data) {
      await fetch(`${process.env.REACT_APP_ORDER_API}/package/update`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: packageData?.data?.id,
          deleted: false,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Package Update Successfully", "success");
            form.resetFields();
            navigate(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ORDER_API}/package/add-package`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Package Create Successfully", "success");
            form.resetFields();
            navigate(-1);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onClose = () => {
    setIndividulUnit(false);
    setCaseUnit(false);
    setGroupUnit(false);
  };
  const fetchPackageDetails = useCallback((getPackageId: any) => {
    if (getPackageId) {
      try {
        setPackageData({ loading: true, data: null });
        axios
          .get(
            `${process.env.REACT_APP_ORDER_API}/package/details?id=${getPackageId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              setPackageData({
                loading: false,
                data: data?.data?.deliveryPackage,
              });
              setDefaultSellerPackage(
                data?.data?.isMakeThisTheDefaultSellerPackage
              );
              setWhoPrepsUnit(data?.data?.whoPrepsUnits);
              setWhoLabelUnits(data?.data?.whoLabelsUnits);
              setPackingUnitType(data?.data?.packageUnitType);
              setPackingType(data?.data?.packageType);
              setPackagePerUnit(data?.data?.packageSizeAndWeight?.unitType);
              setPackageUnit(data?.data?.packageSizeAndWeight?.sizeUnitType);
            } else {
              setPackageData({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setPackageData({ loading: false, data: null });
          });
      } catch (error) {
        setPackageData({ loading: false, data: null });
      }
    }
  }, []);
  useEffect(() => {
    fetchPackageDetails((route as any)?.packageId);
  }, [fetchPackageDetails,route]);

  useEffect(() => {
    if (packageData?.data) {
      form.resetFields(Object.keys(packageData?.data));
      form.resetFields();
      setDefaultSellerPackage(
        packageData?.data?.isMakeThisTheDefaultSellerPackage
      );
      setWhoPrepsUnit(packageData?.data?.whoPrepsUnits);
      setWhoLabelUnits(packageData?.data?.whoLabelsUnits);
      setPackingUnitType(packageData?.data?.packageUnitType);
      setPackingType(packageData?.data?.packageType);
      setPackagePerUnit(packageData?.data?.packageSizeAndWeight?.unitType);
      setPackageUnit(packageData?.data?.packageSizeAndWeight?.sizeUnitType);
    }
  }, [form,packageData?.data]);


  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId}
        title="Package List"
        subTitle="Package"
        extra={<></>}
      >
        <div className="content-body_ rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-0 mb-6">
            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    <div className="content-body">
                      <>
                        <Form
                          name="control-hooks"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          className={styles.formStyles}
                          form={form} // like ref
                          onFinish={onSubmit}
                          layout="horizontal"
                          initialValues={{
                            ...packageData?.data,
                            unitPerBox:
                              packageData?.data?.packageSizeAndWeight
                                ?.unitPerBox,
                            height:
                              packageData?.data?.packageSizeAndWeight?.height,
                            length:
                              packageData?.data?.packageSizeAndWeight?.length,
                            totalBoxWeight:
                              packageData?.data?.packageSizeAndWeight
                                ?.totalBoxWeight,
                            unitType:
                              packageData?.data?.packageSizeAndWeight?.unitType,
                            width:
                              packageData?.data?.packageSizeAndWeight?.width,
                          }}
                        >
                          <Card className="mx-8 my-4 rounded-xl">
                            <div className="float-left w-full">
                              <div>
                                <Form.Item
                                  hasFeedback
                                  label="Package name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Package name is Required!",
                                    },
                                  ]}
                                  name="name"
                                >
                                  <Input
                                    id="name"
                                    type="text"
                                    placeholder="Enter Package name"
                                  />
                                </Form.Item>
                              </div>
                              <div className="float-left w-full">
                                <Form.Item
                                  hasFeedback
                                  label="Packing Unit type"
                                  name="PackingUnitType"
                                  initialValue={packingUnitType}
                                >
                                  <Radio.Group
                                    onChange={(val) =>
                                      setPackingUnitType(
                                        (val as any).target.value
                                      )
                                    }
                                    defaultValue={packingUnitType}
                                    value={packingUnitType}
                                     name="packingUnitType"
                                    className="w-full"
                                  >
                         
                         <label className="float-left w-[33%]">
                          <p>
                          <Radio
                                      value="individualUnits"
                                      name="individualUnits"
                                      
                                    >
                                     <p className="text-lg">
                                      Individual Units 
          <Typography.Link  onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setIndividulUnit(true);
                                }}> View</Typography.Link> 
                                </p>
                                
                                    </Radio>
                          </p>
                          <p className="pl-6 text-sm">For sending in mixed VeriationSKUS (Ex-1box=10)</p>
                         </label>       
                         <label className="float-left w-[33%]">
                         <p>
                                    <Radio
                                      value="caseUnits"
                                      name="caseUnits"
                                    >
                                      <p className="text-lg min-w-full">
                                      Case Units
                                      <Typography.Link  onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setCaseUnit(true);
                                }}> View</Typography.Link> 
                                      </p>
                                    </Radio>
                                    </p>
                                    <p className="pl-6 text-sm">For sending in One SKU (Ex-1box=50)</p>
                                    <p className="pl-6 text-sm">Same number of units, Same product type</p>
                                    </label>
                                    <label className="float-left w-[30%]">
                                    <p className=" w-full">
                                    <Radio
                                      name="groupUnits"
                                      value="groupUnits"
                                    >
                                      <p className="text-lg">
                                      Group Units
                                      <Typography.Link  onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setGroupUnit(true);
                                }}> View</Typography.Link> 
                                      </p>
                                    </Radio>
                                    </p>
                                    <p className="pl-6 text-sm">This SKU pack can be together to SKU (200 Units)</p>
                                    <p className="pl-6 text-sm">Everything will fit into one box.Multiple boxes will be Needed</p>
                                    </label>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                              <div className="float-left w-full">
                                <Form.Item
                                  hasFeedback
                                  label="Package type"
                                  name="packageType"
                                  initialValue={packingType}
                                >
                                  <Radio.Group
                                    onChange={(val) =>
                                      setPackingType((val as any).target.value)
                                    }
                                    value={packingType}
                                    name="packingType"
                                    className="w-full"
                                  >
                                    <Radio
                                      value="box"
                                      name="box"
                                      className="float-left w-[30%]"
                                    >
                                      Box
                                    </Radio>

                                    <Radio
                                      value="envelope"
                                      className="float-left w-[30%]"
                                      name="envelope"
                                    >
                                      Envelope
                                    </Radio>

                                    <Radio
                                      name="softPackageOrSatchel"
                                      value="softPackageOrSatchel"
                                      className="float-left w-[33%]"
                                    >
                                      Soft Package or Satchel
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="float-left w-full">
                              <Card
                                title="Package size & weight"
                                className="my-4 rounded-xl"
                              >
                                <div className="float-left w-[20%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Unit per Box"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Unit per Box is Required!",
                                      },
                                    ]}
                                    name="unitPerBox"
                                  >
                                    <Input
                                      id="unitPerBox"
                                      type="number"
                                      min={0}
                                      placeholder="Unit per Box"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="float-left w-[20%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Length"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Length is Required!",
                                      },
                                    ]}
                                    name="length"
                                  >
                                    <Input
                                      id="length"
                                      type="number"
                                      min={0}
                                      placeholder="Length"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="float-left w-[20%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Width"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Width is Required!",
                                      },
                                    ]}
                                    name="width"
                                  >
                                    <Input
                                      id="width"
                                      type="number"
                                      min={0}
                                      placeholder="Width"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="float-left w-[20%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Height"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Height is Required!",
                                      },
                                    ]}
                                    name="height"
                                  >
                                    <Input
                                      id="height"
                                      type="number"
                                      min={0}
                                      placeholder="Height"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="float-left w-[20%] p-2">
                                <Form.Item
                                    hasFeedback
                                    label="Unit"
                                    
                                    rules={[
                                      {
                                        required: !packagePerUnit,
                                        message: "Unit is Required!",
                                      },
                                    ]}
                                    name="unitperbox"
                                  >
                                <select
                            className="mb-2 mr-2 w-32 border border-gray-200 py-2 px-5 rounded-md"
                            onChange={(e) =>
                              setPackagePerUnit(e.target.value)
                            }
                            defaultValue={packagePerUnit}
                          >
                            <option value="">Select Unit</option>
                            <option value="CM">CM</option>
                            <option value="INCH">INCH</option>
                            </select>
                            </Form.Item>
                                </div>
                                
                                <div className="float-left w-[30%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Weight"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Weight is Required!",
                                      },
                                    ]}
                                    name="totalBoxWeight"
                                  >
                                    <Input
                                      id="totalBoxWeight"
                                      type="number"
                                      min={0}
                                      placeholder="Weight"
                                    />
                                  </Form.Item>
                                </div>
                                <div className="float-left w-[30%] p-2">
                                  <Form.Item
                                    hasFeedback
                                    label="Unit"
                                    rules={[
                                      {
                                        required: !packageUnit,
                                        message: "Unit is Required!",
                                      },
                                    ]}
                                    name="sizeUnitType"
                                  >
                        <select
                            className="mb-2 mr-2 w-32 border border-gray-200 py-2 px-5 rounded-md"
                            onChange={(e) =>
                              setPackageUnit(e.target.value)
                            }
                            defaultValue={packageUnit}
                          >
                            <option value="">Select Unit</option>
                            <option value="KG">KG</option>
                            <option value="LB">LB</option>
                            </select>
                                  </Form.Item>
                                </div>
                              </Card>
                            </div>
                            <div className="float-left w-full">
                              <div className="float-left w-1/2 p-4">
                                <Form.Item
                                  name="Who preps units"
                                  label="Who preps units"
                                  initialValue={whoPrepsUnit}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Status"
                                    defaultValue={whoPrepsUnit}
                                    onChange={(val) =>
                                      setWhoPrepsUnit(val as string)
                                    }
                                  >
                                    <Select.Option value={undefined}>
                                      NONE
                                    </Select.Option>
                                    <Select.Option
                                      value={"SELLER"}
                                      title="SELLER"
                                    >
                                      SELLER
                                    </Select.Option>
                                    <Select.Option
                                      value={"DELIVERYMAN"}
                                      title="DELIVERYMAN"
                                    >
                                      DELIVERYMAN
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </div>
                              <div className="float-left w-1/2 p-4">
                                <Form.Item
                                  name="Who Labels units"
                                  label="Who Labels units"
                                  initialValue={whoLabelUnits}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Status"
                                    defaultValue={whoLabelUnits}
                                    onChange={(val) =>
                                      setWhoLabelUnits(val as string)
                                    }
                                  >
                                    <Select.Option value={undefined}>
                                      NONE
                                    </Select.Option>
                                    <Select.Option
                                      value={"SELLER"}
                                      title="SELLER"
                                    >
                                      SELLER
                                    </Select.Option>
                                    <Select.Option
                                      value={"DELIVERYMAN"}
                                      title="DELIVERYMAN"
                                    >
                                      DELIVERYMAN
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="float-left w-full">
                              <Checkbox
                                checked={defaultSellerPackage}
                                onChange={(e) =>
                                  setDefaultSellerPackage(e.target.checked)
                                }
                              >
                                Make this the default seller package
                              </Checkbox>
                            </div>
                            <div className="float-left w-full">
                              <p className="mb-2 mt-0 font-medium leading-tight text-primary">
                                Used to calculate shipping rates at checkout.
                                Also pre-selected when you buy Jachai Shipping
                                labels if a suggested package is not available.
                              </p>
                            </div>

                            <div className="buttons-container">
                            <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
                            </div>
                          </Card>
                        </Form>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <Modal
        width={`40%`}
        title={`Individual Units`}
        open={individulUnit}
        onCancel={onClose}
        okButtonProps={{ type: "primary", danger: true }}
        footer={false}
      >
       <img  src={individulPic} />
      </Modal>
      <Modal
        width={`40%`}
        title={`Case Units`}
        open={caseUnit}
        onCancel={onClose}
        okButtonProps={{ type: "primary", danger: true }}
        footer={false}
      >
       <img  src={caseUnitPic} />
      </Modal>
      <Modal
        width={`40%`}
        title={`Group Units`}
        open={groupUnit}
        onCancel={onClose}
        okButtonProps={{ type: "primary", danger: true }}
        footer={false}
      >
       <img  src={GroupUniPic} />
      </Modal>
      </ShopLayout>
    </React.Fragment>
  );
};

ShopPackagesAdd.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default ShopPackagesAdd;
