import { Button, Checkbox, DatePicker, Drawer, Form, Input, Modal, Popconfirm, Select } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getPage, getParamValue, jcTypeArray } from "../../../utils/index";
import Empty from "../../common/Empty";
import moment from "moment";
import { responseNotification } from "../../../utils/notify";
import { useSelector } from "react-redux";
import ShopLayout from "../Layout";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination/Pagination";
import styles from "../../../styles/tailwind/List.module.css";
import OrderExtra from "./extraOrder";
import { SwapOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const DriverUnsettleOrderList = () => {
  const { driverInfo, token } = useSelector(
    (state) => (state as any)?.authReducer
  );
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [orderVisible, setOrderVisible] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const [selectedOrderId, setSelectedOrderId] = useState<any>([]);
  const [limit, setLimit] = useState(16);
  const [showSearch, setShowSearch] = useState(true);
  const [usersData, setUsersData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [selectedUser, setSelectedUser] = useState(undefined);
  const route = useParams();

  const [form] = Form.useForm();
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const showDrawer = () => {
    setOrderVisible(true);
  };
  const getSettledRequestOrders = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setUsersData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUri}/admin/settlement/settlement-requested-by-shop` +
          `?shopId=${(route as any)?.shopId || ``}` +
          (type ? `&type=${type}` : ``) +
          (from ? `&startAt=${from}` : ``) +
          (to ? `&endAt=${to}` : ``) +
          `&page=${page || 0}` +
          `&limit=${limit || 16}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUsersData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setUsersData({ loading: false, data: [] });
        console.error("Users: Error", err);
      });
  }, [type, range, route, page, limit]);



  const onApprovedStatus = async (data: any) => {
    setLoading(true);

    await fetch(
      `${process.env.REACT_APP_ORDER_API}/admin/settlement/settlement-approve-for-shop/${data}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Approved Successfully", "success");
          getSettledRequestOrders();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };

  const reseAllFieldData = () => {
    form?.resetFields();
    setRange({
      from: null,
      to: null,
    });
  };

  const onClose = () => {
    setOrderVisible(false);
  };
  useEffect(() => {
    getSettledRequestOrders();
  }, [getSettledRequestOrders]);

  return (
    <React.Fragment>
      <ShopLayout
        shopId={(route as any)?.shopId as string}
        title="Settled Request Orders"
        extra={[
          usersData?.data?.orders?.length && (
            <Button
              shape="round"
              type="primary"
              onClick={() => showDrawer()}
              disabled={!selectedOrderId.length}
              title="Clone Product"
              className="bg-blue-600"
            >
              <SwapOutlined /> Action
            </Button>
          ),
        ]}
      >
        {showSearch && (
          <div className={`${styles.searchBox}`}>
            <Form form={form} layout="inline" className={styles.formInline}>
              <Form.Item initialValue={type}>
                <Select
                  placeholder="Shop Type"
                  onChange={(val) => setType(val)}
                  value={type}
                  style={{ width: 250 }}
                >
                  {Object.values(jcTypeArray)?.map((type, i) => (
                    <Option value={type} key={i}>
                      {type.replace("_", " ")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="range">
                <RangePicker
                  defaultValue={
                    range.from != null && range.to != null
                      ? [moment(range.from), moment(range.to)]
                      : null
                  }
                  onChange={(e, f) => {
                    setRange({
                      from: moment.utc(f[0])?.toISOString(),
                      to: moment.utc(f[1])?.toISOString(),
                    });
                  }}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss"),
                    ],
                  }}
                  inputReadOnly
                  allowClear
                />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              danger
              size="large"
              htmlType="reset"
              onClick={reseAllFieldData}
            >
              Reset
            </Button>
          </div>
        )}

        <div className={`content-body`}>
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-2">
            <table className={styles.mainTable}>
              <thead className="bg-white border-b">
                <tr>
                  <th scope="col">Date & Time</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {usersData?.loading ? (
                <Loader />
              ) : (
                <tbody>
                  {usersData?.data?.shopSettlementRequests?.length ? (
                    usersData?.data?.shopSettlementRequests?.map((order: any, index: any) => (
                      <tr
                        className="border-t hover:bg-gray-100"
                        key={index}
                        onClick={() => setSelectedUser(order)}
                      >
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {moment(order?.createdAt).format("lll")}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            &#2547;{order?.amount}
                          </span>
                        </td>
                        <td>
                          <span className="font-medium text-gray-500 ml-2">
                            {order?.status}
                          </span>
                        </td>
                        <td>
                            <Popconfirm
                              title="Do you confirm, that you have approved?"
                              onConfirm={() => onApprovedStatus(order?.id)}
                              okText="CONFIRM"
                              cancelText="NOT SURE"
                              disabled={
                                order?.status === "APPROVED"
                              }
                            >
                              <Button
                                size="small"
                                shape="round"
                                type="dashed"
                                danger
                                disabled={
                                  order?.status === "APPROVED"
                                }
                              >
                                APPROVE
                              </Button>
                            </Popconfirm>
                          </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        <Empty />
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
            <Pagination
              {...usersData?.data}
              limit={limit}
              page={getPage(loc.search)}
            />
          </div>
          <Drawer
        title={"Settlement Request"}
        placement="right"
        onClose={onClose}
        open={orderVisible}
        width={550}
      >
        <OrderExtra orderIds={selectedOrderId} shopId={(route as any)?.shopId} />
      </Drawer>
      
        </div> 
      </ShopLayout>


    </React.Fragment>
  );
};

export default DriverUnsettleOrderList;
