import { Badge, Button, Drawer, Empty, Modal, Rate } from "antd";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import cn from "classnames";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useLocation, useParams } from "react-router";
import _ from "lodash";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import CustomerLayout from "../Layout";
import { SET_USER_INFO } from "../../../redux/auth/authType";
import { getPage, getParamValue } from "../../../utils";
import styles from "../../../styles/tailwind/List.module.css";
import { Loader, PaginationThree } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBiking, faDownload } from "@fortawesome/free-solid-svg-icons";
import { SnippetsOutlined } from "@ant-design/icons";
import ShopOrderInvoice from "../../Orders/shopOrderInvoice";

const UserOrderHistory = (props: any): ReactElement => {  
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [orderType, setOrderType] = useState(initialState?.orderType);
  const [orderId, setOrderId] = useState("");
  const [shopId, setShopId] = useState<any>();
  const [campaignId, setCampaignId] = useState<any>();
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [mobileNumber, setMobileNumber] = useState("");
  const [pmethodId, setPmethodId] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [showShopOrderInoice, setShowShopOrderInvoice] = useState(false);
  const [singleUserInfo, setSingleUserInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const route = useParams();
  const dispatch = useDispatch();
  const userId = (route as any)?.userId;
  const [orderData, setOrderData] = useState<any>({
    loading: false,
    data: null,
  });
  const [shopOrderData, setShopOrderData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const getOrders = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setOrderData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/order/by-user?userId=${userId}` +
          (orderType ? `&orderType=${orderType}` : ``) +
          (orderId ? `&orderIdOrBaseOrderId=${orderId}` : ``) +
          (mobileNumber ? `&mobileNumber=${mobileNumber}` : ``) +
          // (campaignId ? `&campaignId=${campaignId}` : ``) +
          (status ? `&status=${status}` : ``) +
          (pmethodId ? `&paymentMethod=${pmethodId}` : ``) +
          (from ? `&from=${from}` : ``) +
          (to ? `&to=${to}` : ``) +
          (page
            ? `&page=${
                page == orderData?.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOrderData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setOrderData({ loading: false, data: [] });
        console.error("Order: Error", err);
      });
  }, [
    userId,
    limit,
    page,
    type,
    orderType,
    shopId,
    orderId,
    mobileNumber,
    status,
    pmethodId,
    range,
  ]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);


  const onClose = () => {
    setSelectedOrder(undefined);
    //setSecondDrawer(false);
    setSelectedOrder(undefined);
    // setVisible(undefined);
    // setSelectedBannerForEdit(undefined);
  };
  const fetchUserDetails = useCallback((getUserId: any) => {
    if (getUserId) {
      try {
        setSingleUserInfo({ loading: true, data: null });
        axios
          .get(`${process.env.REACT_APP_RIDER_API}/admin/user/${getUserId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((data) => {
            if (data.status === 200) {
              setSingleUserInfo({
                loading: false,
                data: data?.data?.user,
              });
              dispatch({
                type: SET_USER_INFO,
                payload: {
                  customerInfo: {
                    id: data?.data?.user?.id,
                    fullName: data?.data?.user?.fullName,
                    profilePicture: data?.data?.user?.profilePicture,
                    mobileNumber: data?.data?.user?.mobileNumber,
                    balance: data?.data?.user?.balance,
                    rating: data?.data?.user?.rating,
                    numberOfRating: data?.data?.user?.numberOfRating,
                    status: data?.data?.user?.status,
                  },
                },
              });
            } else {
              setSingleUserInfo({ loading: false, data: null });
              responseNotification(
                data.statusText || "something went wrong",
                "error"
              );
            }
          })
          .catch((err) => {
            setSingleUserInfo({ loading: false, data: null });
          });
      } catch (error) {
        setSingleUserInfo({ loading: false, data: null });
      }
    }
  }, []);

  useEffect(() => {
    fetchUserDetails((route as any)?.userId);
  }, [fetchUserDetails]);

  const user = singleUserInfo?.data;
  return (
    <React.Fragment>
      <CustomerLayout userId={(route as any)?.userId as string}>
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
            <h3 className="mb-4 text-xl font-bold">General information</h3>
            <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {orderData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Order ID</th>
                      <th scope="col">Date</th>
                      <th scope="col">Shop</th>
                      <th scope="col">Method</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Delivery Man</th>
                      <th scope="col">Status</th>
                      {/* <th scope="col">Return</th> */}
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orderData?.data?.orders?.length ? (
                      orderData?.data?.orders?.map((order: any, index: any) => (
                        <tr
                          className={cn(
                            `border-t hover:bg-gray-100 cursor-pointer`,
                            {
                              ["bg-red-50 border-b border-slate-50"]:
                                order?.isPreOrder,
                            }
                          )}
                          key={index}
                        //   onClick={() =>
                        //     navigate("")
                        //   }
                          
                        >
                          <td>
                            <span className="font-medium text-slate-700">
                              {order?.orderId}
                            </span>
                            <br />
                            <span className="font-light text-slate-600">
                              {order?.baseOrderId}
                            </span>
                          </td>
                          <td>
                            <small
                              className={`font-capitalize ${
                                moment().diff(order?.createdAt, "minutes") >= 60
                                  ? "text-red-600"
                                  : "text-gray-800"
                              }`}
                            >
                              {moment(order?.createdAt).fromNow()}
                            </small>
                            <br />
                            <span className="name">
                              {moment(order?.createdAt).format("lll")}
                            </span>
                          </td>
                            <td>
                              <div className="flex">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4 mr-1 text-gray-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                  />
                                </svg>
                                {order?.shop?.name}
                              </div>
                            </td>
                        
                          <td>{order?.paymentMethod}</td>
                          <td>&#2547;{parseInt(order?.total)}</td>
                          <td>
                            {order?.deliveryMan?.name ? (
                              <span className="name">
                                {order?.deliveryMan?.name}
                                <br />
                                {order?.deliveryMan?.mobileNumber.split("+88")}
                              </span>
                            ) : (
                              <span className="radius-btn text-muted text-nowrap">
                                <FontAwesomeIcon
                                  icon={faBiking}
                                  className="text-danger mr-2"
                                />
                                UNASSIGNED
                              </span>
                            )}
                          </td>

                          <td>
                            <span className="border p-1 px-5 rounded-2xl bg-white">
                              {order?.status}
                            </span>
                          </td>
                          {/* <td>
                            <Button
                              shape="round"
                              type="dashed"
                              className="bg-white"
                              onClick={() => setSelectedBannerForEdit(order)}
                            >
                              <EditOutlined />
                            </Button>
                          </td> */}
                          <td>
                            {/* <Button
                              title="View Order"
                              onClick={() => {
                                navigate(
                                  `${
                                    inShop ? `/shops/${getShopId}` : ``
                                  }/orders/${order?.orderId}/details`
                                );
                              }}
                              className="py-2 px-4 rounded-full mr-1 leading-none bg-white border hover:bg-blue-100 hover:text-gray-600 flex items-center"
                            >
                              <EyeOutlined />
                            </Button> */}

                            {order?.isPreOrder ? (
                              <Badge.Ribbon
                                text="Pre Order"
                                color="red"
                                placement="end"
                              >
                                <div style={{ minWidth: 150 }}>
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="px-6"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setSelectedOrder(order);
                                    }}
                                  >
                                    <SnippetsOutlined />
                                  </Button>
                                </div>
                              </Badge.Ribbon>
                            ) : order?.isGroupBuyOrder ? (
                              <Badge.Ribbon
                                text="Group Buy Order"
                                color="red"
                                placement="end"
                              >
                                <div style={{ minWidth: 150 }}>
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="px-6"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setSelectedOrder(order);
                                    }}
                                  >
                                    <SnippetsOutlined />
                                  </Button>
                                </div>
                              </Badge.Ribbon>
                            ) : (
                              <Button
                                shape="round"
                                type="dashed"
                                className="px-6"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSelectedOrder(order);
                                }}
                              >
                                <SnippetsOutlined />
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <PaginationThree
          {...orderData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>

   
      <Modal
        width={`80%`}
        title={`ORDER DETAILS - ${selectedOrder?.orderId}`}
        open={selectedOrder}
        onCancel={onClose}
        okButtonProps={{ type: "primary", danger: true }}
        footer={false}
      >
        {/* <SingleOrder inShop={true} selectedOrder={selectedOrder} /> */}
      </Modal>

      <Modal
        centered
        title={
          <div className="d-flex-l">
            <FontAwesomeIcon
              icon={faDownload}
              className="mr-2"
              style={{ color: "#e73d42" }}
            />{" "}
            Download Invoice
          </div>
        }
        width={800}
        visible={!!showShopOrderInoice}
        // onOk={() => print(['1'])}
        okText={
          <PDFDownloadLink
            document={<ShopOrderInvoice shopOrderData={shopOrderData} />}
            fileName="shop-order-invoice.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download PDF"
            }
          </PDFDownloadLink>
        }
        cancelButtonProps={{
          type: "primary",
          danger: true,
        }}
        // confirmLoading={loading}
        onCancel={() => {
          setShowShopOrderInvoice(false);
        }}
        destroyOnClose={true}
      >
        <div>
          <PDFViewer
            showToolbar={false}
            style={{ width: "100%", minHeight: 600, border: 0 }}
          >
            <ShopOrderInvoice shopOrderData={shopOrderData} />
          </PDFViewer>
        </div>
      </Modal>
          </div>
        </div>
      </CustomerLayout>
    </React.Fragment>
  );
};
UserOrderHistory.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default UserOrderHistory;
