import { FilePdfOutlined } from "@ant-design/icons";
import {
  faBiking,
  faExchangeAlt,
  faHandHoldingUsd,
  faSortAmountDownAlt,
  faDownload,
  faTruckArrowRight,
  faTruck,
  faTruckDroplet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { Button, Form, Input, Modal, Select } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  jcCourierArray,
  jcParcelTypeArray,
  OrderTypeArray,
} from "../../../utils";
import { responseNotification } from "../../../utils/notify";
import { isAllowedService } from "../../../utils/services";
import OrderPaymentLogs from "./paymentLogs";
import OrderInvoice from "./OrderInvoice";

const { Option } = Select;

const ReturnOrderExtra = ({
  orderDetails,
  selectedProducts,
  refetch,
}: any): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [orderTransferModal, setOrderTransferModal] = useState(false);
  const [assignCourierModel, setAssignCourierModel] = useState(false);
  const [assignment, setAssignment] = useState(false);
  const [showInoice, setShowInvoice] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [merchantId, setMerchantId] = useState<any>();
  const [packageCode, setPackageCode] = useState<any>();
  const [pathaoStoreId, setPathaoStoreId] = useState<any>();
  const [carrierId, setCarrierId] = useState<any>();
  const [shopId, setShopId] = useState<any>();
  const [selectedDeliveryManId, setSelectedDeliveryManId] = useState("");
  
  const [singleOrderInfo, setSingleOrderInfo] = useState<any>();


const [returnOrderApprovedType, setReturnOrderApprovedType] = useState("PENDING");
  const [pdfloading, setPdfloading] = useState(false);
  const [status, setStatus] = useState("");
  const [courierCompany, setCourierCompany] = useState("");
  const [parcelType, setParcelType] = useState("BOX");
  const [deliveryType, setDeliveryType] = useState("");
  const [itemType, setItemType] = useState("");
  const [cityId, setCityId] = useState<any>("");
  const [zoneId, setZoneId] = useState<any>();
  const [areaId, setAreaId] = useState<any>();
  const [note, setNote] = useState("");
  const [courierNote, setCourierNote] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [recipientPhone, setRecipientPhone] = useState("");
  const [amountToCollect, setAmountToCollect] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [itemWeight, setItemWeight] = useState("");
  const [recipientAddress, setRecipientAddress] = useState("");
  const [form] = Form.useForm();
  const fetchRef = useRef(0);
  const isActive = true;
  const [deliveryManData, setDeliveryManData] = useState({
    loading: false,
    list: [],
  });
  const [cityOptions, setCityOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [zoneOptions, setZoneOptions] = useState({
    loading: false,
    list: [],
  });
  const [areaOptions, setAreaOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [merchantOptions, setMerchantOptions] = useState({
    loading: false,
    list: [],
  });
  const [packageOptions, setPackageOptions] = useState({
    loading: false,
    list: [],
  });
  const [storeOptions, setStoreOptions] = useState({
    loading: false,
    list: [],
  });
  const [carrierOptions, setCarrierOptions] = useState({
    loading: false,
    list: [],
  });

  const onStatusChange = async (data: any) => {
    if (status === "CANCELLED" && !note) {
      responseNotification("To cancel note is required!");
    } else {
      setLoading(true);

      const readyData = data && {
        orderId: singleOrderInfo?.orderId,
        status: status,
        note: note,
      };

      await fetch(`${process.env.REACT_APP_ORDER_API}/admin/order`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Status Updated Successfully", "success");
            form.resetFields();
            refetch();
            setStatusModal(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchDeliveryMan = useCallback(async (value: any) => {
    setDeliveryManData({ loading: true, list: [] });
    await fetch(
      `${
        process.env.REACT_APP_RIDER_API
      }/admin/driver?status=VERIFIED&mobileNumberOrName=${value?.replace(
        "+88",
        ""
      )}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setDeliveryManData({
          loading: true,
          list: res?.drivers?.map((driver: any) => ({
            label: `${driver?.mobileNumber?.replace("+88", "")} - ${
              driver?.name
            }`,
            value: driver?.id,
          })),
        });
      });
  }, []);

  // =========================================
  const getMerchantsOptions = useCallback(
    async (key?: string) => {
      setMerchantOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
      const res = await axios.get(
        `${encodedUri}/merchant/all?page=0&limit=20` +
          (type ? `&type=${type}` : ``) +
          (key ? `&name=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMerchantOptions({
        loading: false,
        list: res.data?.merchants?.map((merchant: any) => ({
          label: merchant.name,
          value: merchant.id,
        })),
      });
    },
    [type]
  );
  const getPackagesOptions = useCallback(async () => {
    setPackageOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    const res = await axios.get(`${encodedUri}/e-courier/packages`);

    setPackageOptions({
      loading: false,
      list: res.data?.packages?.map((eCourierPackage: any) => ({
        label: eCourierPackage.package_name,
        value: eCourierPackage.package_code,
      })),
    });
  }, []);
  const getStoresOptions = useCallback(async () => {
    setStoreOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    const res = await axios.get(`${encodedUri}/pathao/pathao-store`);
    setStoreOptions({
      loading: false,
      list: res.data?.data?.data?.map((pathaoStore: any) => ({
        label: pathaoStore.store_name,
        value: pathaoStore.store_id,
      })),
    });
  }, []);
  const getCarriersOptions = useCallback(async () => {
    setCarrierOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    const res = await axios.get(
      `${encodedUri}/delivery-carrier/all?isActive=${isActive}&page=0&limit=100`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCarrierOptions({
      loading: false,
      list: res.data?.deliveryCarriers?.map((deliveryCarrier: any) => ({
        label: deliveryCarrier.name,
        value: deliveryCarrier.name,
      })),
    });
  }, []);
  const getShopsOptions = useCallback(
    async (val?: string) => {
      setShopsOptions({ loading: true, list: [] });

      const res = await axios.get(
        `${process.env.REACT_APP_CATALOG_READER_API}/admin/shop/by-merchant?page=0&limit=20` +
          (merchantId ? `&merchantId=${merchantId}` : ``) +
          (val ? `&key=${val}` : ``) +
          (type ? `&type=${type}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShopsOptions({
        loading: false,
        list: res?.data?.shops?.map((shop: any) => {
          return {
            value: shop?.id,
            label: shop?.name,
          };
        }),
      });
    },
    [type, merchantId]
  );

  //
  const assignCourierComapny = async (data: any) => {
    if (carrierId === "E_COURIER") {
      setLoading(true);
      const readyECourierData = data && {
        carrierId: carrierId,
        orderIds: [singleOrderInfo?.orderId],
        packageCode: packageCode,
        parcelType: parcelType,
      };
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/e-courier/place-by-order-id`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyECourierData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Courier Assign Successfully", "success");
            setAssignCourierModel(!assignCourierModel);
            form.resetFields();
            refetch();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else if (carrierId === "PATHAO") {
      setLoading(true);
      const readyPathaoData = data && {
        // recipient_name: recipientName,
        // recipient_phone: recipientPhone,
        // amount_to_collect: amountToCollect,
        delivery_type: deliveryType,
        //item_description: itemDescription,
        //item_quantity: itemQuantity,
        item_type: itemType,
        //item_weight: itemWeight,
        //recipient_address: recipientAddress,
        recipient_area: areaId,
        recipient_city: cityId,
        recipient_zone: zoneId,
        specialInstruction: courierNote,
        storeId: pathaoStoreId,
        orderId: singleOrderInfo?.orderId,
      };
      await fetch(`${process.env.REACT_APP_ORDER_API}/pathao/create-order`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyPathaoData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("pathao Assign Successfully", "success");
            setAssignCourierModel(!assignCourierModel);
            form.resetFields();
            refetch();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else if (carrierId === "STEAD_FAST") {
      setLoading(true);
      const readyStedFastData = data && {
        carrierId: carrierId,
        note: courierNote,
        orderId: singleOrderInfo?.orderId,
      };
      await fetch(
        `${process.env.REACT_APP_ORDER_API}/steadFast-courier/create_order`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyStedFastData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("StedFast Assign Successfully", "success");
            setAssignCourierModel(!assignCourierModel);
            form.resetFields();
            refetch();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      responseNotification("Select a Courier Company", "warning");
    }
  };

  const onReturnOrderChangeStatus = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      returnOrderId: orderDetails?.id,
      status: returnOrderApprovedType,
    };

    await fetch(
      `${process.env.REACT_APP_ORDER_API}/admin/return-order/status-update`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.statusCode === 200) {
          responseNotification("Order Transfered Successfully", "success");
          //getShopsOptions();
          setOrderTransferModal(!orderTransferModal);
          form.resetFields();
          refetch();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  //

  const onBkashManual = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      orderId: singleOrderInfo?.baseOrderId,
      amount: data.amount,
      transactionId: data.transactionId,
    };

    await fetch(
      `${process.env.REACT_APP_PAY_API}/bkash/manual/success/from-app`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          responseNotification("Payment Received Successfully", "success");
          getShopsOptions();
          setShowForm(false);
          form.resetFields();
          refetch();
        } else if (res.status === 500) {
          responseNotification("Internal server error", "error");
        } else {
          responseNotification(res.message || "something wrong", "warning");
        }
      })
      .catch((err) => {
        setLoading(false);
        responseNotification(`${"Internal server error"} ${err}`, "error");
        console.error("err", err);
      });
  };
  const getCityOptions = useCallback(async (countryName?: string) => {
    setCityOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ORDER_API}/pathao/city-list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCityOptions({
      loading: false,
      list: res?.data?.data?.data?.map((city: any) => {
        return {
          value: city?.city_id,
          label: city?.city_name,
        };
      }),
    });
  }, []);

  const getZoneOptions = useCallback(
    async (key: any) => {
      setZoneOptions({ loading: true, list: [] });
      return axios
        .get(
          `${process.env.REACT_APP_ORDER_API}/pathao/zone-list?city_id=${cityId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setZoneOptions({
            loading: false,
            list: res.data?.data?.data?.map((zone: any) => ({
              label: zone.zone_name,
              value: zone.zone_id,
            })),
          });
        })
        .catch((err) => {
          setZoneOptions({ loading: false, list: [] });
          console.error("city: Error", err);
        });
    },
    [cityId]
  );

  const getAreaOptions = useCallback(async () => {
    setAreaOptions({ loading: true, list: [] });
    return axios
      .get(
        `${process.env.REACT_APP_ORDER_API}/pathao/area-list?zone_id=${zoneId}`
      )
      .then((res) => {
        setAreaOptions({
          loading: false,
          list: res.data?.data?.data?.map((area: any) => ({
            label: area.area_name,
            value: area.area_id,
          })),
        });
      })
      .catch((err) => {
        setAreaOptions({ loading: false, list: [] });
        console.error("area: Error", err);
      });
  }, [zoneId]);
  // =======================================

  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
        if (field === "merchant") getMerchantsOptions(value);
        if (field === "package") getPackagesOptions();
        if (field === "store") getStoresOptions();
        if (field === "carrier") getCarriersOptions();
        if (field === "shop") getShopsOptions(value);
        if (field === "driver") fetchDeliveryMan(value);
        if (field === "city") getCityOptions(value);
        if (field === "zone") getZoneOptions(value);
        if (field === "area") getAreaOptions();
      }
    };

    return debounce(loadOptions, 800);
  }, [
    getMerchantsOptions,
    getPackagesOptions,
    getStoresOptions,
    getCarriersOptions,
    getShopsOptions,
    fetchDeliveryMan,
    getCityOptions,
    getZoneOptions,
    getAreaOptions,
  ]);

  const assignDeliveryHero = () => {
    if (selectedDeliveryManId) {
      setLoading(true);
      axios
        .put(
          `${process.env.REACT_APP_ORDER_API}/admin/return-order/assign-delivery-man`,
          {
            deliveryManId: selectedDeliveryManId,
            id: orderDetails?.orderId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            responseNotification("Assigned Successfully!");
            refetch();
            setAssignment(false);
          } else {
            responseNotification("Assigmenation Failed!", "error");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(
            err?.message || "Assigmenation Failed!",
            "error"
          );
          console.error(err);
        });
    } else {
      responseNotification("Select a delivery man", "warning");
    }
  };

  useEffect(() => {
    setPdfloading(true);
    setSingleOrderInfo(orderDetails);
    setTimeout(() => setPdfloading(false), 3000);
    setStatus(orderDetails?.status);
  }, [orderDetails]);
  useEffect(() => {
    getCityOptions("");
    getZoneOptions("");
    getAreaOptions();
  }, [getCityOptions, getZoneOptions, getAreaOptions]);
  useEffect(() => {
    if (orderTransferModal) {
      getMerchantsOptions();
    }
  }, [getMerchantsOptions, orderTransferModal]);

  useEffect(() => {
    if (assignCourierModel) {
      getPackagesOptions();
      getStoresOptions();
      getCarriersOptions();
    }
  }, [
    getPackagesOptions,
    getStoresOptions,
    getCarriersOptions,
    assignCourierModel,
  ]);

  useEffect(() => {
    if (merchantId) {
      getShopsOptions();
    }
  }, [getShopsOptions, merchantId]);

  useEffect(() => {
    if (type) {
      getMerchantsOptions();
    }
  }, [getMerchantsOptions, type]);
  useEffect(() => {
    if (singleOrderInfo) {
      setReturnOrderApprovedType(singleOrderInfo?.status);
    }
  }, [singleOrderInfo]);
  
  return (
    <React.Fragment>
      <div className="bg-white rounded-md shadow p-2 grid grid-cols-8 gap-3">
        {isAllowedService(`Orders Extra`) && (
          <Button
            type="dashed"
            title="Assign Courier"
            onClick={() => setAssignCourierModel(true)}
            disabled={
              // !!orderDetails?.deliveryManId ||
              orderDetails?.status === "ON_DELIVERY" ||
              orderDetails?.status === "PICKED" ||
              orderDetails?.status === "DELIVERED" ||
              orderDetails?.status === "COMPLETED" ||
              orderDetails?.status === "REVIEWED" ||
              orderDetails?.status === "CANCELLED"
            }
          >
            <FontAwesomeIcon
              icon={faTruck}
              className={` text-${
                orderDetails?.deliveryManId ? "success" : "dark"
              }`}
            />
          </Button>
        )}

        {isAllowedService(`Orders Extra`) && (
          <Button
            type="dashed"
            onClick={() => setAssignment(true)}
            disabled={
              // !!orderDetails?.deliveryManId ||
              orderDetails?.status === "ON_DELIVERY" ||
              orderDetails?.status === "PICKED" ||
              orderDetails?.status === "DELIVERED" ||
              orderDetails?.status === "COMPLETED" ||
              orderDetails?.status === "REVIEWED" ||
              orderDetails?.status === "CANCELLED"
            }
            title="Assign Delivery Hero"
          >
            <FontAwesomeIcon
              icon={faBiking}
              className={` text-${
                orderDetails?.deliveryManId ? "success" : "dark"
              }`}
            />
          </Button>
        )}

        {isAllowedService(`Orders Extra`) && (
          <Button
            type="dashed"
            title="Approved Return Order"
            onClick={() => setOrderTransferModal(true)}
          
          >
            <FontAwesomeIcon icon={faExchangeAlt} />
          </Button>
        )}

        {isAllowedService(`Orders Extra`) && (
          <Button
            type="dashed"
            title="Order Invoice"
            onClick={() => setShowInvoice(!showInoice)}
          >
            <FilePdfOutlined />
          </Button>
        )}
      </div>

      <Modal
        title={"Payment History"}
        okButtonProps={{
          disabled:
            !!!paymentStatus ||
            singleOrderInfo?.total <= singleOrderInfo?.totalPaid,
        }}
        open={!!paymentStatus}
        okText="bKash Manual"
        onOk={() => {
          setShowForm(true);
        }}
        cancelButtonProps={{
          color: "red",
          type: "dashed",
          danger: true,
        }}
        confirmLoading={loading}
        onCancel={() => {
          setPaymentStatus(false);
          refetch();
        }}
        destroyOnClose={true}
        width={"62%"}
      >
        {showForm && (
          <Form layout="inline" onFinish={onBkashManual} className="mb-4">
            <Form.Item
              hasFeedback
              name="amount"
              rules={[
                { required: true },
                {
                  validator: async (_, value) => {
                    if (
                      value >
                      singleOrderInfo?.total - singleOrderInfo?.totalPaid
                    ) {
                      return Promise.reject(
                        new Error("Amount Must be Equal or less than Balance")
                      );
                    }
                  },
                },
              ]}
              initialValue={
                singleOrderInfo?.baseAmount - singleOrderInfo?.totalPaid
              }
            >
              <Input id="amount" placeholder="Enter Amount" />
            </Form.Item>

            <Form.Item
              hasFeedback
              name="transactionId"
              rules={[
                {
                  required: true,
                  message: "Transaction ID is required",
                },
              ]}
            >
              <Input id="transactionId" placeholder="Enter Transaction ID" />
            </Form.Item>

            <Button danger type="primary" htmlType="submit">
              Recieve
            </Button>
          </Form>
        )}

        <OrderPaymentLogs orderDetails={orderDetails} />
      </Modal>

      <Modal
        title={"Approved Return Order"}
        open={!!orderTransferModal}
        okText="Submit"
        onOk={onReturnOrderChangeStatus}
        cancelButtonProps={{
          color: "red",
          type: "ghost",
          danger: true,
        }}
        confirmLoading={loading}
        onCancel={() => {
          setOrderTransferModal(!orderTransferModal);
        }}
        destroyOnClose={true}
      >
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
        >

          <h1>Refund Payment Method : {singleOrderInfo?.refundPaymentMethod}</h1>
          <Form.Item
            hasFeedback
            label="Approved Status"
            rules={[
              {
                required: false,
                message: "Approved Status is Required!",
              },
            ]}
            name="campaignType"
            initialValue={returnOrderApprovedType}
          >
            <Select
              options={[
                { value: "PENDING", label: "PENDING" },
                {
                  value: "PICKED",
                  label: "PICKED",
                },
                {
                  value: "RECEIVED_AT_WAREHOUSE",
                  label: "RECEIVED AT WAREHOUSE",
                },
                {
                  value: "REJECTED",
                  label: "REJECTED",
                },
                {
                  value: "CANCELLED",
                  label: "CANCELLED",
                },
                {
                  value: "COMPLETED",
                  label: "COMPLETED",
                },
              ]}
              placeholder="Select Transit Type"
              defaultValue={returnOrderApprovedType}
              onChange={(val) => setReturnOrderApprovedType(val)}
            />
          </Form.Item>
          
        </Form>
      </Modal>

      <Modal
        title={"Change Order Status"}
        okButtonProps={{ disabled: !!!status }}
        open={!!statusModal}
        onOk={onStatusChange}
        okText="Change"
        cancelButtonProps={{
          color: "red",
          type: "ghost",
          danger: true,
        }}
        confirmLoading={loading}
        onCancel={() => {
          setStatusModal(false);
        }}
        destroyOnClose={true}
      >
        <div>
          <Form layout="vertical" onFinish={onStatusChange}>
            <Form.Item
              hasFeedback
              label="Status"
              name="status"
              initialValue={status}
              rules={[
                {
                  required: true,
                  message: "Status is required",
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                onChange={(val) => setStatus(val as string)}
                filterOption={() => {
                  return true;
                }}
              >
                {Object.values(OrderTypeArray)?.map((type, i) => (
                  <Option value={type} key={i}>
                    {type?.split("_").join(" ")}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item hasFeedback label="Note" name="note">
              <Input.TextArea
                onChange={(val) => setNote(val?.target.value)}
                rows={5}
                id="note"
                placeholder="Write some note"
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Modal
        title={
          <div className="d-flex-l">
            <FontAwesomeIcon
              icon={faBiking}
              className="mr-2"
              style={{ color: "#e73d42" }}
            />{" "}
            Assign a Delivery Man
          </div>
        }
        okButtonProps={{ disabled: !!!selectedDeliveryManId }}
        open={!!assignment}
        onOk={assignDeliveryHero}
        okText="Assign"
        cancelButtonProps={{
          color: "red",
          type: "ghost",
          danger: true,
        }}
        confirmLoading={loading}
        onCancel={() => {
          setDeliveryManData({ loading: false, list: [] });
          setSelectedDeliveryManId("");
          setAssignment(false);
        }}
        destroyOnClose={true}
      >
        <div>
          <Select
            showSearch
            style={{ width: "100%", marginBottom: 15 }}
            placeholder="Search by mobile number"
            optionFilterProp="children"
            onChange={(val) => setSelectedDeliveryManId(val as string)}
            onSearch={(e) => handleSearch(e, "driver")}
            filterOption={() => {
              return true;
            }}
            options={deliveryManData?.list}
          ></Select>
        </div>
      </Modal>

      <Modal
        title={
          <div className="d-flex-l">
            <FontAwesomeIcon
              icon={faTruckDroplet}
              className="mr-2"
              style={{ color: "#e73d42" }}
            />{" "}
            Assign a Courier Comany
          </div>
        }
        open={!!assignCourierModel}
        okText="Assign"
        onOk={assignCourierComapny}
        cancelButtonProps={{
          color: "red",
          type: "ghost",
          danger: true,
        }}
        confirmLoading={loading}
        onCancel={() => {
          setDeliveryManData({ loading: false, list: [] });
          setSelectedDeliveryManId("");
          setAssignCourierModel(false);
        }}
        destroyOnClose={true}
      >
        <div>
          <Form name="courier-assign" layout="vertical">
            <Form.Item label="Select Carrier">
              <Select
                allowClear
                showSearch
                placeholder="Select Carrier"
                optionFilterProp="children"
                onChange={(val) => setCarrierId(val)}
                onSearch={(e) => handleSearch(e, "carrier")}
                filterOption={() => {
                  return true;
                }}
                options={carrierOptions.list}
              ></Select>
            </Form.Item>

            {carrierId === "E_COURIER" && (
              <>
                <Form.Item
                  hasFeedback
                  label="Parcel Type"
                  name="parcelType"
                  initialValue={parcelType}
                  rules={[
                    {
                      required: true,
                      message: "Parcel Type is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    onChange={(val) => setParcelType(val as string)}
                    filterOption={() => {
                      return true;
                    }}
                  >
                    {Object.values(jcParcelTypeArray)?.map((type, i) => (
                      <Option value={type} key={i}>
                        {type?.split("_").join(" ")}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}

            {carrierId === "E_COURIER" && (
              <>
                <Form.Item label="Select Package">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Package"
                    optionFilterProp="children"
                    onChange={(val) => setPackageCode(val)}
                    onSearch={(e) => handleSearch(e, "package")}
                    filterOption={() => {
                      return true;
                    }}
                    options={packageOptions.list}
                  ></Select>
                </Form.Item>
              </>
            )}

            {carrierId === "PATHAO" && (
              <>
                <Form.Item
                  hasFeedback
                  label="Delivery Type"
                  rules={[
                    {
                      required: true,
                      message: "Delivery Type is Required!",
                    },
                  ]}
                  name="delivery_type"
                  initialValue={deliveryType}
                >
                  <Select
                    options={[
                      { value: "", label: "Select Delivery Type" },
                      { value: "48", label: "Normal Delivery" },
                      { value: "12", label: "On Demand Delivery" },
                    ]}
                    placeholder="Enter Delivery Type"
                    onChange={(val) => setDeliveryType(val)}
                  />
                </Form.Item>
              </>
            )}

            {carrierId === "PATHAO" && (
              <>
                <Form.Item
                  hasFeedback
                  label="Item Type"
                  rules={[
                    {
                      required: true,
                      message: "Item Type is Required!",
                    },
                  ]}
                  name="item_type"
                  initialValue={itemType}
                >
                  <Select
                    options={[
                      { value: "", label: "Select Item Type" },
                      { value: "1", label: "Document" },
                      { value: "2", label: "Parcel" },
                    ]}
                    placeholder="Enter Item Type"
                    onChange={(val) => setItemType(val)}
                  />
                </Form.Item>
              </>
            )}
            {carrierId === "PATHAO" && (
              <>
                <Form.Item label="Select Store">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Store"
                    optionFilterProp="children"
                    onChange={(val) => setPathaoStoreId(val)}
                    onSearch={(e) => handleSearch(e, "store")}
                    filterOption={() => {
                      return true;
                    }}
                    options={storeOptions.list}
                  ></Select>
                </Form.Item>
              </>
            )}

            {carrierId === "PATHAO" && (
              <>
                <Form.Item
                  hasFeedback
                  label={`City`}
                  rules={[
                    {
                      required: true,
                      message: "City is required!",
                    },
                  ]}
                  name="city"
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select City"
                    optionFilterProp="children"
                    onChange={(val) => setCityId(val)}
                    onSearch={(val) => {
                      handleSearch?.(val, "city");
                    }}
                    filterOption={() => {
                      return true;
                    }}
                    options={cityOptions?.list}
                  ></Select>
                </Form.Item>
              </>
            )}

            {carrierId === "PATHAO" && (
              <>
                <Form.Item name="zone_search" label="Zone">
                  <Select
                    allowClear
                    showSearch
                    placeholder={`Filter by Zone`}
                    optionFilterProp="children"
                    onChange={(val) => setZoneId(val)}
                    onSearch={(e) => handleSearch(e, "zone")}
                    filterOption={() => {
                      return true;
                    }}
                    options={zoneOptions?.list}
                  ></Select>
                </Form.Item>
              </>
            )}

            {carrierId === "PATHAO" && (
              <>
                <Form.Item
                  hasFeedback
                  label={`Area`}
                  rules={[
                    {
                      required: false,
                      message: "Area is required!",
                    },
                  ]}
                  name="area"
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select area"
                    optionFilterProp="children"
                    onChange={(val) => setAreaId(val)}
                    onSearch={(val) => {
                      handleSearch?.(val, "area");
                    }}
                    filterOption={() => {
                      return true;
                    }}
                    options={areaOptions?.list}
                  ></Select>
                </Form.Item>
              </>
            )}

            {(carrierId === "PATHAO" || carrierId === "STEAD_FAST") && (
              <>
                <Form.Item hasFeedback label="Note" name="note">
                  <Input.TextArea
                    onChange={(val) => setCourierNote(val?.target.value)}
                    rows={5}
                    id="note"
                    placeholder="Write some note"
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      </Modal>
      <Modal
        centered
        title={
          <div className="d-flex-l">
            <FontAwesomeIcon
              icon={faDownload}
              className="mr-2"
              style={{ color: "#e73d42" }}
            />{" "}
            Download Invoice
          </div>
        }
        width={800}
        visible={!!showInoice}
        // onOk={() => print(['1'])}
        okText={
          <PDFDownloadLink
            document={
              <OrderInvoice
                orderDetails={singleOrderInfo}
                selectedProducts={selectedProducts}
              />
            }
            fileName="order.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download PDF"
            }
          </PDFDownloadLink>
        }
        cancelButtonProps={{
          type: "primary",
          danger: true,
        }}
        // confirmLoading={loading}
        onCancel={() => {
          setShowInvoice(false);
        }}
        destroyOnClose={true}
      >
        <div>
          <PDFViewer
            showToolbar={false}
            style={{ width: "100%", minHeight: 600, border: 0 }}
          >
            <OrderInvoice
              orderDetails={singleOrderInfo}
              selectedProducts={selectedProducts}
            />
          </PDFViewer>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ReturnOrderExtra;
