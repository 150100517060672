import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Select, Tooltip } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import ImageUploader from "../../common/ImageUploader";
import SubmitResetBtn from "../../common/SubmitBtn";
const GiftCardTemplateCategory = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [bannerImgUrl, setBannerImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();


  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload,startUpload2]);

  const onSubmit = async (data: any) => {
      setLoading(true);
      const readyData = data && {
        type: type || data?.type,
        title: data.title,
        position: data.position,
        keyword: data.keyword,
        metaTitle: data.metaTitle,
        metaDescription: data.metaDescription,
        description: data.description,
        bannerImage: bannerImgUrl,
        image: generatedImgUrl,
      };

      if (visibleData) {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/gift-card-template-category`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: visibleData?.id,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification(
                "Gift Card Template Category Updated Successfully",
                "success"
              );
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      } else {
        await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/gift-card-template-category`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        })
          .then((res) => res.json())
          .then((res) => {
            setLoading(false);

            if (res.statusCode === 200) {
              responseNotification("Gift Card Template Category Create Successfully", "success");
              form.resetFields();
              if (onCloseMethod) {
                onCloseMethod();
              }
            } else if (res.status === 500) {
              responseNotification("Internal server error", "error");
            } else {
              responseNotification(res.message || "something wrong", "warning");
            }
          })
          .catch((err) => {
            setLoading(false);
            responseNotification(`${"Internal server error"} ${err}`, "error");
            console.error("err", err);
          });
      }
 
  };

  const fetchRef = useRef(0);
 
  

  useEffect(() => {
    if (visibleData) {
      setGeneratedImgUrl(visibleData?.image);
      setBannerImgUrl(visibleData?.bannerImage);
    }
  }, [visibleData]);



  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(undefined);
    setBannerImgUrl(undefined);
    setStartUpload(undefined);
    setStartUpload2(undefined);
  };

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          initialValues={{
            ...visibleData,
            type: type || visibleData?.type,
          }}
        >
          <div className="grid grid-cols-2 xl:gap-x-4">
            <Form.Item
              hasFeedback
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Title is Required!",
                },
              ]}
              name="title"
            >
              <Input id="title" type="text" placeholder="Enter Title" />
            </Form.Item>
            <Form.Item
              hasFeedback
              label="Position"
              rules={[
                {
                  required: true,
                  message: "Position is Required!",
                },
              ]}
              name="position"
            >
              <Input id="position" type="text" placeholder="Enter Position" />
            </Form.Item>
            <div className="col-span-2 mb-6">
              <Form.Item hasFeedback label="Keyword" name="keyword">
                <Input.TextArea
                  rows={5}
                  id="keyword"
                  placeholder="Enter Keyword"
                />
              </Form.Item>
              <Form.Item hasFeedback label="Meta Title" name="metaTitle">
              <Input id="metaTitle" type="text" placeholder="Enter Meta Title" />
            </Form.Item>
              <Form.Item hasFeedback label="Meta Description" name="metaDescription">
                <Input.TextArea
                  rows={5}
                  id="metaDescription"
                  placeholder="Enter Meta Description"
                />
              </Form.Item>
              <Form.Item hasFeedback label="description" name="description">
                <Input.TextArea
                  rows={5}
                  id="description"
                  placeholder="Enter Description"
                />
              </Form.Item>

              <Row gutter={20} className="mb-6">
          <Col span={12}>
            <Divider orientation="left">
              Image
              <Tooltip
                placement="bottom"
                title={
                  "Image should be square. We prefer height 200px and width 200px (Ratio 1)"
                }
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              imgUrl={generatedImgUrl || ""}
              startUpload={startUpload}
              setStartUpload={(val: any) => setStartUpload(val)}
              setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
              // crop={1}
            />
          </Col>
          <Col span={12}>
            <Divider orientation="left">
              Banner Image
              <Tooltip
                placement="bottom"
                title={"Banner image should 250px/1600px (Ratio 6.4)"}
              >
                <QuestionCircleOutlined className="px-2 -mt-2" />
              </Tooltip>
            </Divider>
            <ImageUploader
              // crop={6.4}
              imgUrl={bannerImgUrl || ""}
              startUpload={startUpload2}
              setStartUpload={(val: any) => setStartUpload2(val)}
              setGeneratedImgUrl={(url: any) => setBannerImgUrl(url)}
            />
          </Col>
        </Row>
            </div>
          </div>
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
            loading={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
          />
        </Form>
      </div>
    </div>
  );
};

export default GiftCardTemplateCategory;
