import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Select, Tooltip } from "antd";
import axios from "axios";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
const { Option } = Select;

const AddUser = ({ UserDetails, onCloseMethod }: any): ReactElement => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [adminData, setAdminData] = useState<any>(undefined);
  const [adminGroup, setAdminGroup] = useState([]);
  const [error, setError] = useState("");
  const [userGroup, setUserGroup] = useState([]);
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();

  const [groupOptions, setGroupOptions] = useState({
    loading: false,
    list: [],
  });

  // ******************************
  const fetchUserRoleGroupDetails = async () => {
    if (UserDetails?.merchantId) {
      await fetch(
        `${process.env.REACT_APP_AUTH_API}/admin/merchant/get-merchant-groups?merchantId=${UserDetails?.merchantId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            form.resetFields();
            //setAdminData(res?.admin);
            //setGeneratedImgUrl(res?.admin?.profilePicture);
            setAdminGroup(
              res?.merchantUserGroups?.map((group: { groupId: any }) => group?.groupId)
            );
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  // ******************************
  const getGroupOptions = useCallback(async (key?: string) => {
    setGroupOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_AUTH_API}`;
    return axios
      .get(
        `${encodedUri}/merchant-group/all?type=${type}` + (key ? `&name=${key}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setGroupOptions({
          loading: false,
          list: res.data?.merchantGroups?.map((group: { name: any; id: any }) => ({
            label: group.name,
            value: group.id,
          })),
        });
      })
      .catch((err) => {
        setGroupOptions({ loading: false, list: [] });
        console.error("Groups: Error", err);
      });
  }, []);

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form.submit();
    }
  }, [form, startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      merchantGroupActives: userGroup.map((item) => ({
        groupId: item,
        isActive: true,
      })),
    };

    if (adminData) {
      await fetch(`${process.env.REACT_APP_AUTH_API}/merchant-user-group`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          merchantId: adminData?.merchantId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("merchant Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_AUTH_API}/merchant-user-group`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("merchant Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  // ****************************************
  // const handleSearch = React.useMemo(() => {
  //   const loadOptions = (value: string, field: string) => {
  //     fetchRef.current += 1;
  //     const fetchId = fetchRef.current;

  //     if (fetchId !== fetchRef.current) {
  //       return;
  //     }
  //     if (value) {
  //       if (field === "group") getGroupOptions(value);
  //     }
  //   };

  //   return debounce(loadOptions, 800);
  // }, [getGroupOptions]);
  // ****************************************

  useEffect(() => {
    getGroupOptions();
  }, [getGroupOptions]);

  useEffect(() => {
    if (adminData || adminGroup) {
      form.resetFields();
      // setLogoUrl(visibleData?.logo);
    }
  }, [adminData, adminGroup, UserDetails]);

  useEffect(() => {
    if (UserDetails) {
      setAdminData(UserDetails);
    }
  }, [UserDetails]);

  useEffect(() => {
    if (UserDetails){
      fetchUserRoleGroupDetails();
    } 
  }, [UserDetails]);

  return (
    <div className="">
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        // requiredMark="optional"
        autoComplete="off"
        initialValues={{
          ...adminData,
          name: adminData?.fullName,
          groupId: adminGroup,
        }}
      >
       


        <Form.Item
          hasFeedback
          label={`Groups`}
          name="groupId"
          initialValue={adminGroup}
        >
          <Select
            defaultValue={adminGroup}
            allowClear
            showSearch
            placeholder="Select Group"
            optionFilterProp="children"
            onChange={(val) => {
              setUserGroup(val);
            }}
            filterOption={() => {
              return true;
            }}
            mode="multiple"
          >
            {groupOptions?.list?.map((group: any, i) => (
              <Option value={group?.value} key={i}>
                {group?.label}
              </Option>
            ))}
          </Select>
        </Form.Item>



        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddUser;
