import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  SearchOutlined,
  SwapOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Popconfirm,
  Select,
  Skeleton,
  Switch,
} from "antd";
import axios from "axios";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import BreadCrumb from "../../Layouts/Breadcrumb";
import { responseNotification } from "../../../utils/notify";
import { getPage, getParamValue } from "../../../utils";
import { useLocation, useNavigate } from "react-router";
import styles from "../../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import _ from "lodash";
import moment from "moment";
import { isAllowedService } from "../../../utils/services";
import React from "react";
import DirectoryTree from "antd/es/tree/DirectoryTree";
import { AddBtn, LoaderFull, Pagination } from "../../common";
const { RangePicker } = DatePicker;
const { Option } = Select;

const CampaignList = (): ReactElement => {
  const [form] = Form.useForm();
  const [key, setKey] = useState("");
  const fetchRef = useRef(0);
  const navigate = useNavigate();
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = 16;
  const [visible, setVisible] = useState<any>(undefined);
  const [campaignType, setCampaignType] = useState();
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(undefined);
  const [showSearch, setShowSearch] = useState(true);
  const [reArrangeDrawer, setReArrangeDrawer] = useState<any>(undefined);
  const [isFlashSale, setIsFlashSale] = useState<any>();
  const [isActive, setIsActive] = useState<any>(undefined);
  const [sortBy, setSortBy] = useState<any>("createdAt");
  const [sortOrder, setSortOrder] = useState<any>("DESC");
  const [campaignData, setCampaignData] = useState<any>({
    loading: false,
    data: null,
  });
  const [campaignOptions, setCampaignOptions] = useState({
    loading: false,
    list: [],
  });

  const getCampaigns = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setCampaignData({ loading: true, data: null });

    const encodedUrl = `${process.env.REACT_APP_CATALOG_READER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/campaign?type=${type}` +
          (key ? `&name=${key}` : ``) +
          (sortBy ? `&sortBy=${sortBy}` : ``) +
          (campaignType ? `&campaignType=${campaignType}` : ``) +
          (from ? `&startDate=${from}` : ``) +
          (to ? `&endDate=${to}` : ``) +
          (sortOrder ? `&sortOrder=${sortOrder}` : ``) +
          (isActive ? `&isActive=${isActive}` : ``) +
          (isFlashSale ? `&isFlashSale=${isFlashSale}` : ``) +
          (page
            ? `&page=${
                page == campaignData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCampaignData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setCampaignData({ loading: false, data: [] });
        console.error("Type: Error", err);
      });
  }, [
    key,
    campaignType,
    isFlashSale,
    sortBy,
    sortOrder,
    isActive,
    page,
    limit,
    range.from,
    range.to,
  ]);

  const getCampaignOptions = useCallback(async (key?: string) => {
    setCampaignOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/admin/campaign?type=${type}` + (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCampaignOptions({
      loading: false,
      list: res?.data?.campaigns?.map((campaign: any) => ({
        label: campaign.name,
        value: campaign.name,
      })),
    });
  }, []);
  //
  const changeOrder = async (pos: any, node: any) => {
    let curr: any;
    let item;

    campaignData?.data?.campaigns?.find((campaign: any, i: string) => {
      if (campaign?.id === node?.key) {
        curr = i;
        item = campaign;
      }
    });

    const formated =
      curr !== pos &&
      _.concat(
        curr < pos
          ? campaignData?.data?.campaigns
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(0, pos - 1)
          : campaignData?.data?.campaigns?.slice(0, pos),
        item,
        curr > pos
          ? campaignData?.data?.campaigns
              ?.filter((_: any, i: any) => i !== curr)
              ?.slice(pos, campaignData?.data?.campaigns?.length - 1)
          : campaignData?.data?.campaigns?.slice(
              pos,
              campaignData?.data?.campaigns?.length
            )
      )?.map((item, i) => ({
        id: item?.id,
        displayOrder: i + 1,
      }));

    if (formated) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/update-campaign-display-order`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayOrders: formated,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          // setLoading(false);

          if (res.statusCode === 200) {
            getCampaigns();
            responseNotification("Position Updated Successfully", "success");
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onStatusChange = async (id: number, val: boolean) => {
    setLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/campaign`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getCampaigns();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const onBrandMoveToTop = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/update-campaign-display-order`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayOrders: [
              {
                id: id,
                isSendTop: true,
              },
            ],
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              "Campaign Move To Top Successfully",
              "success"
            );
            getCampaigns();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const onAdditionalPriceRemove = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/campaign/remove-all-additional-price?campaignId=${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification(
              "Remove All Additional Price Successfully",
              "success"
            );
            setVisible(undefined);
            setVisiblePopconfirm(undefined);
            getCampaigns();
             
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "campaign") getCampaignOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getCampaignOptions]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  useEffect(() => {
    if (showSearch) {
      getCampaignOptions();
    }
  }, [showSearch]);

  const onClose = () => {
    getCampaigns();
    setVisible(undefined);
    setReArrangeDrawer(undefined);
  };

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setVisiblePopconfirm(undefined);
  };
  const reseAllFieldData = () => {
    setIsFlashSale(false);
    setSortBy("");
    setSortOrder("");
    setIsActive("");
    form.resetFields();
  };

  return (
    <>
      <BreadCrumb
        title="Campaign List"
        subTitle={`${
          campaignData?.data?.totalElements ||
          campaignData?.data?.campaigns?.length
        } Campaign(s)`}
        extra={[
          <Button
            type="dashed"
            shape="circle"
            onClick={() => setShowSearch(!showSearch)}
            key={1}
          >
            <SearchOutlined />
          </Button>,
          <Button
            shape="round"
            type="primary"
            className="bg-blue-600"
            onClick={() => setReArrangeDrawer(true)}
          >
            <SwapOutlined />
          </Button>,
          isAllowedService(`Promotion Campaign`) && (
            <AddBtn
              onClick={() => navigate("/promotion/campaign/add")}
              key={2}
            />
          ),
        ]}
      />

      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="campaign_search">
              <Select
                allowClear
                showSearch
                placeholder="Filter by Campaign"
                optionFilterProp="children"
                onChange={(val) => setKey(val)}
                onSearch={(e) => handleSearch(e, "campaign")}
                filterOption={() => {
                  return true;
                }}
                options={campaignOptions?.list}
              ></Select>
            </Form.Item>

            <Form.Item name="campaign_type">
              <Select
                onChange={(val) => setCampaignType(val)}
                options={[
                  { value: "DISCOUNT", label: "DISCOUNT" },
                  { value: "QUNTITY", label: "QUNTITY" },
                  { value: "BOGO", label: "BUY ONE GET ONE" },
                  { value: "GIFT_ITEMS", label: "GIFT ITEMS" },
                  { value: "GROUP_BUY", label: "GROUP BUY" },
                  {
                    value: "PURCHASE_VOUCHER",
                    label: "PURCHASE VOUCHER",
                  },
                  { value: "PROMO", label: "PROMO" },
                  { value: "MEMBERSHIP_CARD", label: "MEMBERSHIP CARD" },
                  { value: "POINT_CODE", label: "POINT CODE" },
                  { value: "CASH_BACK", label: "CASH BACK" },
                  { value: "GIFT_CARD", label: "GIFT CARD" },
                  { value: "FREE_DELIVERY", label: "FREE DELIVERY" },
                ]}
                placeholder="Enter Campaign Type"
              />
            </Form.Item>
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="deleted_search"
              initialValue={
                isActive == "true"
                  ? "ACTIVE"
                  : isActive == "false"
                  ? "INACTIVE"
                  : "ALL"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setIsActive(val as string)}
              >
                <Select.Option value={undefined}>ALL</Select.Option>
                <Select.Option value={"true"} title="ACTIVE">
                  ACTIVE
                </Select.Option>
                <Select.Option value={"false"} title="INACTIVE">
                  INACTIVE
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="orderP_by_search"
              initialValue={
                sortBy == "displayOrder"
                  ? "DISPLAY ORDER"
                  : sortBy == "createdAt"
                  ? "CREATED AT"
                  : sortBy == "updatedAt"
                  ? "UPDATED AT"
                  : "SORTING BY"
              }
            >
              <Select
                showSearch
                placeholder="Status"
                onChange={(val) => setSortBy(val as string)}
              >
                <Select.Option value={undefined}>SORTING BY</Select.Option>
                <Select.Option value={"displayOrder"} title="DISPLAY ORDER">
                  DISPLAY ORDER
                </Select.Option>
                <Select.Option value={"createdAt"} title="CREATED AT">
                  CREATED AT
                </Select.Option>
                <Select.Option value={"updatedAt"} title="UPDATED AT">
                  UPDATED AT
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sorting_order_search"
              initialValue={
                sortOrder == "ASC"
                  ? "ASC"
                  : sortOrder == "DESC"
                  ? "DESC"
                  : "SORTING ORDER"
              }
            >
              <Select
                showSearch
                placeholder="sorting order"
                onChange={(val) => setSortOrder(val as string)}
              >
                <Select.Option value={undefined}>SORTING ORDER</Select.Option>
                <Select.Option value={"ASC"} title="ASC">
                  ASC
                </Select.Option>
                <Select.Option value={"DESC"} title="DESC">
                  DESC
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {confirmLoading && loading && <LoaderFull />}
              {campaignData?.loading ? (
                <LoaderFull />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Campaign</th>
                      {/* <th scope="col">Total Product</th>
                      <th scope="col">Total Order</th> */}
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      {isAllowedService(`Promotion Campaign`) && (
                        <>
                          <th scope="col">Move TO Top</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {campaignData?.data?.campaigns?.length ? (
                      campaignData?.data?.campaigns?.map(
                        (campaign: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100 cursor-pointer"
                            key={index}
                            onClick={() =>
                              navigate(
                                `/promotion/campaign/${campaign?.id}/products`
                              )
                            }
                            draggable="true"
                          >
                            <td>
                              <div className="flex items-center">
                                <Avatar.Group
                                  maxCount={3}
                                  size="large"
                                  maxStyle={{
                                    color: "#f56a00",
                                    backgroundColor: "#fde3cf",
                                  }}
                                >
                                  <Avatar
                                    style={{
                                      backgroundColor:
                                        campaign?.colorCode || "#f56a00",
                                    }}
                                  />
                                  <Avatar src={campaign?.bannerWeb} />
                                  <Avatar src={campaign?.banner} />
                                </Avatar.Group>
                                <div className="flex flex-col ml-2">
                                  <span className="font-medium text-gray-500">
                                    {campaign?.name}
                                  </span>
                                  <small className="font-light text-gray-500">
                                    {campaign?.campaignType
                                      ?.split("_")
                                      .join(" ")}
                                  </small>
                                </div>
                              </div>
                            </td>
                            {/* <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {campaign?.numberOfProducts}
                              </span>
                            </td> */}
                            {/* <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {campaign?.totalOrders}
                              </span>
                            </td> */}
                            <td>{moment(campaign?.startAt).format("lll")}</td>
                            <td>{moment(campaign?.endAt).format("lll")}</td>

                            {isAllowedService(`Promotion Campaign`) && (
                              <>
                                <td>
                                  <Button
                                    shape="round"
                                    type="primary"
                                    danger
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onBrandMoveToTop(campaign?.id);
                                    }}
                                  >
                                    <ToTopOutlined />
                                  </Button>
                                </td>
                                <td>
                                  <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={campaign?.isActive}
                                    onChange={(val, e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onStatusChange(campaign?.id, val);
                                    }}
                                    loading={
                                      loading && campaign?.id === loading
                                    }
                                    title="Active/Inactive"
                                  />
                                </td>
                                <td>
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      navigate(
                                        `/promotion/campaign/${campaign?.id}/edit`
                                      );
                                    }}
                                  >
                                    <EditOutlined />
                                  </Button>
                                  <Popconfirm
                                    placement="left"
                                    title="Are you sure to remove?"
                                    visible={campaign?.id === visiblePopconfirm}
                                    onConfirm={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onAdditionalPriceRemove(campaign?.id);
                                    }}
                                    okButtonProps={{
                                      loading: confirmLoading,
                                      className: "bg-blue-400",
                                    }}
                                    onCancel={handleCancel}
                                  >
                                    <Button
                                      className="bg-white"
                                      type="dashed"
                                      danger
                                      shape="round"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        showPopconfirm(campaign?.id);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </Button>
                                  </Popconfirm>

                                </td>
                              </>
                            )}
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...campaignData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
      <Drawer
        title="Re Arrange Campaign"
        placement="right"
        onClose={onClose}
        open={reArrangeDrawer}
        width={450}
      >
        {campaignData?.loading ? (
          <Skeleton />
        ) : campaignData?.data?.campaigns?.length ? (
          <DirectoryTree
            draggable
            showLine
            multiple
            showIcon={false}
            switcherIcon={<DownOutlined />}
            treeData={campaignData?.data?.campaigns.map((campaign: any) => ({
              title: campaign?.name,
              key: campaign?.id,
            }))}
            onDrop={(e: any) => {
              changeOrder(e.dropPosition, e.dragNode);
            }}
          />
        ) : (
          <Empty />
        )}
      </Drawer>
    </>
  );
};

export default CampaignList;
