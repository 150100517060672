import {
  IdcardOutlined,
  PushpinOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

const DriverSidebar = ({ driverId }: any): ReactElement => {
  const pathname = window.location?.pathname;

  return (
    <>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4">
        <div className="flow-root">
          <h3 className="text-xl font-bold mb-4">Menus</h3>

          <Menu mode="vertical">
            <Menu.Item
              key="/details"
              className={`hover:text-white hover:bg-red-100 ${
                pathname.includes("/details") ? "active bg-gray-100" : ""
              }`}
              icon={<IdcardOutlined />}
            >
              <Link
                to={`/delivery-shipping/drivers/${driverId}/details`}
                className="hover:text-white"
              >
                Basic Information
              </Link>
            </Menu.Item>
            <Menu.Item
              key="/vehicle-info"
              icon={<WarningOutlined />}
              className={pathname.includes("/vehicle-info") ? "active" : ""}
            >
              <Link to={`/delivery-shipping/drivers/${driverId}/vehicle-info`}>
              Vehicle Info
              </Link>
            </Menu.Item>
            <Menu.Item
              key="/unsettle-order"
              icon={<WarningOutlined />}
              className={pathname.includes("/unsettle-order") ? "active" : ""}
            >
              <Link to={`/delivery-shipping/drivers/${driverId}/unsettle-order`}>
                Un-Settled Order
              </Link>
            </Menu.Item>
            <Menu.Item
              key="/settlement-requested"
              icon={<WarningOutlined />}
              className={pathname.includes("/settlement-requested") ? "active" : ""}
            >
              <Link to={`/delivery-shipping/drivers/${driverId}/settlement-requested`}>
              Settlement Requested
              </Link>
            </Menu.Item>
            <Menu.Item
              key="/orders"
              icon={<ShoppingCartOutlined />}
              className={pathname.includes("/orders") ? "active" : ""}
            >
              <Link to={`/delivery-shipping/drivers/${driverId}/orders`}>
                Delivery History
              </Link>
            </Menu.Item>
            

            <Menu.Item
              key="/transactions"
              icon={<SolutionOutlined />}
              className={pathname.includes("/transactions") ? "active" : ""}
            >
              <Link to={`/delivery-shipping/drivers/${driverId}/transactions`}>
                Transaction History
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/location-history"
              icon={<PushpinOutlined />}
              className={pathname.includes("/location-history") ? "active" : ""}
            >
              <Link to={`/delivery-shipping/drivers/${driverId}/location-history`}>
                Location History
              </Link>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default DriverSidebar;
