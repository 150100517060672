import { ReactElement } from "react";
import { Avatar, Image } from "antd";
import React from "react";

const SingleGiftCardTemplateCategory = ({ GitfCardTemplateCategoriesDetails }: any): ReactElement => {
  return (
    <>
      <div className="">
        <Image
          // height={140}
          width="auto"
          src={GitfCardTemplateCategoriesDetails?.image}
          className="rounded-lg shadow-lg"
        />
        <div className="flex flex-no-wrap py-2 mt-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Title</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{GitfCardTemplateCategoriesDetails?.title}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-no-wrap py-2 mt-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Keyword</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{GitfCardTemplateCategoriesDetails?.keyword}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Meta Title</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{GitfCardTemplateCategoriesDetails?.metaTitle}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Meta Description</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{GitfCardTemplateCategoriesDetails?.metaDescription}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2 mt-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Description</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{GitfCardTemplateCategoriesDetails?.description}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleGiftCardTemplateCategory;
