import React from "react";
import MainLayout from "../../../components/Layouts";
import GiftCardTemplateList from "../../../components/Promotions/GiftCardTemplate/index";

const GiftCardTemplatePage = () => {
  return (
    <MainLayout>
      <GiftCardTemplateList />
    </MainLayout>
  );
};

export default GiftCardTemplatePage;
