import {
  IdcardOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { ReactElement } from "react";
import { Link, useParams } from "react-router-dom";

const CustomerSidebar = ({ userId }: any): ReactElement => {
  const pathname = window.location?.pathname;
  const route = useParams();
  const userID = (route as any)?.userId;
  return (
    <>
      <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
        <div className="flow-root">
          <h3 className="text-xl font-bold mb-4">Menus</h3>

          <Menu mode="vertical">
            <Menu.Item
              key="/details"
              className={`hover:text-white hover:bg-red-100 ${
                pathname.includes("/details") ? "active bg-gray-100" : ""
              }`}
              icon={<IdcardOutlined />}
            >
              <Link
                to={`/customer/${userID}/details`}
                className="hover:text-white"
              >
                Basic Information 
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/order-history"
              className={`hover:text-white hover:bg-red-100 ${
                pathname.includes("/order-history") ? "active bg-gray-100" : ""
              }`}
              icon={<ShoppingCartOutlined />}
            >
              <Link 
              to={`/customer/${userID}/order-history`}
              className="hover:text-white">Order History</Link>
            </Menu.Item>
            <Menu.Item
              key="/user-service"
              className={`hover:text-white hover:bg-red-100 ${
                pathname.includes("/user-service") ? "active bg-gray-100" : ""
              }`}
              icon={<ShoppingCartOutlined />}
            >
              <Link 
              to={`/customer/${userID}/user-service`}
              className="hover:text-white">User Service</Link>
            </Menu.Item>

            {/* <Menu.Item
              key="/transactions"
              icon={<SolutionOutlined />}
              className={pathname.includes("/transactions") ? "active" : ""}
            >
              <Link to="#">Transaction History</Link>
            </Menu.Item> */}
          </Menu>
        </div>
      </div>
    </>
  );
};

export default CustomerSidebar;
