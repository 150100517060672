import React from "react";
import MainLayout from "../../components/Layouts";
import SetteledRequestOrderList from "../../components/DeliveryShipping/CarrierSettleRequestOrder";

const SetteledRequestOrder = () => {
  return (
    <MainLayout>
      <SetteledRequestOrderList />
    </MainLayout>
  );
};

export default SetteledRequestOrder;
