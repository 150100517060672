import React from "react";
import MainLayout from "../../../components/Layouts";
import PopUpModalList from "../../../components/Promotions/PopUpModal/index";

const popupModalPage = () => {
  return (
    <MainLayout>
      <PopUpModalList />
    </MainLayout>
  );
};

export default popupModalPage;
