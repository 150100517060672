import React from "react";
import MainLayout from "../../../components/Layouts";
import GiftCardTemplateCategoryList from "../../../components/Promotions/GiftCardTemplateCategory/index";

const GiftCardTemplateCategoryPage = () => {
  return (
    <MainLayout>
      <GiftCardTemplateCategoryList />
    </MainLayout>
  );
};

export default GiftCardTemplateCategoryPage;
