import { Badge, Button, Drawer, Empty, Modal, Rate, Switch } from "antd";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import cn from "classnames";
import AddService from "./AddService";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { responseNotification } from "../../../utils/notify";
import { useLocation, useParams } from "react-router";
import _ from "lodash";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import CustomerLayout from "../Layout";
import { SET_USER_INFO } from "../../../redux/auth/authType";
import { getPage, getParamValue } from "../../../utils";
import styles from "../../../styles/tailwind/List.module.css";
import { AddBtn, Loader, PaginationThree } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBiking, faDownload } from "@fortawesome/free-solid-svg-icons";
import { CheckOutlined, CloseOutlined, EditOutlined, SnippetsOutlined } from "@ant-design/icons";
import ShopOrderInvoice from "../../Orders/shopOrderInvoice";
import BreadCrumb from "../../Layouts/Breadcrumb";

const CustomerService = (props: any): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const [type, setType] = useState(initialState?.type);
  const [orderType, setOrderType] = useState(initialState?.orderType);
  const [orderId, setOrderId] = useState("");
  const [shopId, setShopId] = useState<any>();
  const [visible, setVisible] = useState<any>(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [campaignId, setCampaignId] = useState<any>();
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [mobileNumber, setMobileNumber] = useState("");
  const [pmethodId, setPmethodId] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState(undefined);
  const [selectedBrandForEdit, setSelectedBrandForEdit] = useState(undefined);
  const [showShopOrderInoice, setShowShopOrderInvoice] = useState(false);
  const [singleUserInfo, setSingleUserInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const limit = getParamValue(loc.search, "limit");
  const route = useParams();
  const dispatch = useDispatch();
  const userId = (route as any)?.userId;
  const [customerServiceData, setCustomerServiceData] = useState<any>({
    loading: false,
    data: null,
  });
  const [shopOrderData, setShopOrderData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });

  const getServices = useCallback(async () => {
    setCustomerServiceData({ loading: true, data: null });
    const encodedUrl = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUrl}/admin/my-service/all?customerId=${userId}` +
          (orderType ? `&type=${orderType}` : ``) +
          (page
            ? `&page=${
                page == customerServiceData?.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (limit ? `&limit=${limit}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCustomerServiceData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setCustomerServiceData({ loading: false, data: [] });
        console.error("Order: Error", err);
      });
  }, [
    userId,
    limit,
    page,
    type,
    orderType,
  ]);
  const onStatusChange = async (id: any, val: any) => {
    setConfirmLoading(true);
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/admin/my-service`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          isActive: val,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Status Changed Successfully", "success");
            getServices();
            setVisible(false);
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    getServices();
  }, [getServices]);

  const onClose = () => {
    setSelectedOrder(undefined);
    setVisible(undefined);
    setSelectedOrder(undefined);
    setSelectedBrandForEdit(undefined);
  };

  const user = singleUserInfo?.data;
  return (
    <React.Fragment>
      <CustomerLayout userId={(route as any)?.userId as string}>
        <div className="content-body rounded-2xl">
          <div className="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 mb-6">
         
          <BreadCrumb
          title="Service information"
          extra={[
            <AddBtn onClick={() => setVisible(true)} key={2} />
          ]}
        />
   
            <div className={styles.contentWrapper}>
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div
                    className={
                      showSearch ? `content-body-withSearch` : `content-body`
                    }
                  >
                    {customerServiceData?.loading ? (
                      <Loader />
                    ) : (
                      <table className={styles.mainTable}>
                        <thead className="bg-white border-b">
                          <tr>
                            <th scope="col">Service Type</th>
                            <th scope="col">Status</th>
                            {/* <th scope="col">Action</th> */}
                          </tr>
                        </thead>

                        <tbody>
                          {customerServiceData?.data?.myServices?.length ? (
                            customerServiceData?.data?.myServices?.map(
                              (service: any, index: any) => (
                                <tr
                                  className={cn(
                                    `border-t hover:bg-gray-100 cursor-pointer`,
                                    {
                                      ["bg-red-50 border-b border-slate-50"]:
                                      service?.isPreOrder,
                                    }
                                  )}
                                  key={index}
                                  //   onClick={() =>
                                  //     navigate("")
                                  //   }
                                >
                                  <td>
                                    <span className="font-medium text-slate-700">
                                      {service?.typeName}
                                    </span>
                                  </td>
                                  <td>
                                  <Switch
                                  checkedChildren={<CheckOutlined />}
                                  unCheckedChildren={<CloseOutlined />}
                                  defaultChecked={service?.isActive}
                                  onChange={(val, e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onStatusChange(service?.id, val);
                                  }}
                                  loading={
                                    confirmLoading &&
                                    service?.id === confirmLoading
                                  }
                                />
                                  </td>
                                  {/* <td>
                                  <div className="flex gap-2">
                                  <Button
                                    shape="round"
                                    type="dashed"
                                    className="bg-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setSelectedBrandForEdit(service);
                                    }}
                                  >
                                    <EditOutlined />
                                  </Button>
                                  </div>
                                  </td> */}
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td>
                                <Empty />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>

              <PaginationThree
                {...customerServiceData?.data}
                limit={limit}
                page={getPage(loc.search)}
              />

<Drawer
        title={
          visible
            ? "Add Service"
            : selectedBrandForEdit
            ? "Edit Service"
            : "Service Details"
        }
        placement="right"
        onClose={onClose}
        open={visible || selectedBrand || selectedBrandForEdit}
        width={450}
      >
        {visible || selectedBrandForEdit ? (
          <AddService
            onCloseMethod={onClose}
            visibleData={selectedBrandForEdit}
          />
        ) : (
          ""
        )}
      </Drawer>
            </div>
          </div>
        </div>
      </CustomerLayout>
    </React.Fragment>
  );
};
CustomerService.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
};
export default CustomerService;
