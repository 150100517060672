import React from "react";
import { ReactElement } from "react";
import ShopSettleOrderList from "../../../components/Shop/Order/SettleRequestOrder";
import MainLayout from "../../../components/Layouts";

const ShopSettleOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <ShopSettleOrderList />
    </MainLayout>
  );
};

export default ShopSettleOrderPage;
