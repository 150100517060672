import { Button, Form, Input, Select } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import styles from "../../styles/tailwind/Add.module.css";
import React from "react";
import { debounce } from "lodash";
import axios from "axios";
import { useLocation } from "react-router";
import { getParamValue } from "../../utils";

const AddServiceType = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(200);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [businessTypeId, setBusinessTypeId] = useState<any>();
  const [serviceTypeId, setServiceTypeId] = useState<any>();
  const [serviceTypeData, setServiceTypeData] = useState<any>(undefined);
  const [businessTypeOptions, setBusinessTypeOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const [serviceTypeOptions, setServiceTypeOptions] = useState<any>({
    list: [],
    loading: false,
  });

  const getBusinessTypeOptions = useCallback(async (val?: string) => {
    setBusinessTypeOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/service-type-list?limit=${limit}` +
      (val ? `&name=${val}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setBusinessTypeOptions({
      loading: false,
      list: res?.data?.businessTypes?.map((businessType: any) => {
        return {
          value: businessType?.id,
          label: businessType?.name,
        };
      }),
    });
  }, []);

  const getServiceTypeOptions = useCallback(async (val?: string) => {
    setServiceTypeOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/service-type/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setServiceTypeOptions({
      loading: false,
      list: res?.data?.serviceTypes?.map((serviceType: any) => {
        return {
          value: serviceType?.value,
          label: serviceType?.name,
        };
      }),
    });
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      businessTypeId: businessTypeId,
      serviceTypeId: serviceTypeId,
    };

    if (serviceTypeData) {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/add-service-type`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...readyData,
            id: serviceTypeData?.id,
            deleted: false,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification(
              "Service Type Updated Successfully",
              "success"
            );
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(
        `${process.env.REACT_APP_CATALOG_WRITER_API}/shop/business-type`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(readyData),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Service Type Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "businessType") getBusinessTypeOptions(value);
          if (field === "serviceType") getServiceTypeOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getBusinessTypeOptions,getServiceTypeOptions, type]);


  useEffect(() => {
    getBusinessTypeOptions();
    getServiceTypeOptions();
  }, [getBusinessTypeOptions,getServiceTypeOptions]);


  useEffect(() => {
    if (visibleData) {
      setServiceTypeData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (serviceTypeData) {
      form.resetFields(Object.keys(visibleData));
    }

  }, [serviceTypeData, form, visibleData]);
  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className={styles.formStyles}
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...serviceTypeData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Business Type"
          rules={[
            {
              required: true,
              message: "Business Type is required!",
            },
          ]}
          name="businessTypeId"
        >
          <Select
            showSearch
            placeholder="Select Business Type"
            optionFilterProp="children"
            onSearch={(val) => {
              handleSearch(val, "businessType");
            }}
            onChange={(val) => setBusinessTypeId(val)}
            filterOption={() => {
              return true;
            }}
            options={businessTypeOptions?.list}
          ></Select>
        </Form.Item>

        <Form.Item
          hasFeedback
          label="Service Type"
          rules={[
            {
              required: true,
              message: "Service Type is required!",
            },
          ]}
          name="serviceTypeId"
        >
          <Select
            showSearch
            placeholder="Select Service Type"
            optionFilterProp="children"
            onSearch={(val) => {
              handleSearch(val, "serviceType");
            }}
            onChange={(val) => setServiceTypeId(val)}
            filterOption={() => {
              return true;
            }}
            options={serviceTypeOptions?.list}
          ></Select>
        </Form.Item>

        <div className="buttons-container">
          <Button
            disabled={loading || !!error}
            loading={(loading ? "loading" : undefined) as any}
            type="primary"
            htmlType="submit"
            className="add-submit-btn text-center mr-2"
          >
            Submit
          </Button>

          <Button
            //type="ghost"
            htmlType="button"
            onClick={() => {
              form?.resetFields();
            }}
            className="reset-submit-btn text-center mr-2"
          >
            Reset
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddServiceType;
