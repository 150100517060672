import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Select, Tooltip } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../../common";
import { jcTypeArray } from "../../../utils";
const placement = [
  {
    label: "BEST SELLER LEFT",
    value: "BEST_SELLER_LEFT",
  },
  {
    label: "BEST SELLER RIGHT ONE",
    value: "BEST_SELLER_RIGHT_ONE",
  },
  {
    label: "BEST SELLER RIGHT TWO",
    value: "BEST_SELLER_RIGHT_TWO",
  },
  {
    label: "SHOP MIDDLE",
    value: "SHOP_MIDDLE",
  },
  {
    label: "BRAND VIDEO",
    value: "BRAND_VIDEO",
  },
  {
    label: "BRAND MIDDLE",
    value: "BRAND_MIDDLE",
  },
  {
    label: "LIVE MIDDLE",
    value: "LIVE_MIDDLE",
  },
  {
    label: "HOME POSITION ONE",
    value: "HOME_POSITION_ONE",
  },
  {
    label: "SPONSORED PRODUCTS",
    value: "SPONSORED_PRODUCTS",
  },
  {
    label: "GROUP BUY ADS",
    value: "GROUP_BUY_ADS",
  },
  {
    label: "CATEGORY ADS",
    value: "CATEGORY_ADS",
  },
]; 

const AddPopUp = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [bannerData, setBannerData] = useState<any>(undefined);
  const [serviceType, setServiceType] = useState<any>("JC_COMMERCE");
  const [serviceTypeId, setServiceTypeId] = useState<any>();
  const [appsImgUrl, setAppsImgUrl] = useState<string>();
  const [webImgUrl, setWebImgUrl] = useState<string>();
  const [placementId, setPlacementId] = useState<any>();
  const [serviceTypeOptions, setServiceTypeOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form?.submit();
    }
  }, [startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      type: serviceTypeId,
      name: data.name,
      description: data.description,
      placement: placementId,
      appImage: appsImgUrl,
      image: webImgUrl ? webImgUrl : data?.image, 
    };

    if (visibleData) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/admin/popup`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: visibleData?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Popup Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/admin/popup`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Popup Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const getServiceTypeOptions = useCallback(async (val?: string) => {
    setServiceTypeOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_RIDER_API}/service-type/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setServiceTypeOptions({
      loading: false,
      list: res?.data?.serviceTypes?.map((serviceType: any) => {
        return {
          value: serviceType?.value,
          label: serviceType?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getServiceTypeOptions();
  }, [getServiceTypeOptions]);

  const fetchRef = useRef(0);

  useEffect(() => {
    if (visibleData) {
      form.resetFields();
    }
  }, [form, visibleData]);

  useEffect(() => {
    if (visibleData) {
        setBannerData(visibleData);
      setAppsImgUrl(visibleData?.appImage);
      setServiceType(visibleData?.type);
      setServiceTypeId(visibleData?.type);
      setPlacementId(visibleData?.placement);
    }
  }, [visibleData]);
  useEffect(() => {
    if (visibleData) {
      form.setFieldsValue({
        ...visibleData,
        image: visibleData.image,
      });
      setWebImgUrl(visibleData?.image); // Set web image URL properly
    }
  }, [visibleData, form]);

  const resetData = () => {
    form?.resetFields();
    setWebImgUrl(""); // Reset web image URL
    setStartUpload(undefined);
  };

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          initialValues={{
            ...visibleData,
            type: type || visibleData?.type,
          }}
        >
           <Form.Item
          hasFeedback
          label="Service Type"
          rules={[
            {
              required: !serviceTypeId,
              message: "Service Type is required!",
            },
          ]}
          name="serviceTypeId"
        >
          <Select
            showSearch
            placeholder="Select Service Type"
            optionFilterProp="children"
            defaultValue={serviceTypeId}
            onChange={(val) => setServiceTypeId(val)}
            options={serviceTypeOptions?.list}
          ></Select>
        </Form.Item>
          <Form.Item
            hasFeedback
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is Required!",
              },
            ]}
            name="name"
          >
            <Input id="name" type="text" placeholder="Enter Name" />
          </Form.Item>
          <Form.Item hasFeedback label="Placement" name="placement">
              <Select
                id="placement"
                placeholder="Enter Placement"
                defaultValue={placementId}
                onChange={(val) => setPlacementId(val)}
                options={placement}
              ></Select>
            </Form.Item>

          <div className="col-span-2 mb-6">
            <Form.Item hasFeedback label="Description" name="description">
              <Input.TextArea
                rows={5}
                id="description"
                placeholder="Enter Description"
              />
            </Form.Item>
            <Row gutter={20} className="mb-6">
              <Col span={12}>
                <Divider orientation="left">
                  Apps Image
                  <Tooltip
                    placement="bottom"
                    title={"Web image should 500px/180px (Ratio 2.8)"}
                  >
                    <QuestionCircleOutlined className="px-2 -mt-2" />
                  </Tooltip>
                </Divider>
                <ImageUploader
                  imgUrl={appsImgUrl || ""}
                  startUpload={startUpload}
                  setStartUpload={(val: any) => setStartUpload(val)}
                  setGeneratedImgUrl={(url: any) => setAppsImgUrl(url)}
                  // crop={2.8}
                />
              </Col>
              <Col span={12}>
                <Divider orientation="left">
                  Web Image
                  <Tooltip
                    placement="bottom"
                    title={"Web image should 1200px/750px (Ratio 1.6)"}
                  >
                    <QuestionCircleOutlined className="px-2 -mt-2" />
                  </Tooltip>
                </Divider>
                <ImageUploader
                  imgUrl={webImgUrl || ""}
                  startUpload={startUpload2}
                  setStartUpload={(val: any) => setStartUpload2(val)}
                  setGeneratedImgUrl={(url: any) => {
                    setWebImgUrl(url);
                  }}
                />
              </Col>
            </Row>
          </div>

          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
              setStartUpload2("Uploading");
            }}
            onClickReset={resetData}
            disabled={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
            loading={
              loading ||
              startUpload === "Uploading" ||
              startUpload2 === "Uploading" ||
              !!error
            }
          />
        </Form>
      </div>
    </div>
  );
};

export default AddPopUp;
