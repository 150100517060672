import React from "react";
import FileManagerList from "../../components/FileManagers/index";
import MainLayout from "../../components/Layouts";

const FileManagerPage = () => {
  return (
    <MainLayout>
      <FileManagerList />
    </MainLayout>
  );
};

export default FileManagerPage;
