import React from "react";
import MainLayout from "../../components/Layouts";
import UserDetails from "../../components/Users/Details";

const UserDetailsPage = () => {
  return (
    <MainLayout>
      <UserDetails />
    </MainLayout>
  );
};

export default UserDetailsPage;
