import React from "react";
import { Empty } from "antd";
import moment from "moment";
import { useState } from "react";
import Loading from "../../common/Loader";

import {
  Page,
  Text,
  View,
  Font,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import logo from "../../../images/logo.png";
import font from "../../../assets/fonts/NotoSansBengali.ttf";
// Create styles

Font.register({
  family: "Noto Sans Bengali",
  src: font,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: 20,
    fontFamily: "Noto Sans Bengali",
  },
  section: {
    flexGrow: 1,
  },
  headerArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 15,
  },
  headerLeft: {
    display: "flex",
    flexDirection: "row",
    width: "45%",
  },
  logo: {
    flexDirection: "row",
    height: 40,
    width: 125,
  },

  headerRight: {
    width: "45%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  shopName: {
    fontSize: 10,
    marginBottom: 5,
    textAlign: "right",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  shopText: {
    marginBottom: 3,
    fontSize: 10,
    fontWeight: 300,
    textAlign: "right",
    maxWidth: 200,
  },

  titleArea: {
    backgroundColor: "#E5E5E5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    marginBottom: 10,
  },
  title: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1,
  },

  orderDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 30,
    width: "100%",
  },

  middleLeft: {
    maxWidth: 300,
  },
  bilingTitle: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 5,
  },

  middleRight: {
    width: "45%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column",
  },

  userName: {
    fontSize: 10,
    marginBottom: 5,
    display: "flex",
    justifyContent: "flex-start",
  },
  userText: {
    marginBottom: 5,
    textAlign: "left",
    fontSize: 10,
    fontWeight: 200,
    display: "flex",
    justifyContent: "flex-start",
  },

  middleArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 15,
  },
  totalDue: {
    marginBottom: 0,
    color: "#ff4d4f",
    fontSize: 14,
  },

  productHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "#393E46",
    borderRadius: 3,
    padding: 8,
    color: "#fff",
    fontSize: 11,
  },
  productHeaderText: {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    flex: 1,
  },
  productList: {
    borderBottom: "1px solid #f6f6f5",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    padding: "5px 5px",
  },

  producDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  producText: {
    fontSize: 10,
    fontWeight: 400,
    textAlign: "right",
  },
  producName: {
    width: 100,
    fontSize: 10,
    fontWeight: 400,
    textAlign: "left",
  },
  producVariation: {
    width: 40,
    fontSize: 10,
    fontWeight: 400,
    textAlign: "left",
  },
  imageArea: {
    height: 50,
    width: 50,
    display: "flex",
    flexDirection: "column",
  },
  image: {
    flexDirection: "row",
    flex: 1,
    height: 35,
    width: 35,
  },

  acountSection: {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "row",
    padding: "10px 0",
  },
  acountArea: {
    textAlign: "right",
    marginBottom: 50,
  },

  acountAreaPara: {
    textAlign: "right",
    fontSize: 10,
    fontWeight: 400,
    marginBottom: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    flexDirection: "row",
    width: 180,
    padding: "0px 5px",
  },
  fromSmall: {
    fontSize: 10,
    fontWeight: 400,
    display: "flex",
    justifyContent: "flex-end",
  },

  footerArea: {
    textAlign: "left",
    width: "100%",
    // maxWidth: 500,
    display: "flex",
    alignSelf: "baseline",
    justifyContent: "flex-end",
  },
  footerText: {
    // display: "flex",
    flexDirection: "row",
    fontSize: 10,
    fontWeight: 200,
    marginTop: 1,
  },
  textRed: {
    color: "#cd113b",
  },
});

const OrderInvoice = ({ orderDetails, selectedProducts }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Document title="Order Invoice">
      <Page wrap={false} size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.headerArea}>
            <View style={styles.headerLeft}>
              <Image src={logo} style={styles.logo} />
            </View>

            <View style={styles.headerRight}>
              {/* <View>
                <Text style={styles.shopName}>jachai.com Ltd</Text>
              </View>
              <View>
                <Text style={styles.shopText}>
                  {orderDetails?.shop?.address}
                </Text>
              </View>
              <View>
                <Text style={styles.shopText}>
                  {orderDetails?.shop?.contactNumber.split("+88")}
                </Text>
              </View> */}
            </View>
          </View>

          <View style={styles.titleArea}>
            <Text style={styles.title}>RETURN ORDER INVOICE</Text>
          </View>

          <View style={styles?.orderDetails}>
            <View style={styles.middleLeft}>
              <View>
                <Text style={styles.bilingTitle}>Biling Address</Text>
              </View>
              <View>
                <Text style={styles.userName}>
                  {orderDetails?.customer?.name}
                </Text>
              </View>
              <View>
                <Text style={styles.userText}>
                  <span>Phone:</span>{" "}
                  {orderDetails?.customer?.mobileNumber.split("+88")}
                </Text>
              </View>
              <View>
                <Text style={styles.userText}>
                  <span>Address:</span> {orderDetails?.shippingAddress}
                </Text>
              </View>
            </View>

            <View style={styles?.middleRight}>
              <Text style={styles.userText}>
                Invoice No: {orderDetails?.orderId}
              </Text>
              <Text style={styles.userText}>
                Invoice Date: {moment().format("lll")}
              </Text>
              {/* <Text style={styles.userText}>Order No: {orderDetails?.id}</Text> */}
              <Text style={styles.userText}>
                Order Date: {moment(orderDetails?.createdAt).format("lll")}
              </Text>
            </View>
          </View>
          <View style={styles.middleArea}>
            <View>
              <Text style={styles.userText}>
                Type of Payment: {orderDetails?.paymentMethod}
              </Text>
            </View>
            <Text style={styles.totalDue}>
              DUE:
              {orderDetails?.total - orderDetails?.totalPaid}
            </Text>
          </View>
          {selectedProducts.loading ? (
            <Loading />
          ) : (
            <View>
              <View style={styles.productHeader}>
                <Text style={styles.producName}>Product</Text>
                <Text style={styles.producName}>Reason</Text>
                <Text style={styles.producVariation}>Variation</Text>
                <Text style={styles.productHeaderText}>Price</Text>
                <Text style={styles.productHeaderText}>Discounted Price</Text>
                <Text style={styles.productHeaderText}>Qty</Text>
                <Text style={styles.productHeaderText}>Total</Text>
              </View>

              {selectedProducts?.length ? (
                selectedProducts?.map((product, index) => (
                  <View key={index} style={styles.productList}>
                    {/* <View style={styles.imageArea}>
                      <Image
                        style={{ flex: 1, flexDirection: "row", display: "flex" }}
                        // style={styles.image}
                        // src={product?.productImage}
                        source={product?.productImage}
                      />
                    </View> */}

                    <View style={styles.producDetails}>
                      <Text style={styles.producName}>{product?.name}</Text>
                      <Text style={styles.producName}>{product?.returnReason}</Text>
                      <Text style={styles.producVariation}>
                        {product?.variation?.variationName}
                      </Text>
                      <Text style={styles.producText}>
                        {" "}
                        {product?.variation?.price?.mrp}
                        Tk
                      </Text>
                      <Text style={styles.producText}>
                        {" "}
                        {product?.variation?.price?.discountedPrice}
                        Tk
                      </Text>
                      <Text style={styles.producText}>
                        x{product?.quantity}
                      </Text>
                      <Text style={styles.producText}>
                        {product?.variation?.price?.discountedPrice *
                          product?.quantity}{" "}
                        Tk
                      </Text>
                    </View>
                  </View>
                ))
              ) : (
                <Empty />
              )}
            </View>
          )}
          <View style={styles.acountSection}>
            <View style={styles.acountArea}>
              <View style={styles.acountAreaPara}>
                <Text>Subtotal:</Text>
                <Text>{orderDetails?.subTotal} Tk</Text>
              </View>
              <View style={styles.acountAreaPara}>
                <Text>VAT:</Text>
                <Text>{orderDetails?.vat} Tk</Text>
              </View>
              <View style={styles.acountAreaPara}>
                <Text>Delivery Charge:</Text>
                <Text>{orderDetails?.deliveryCharge} Tk</Text>
              </View>
              {/* <View style={styles.acountAreaPara}>
                <Text>Discount:</Text>
                <Text>
                  {orderDetails?.discount - orderDetails?.promoDiscount} Tk
                </Text>
              </View> */}
              <View style={styles.acountAreaPara}>
                <Text>Promo Discount:</Text>
                <Text>{orderDetails?.promoDiscount} Tk</Text>
              </View>
              <View style={styles.acountAreaPara}>
                <Text>Total Paid:</Text>
                <Text>{orderDetails?.totalPaid} Tk</Text>
              </View>
              <View style={styles.acountAreaPara}>
                <Text>Total Due:</Text>
                <Text>{orderDetails?.total} Tk</Text>
              </View>
            </View>
          </View>

          <View style={styles.footerArea}>
            <View>
              <Text style={styles.footerText}>
                <b>*Shipping:</b> Shipping charges will be calculated based on
                the number of items, item type, weight and volume.{"\n"}
                Have a great day! Thank You for shopping on Jachai.com Ltd.
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default React.memo(OrderInvoice);
