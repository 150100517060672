import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Switch,
  Tabs,
  Upload,
} from "antd";
import BreadCrumb from "../Layouts/Breadcrumb";
import { responseNotification } from "../../utils/notify";
import { getImgUrl, getPage, getParamValue, jcTypeArray } from "../../utils";
import { useLocation } from "react-router";
import styles from "../../styles/tailwind/List.module.css";
import { useSelector } from "react-redux";
import moment from "moment";
import React from "react";
import { AddBtn, Loader, Pagination } from "../common";
import { debounce } from "lodash";
import { Image } from "antd";
interface FileType {
  originFileObj: any;
  uid: string;
  name: string;
  status: string;
  url: string;
}
const FileManagerList = (): ReactElement => {
  const { token } = useSelector((state) => (state as any)?.authReducer);
  const initialState = useSelector((state) => (state as any)?.authReducer);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [limit, setLimit] = useState(16);
  const [error, setError] = useState("");
  const [customFileName, setCustomFileName] = useState("");
  const [form] = Form.useForm();
  const [showSearch, setShowSearch] = useState(true);
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [key, setKey] = useState("");
  const [fileName, setFileName] = useState("");
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [directoryId, setDirectoryId] = useState<any>();
  const [fileDirectoryId, setFileDirectoryId] = useState<any>();
  const [fileManagerId, setFileManagerId] = useState<any>();
  const [altTag, setAltTag] = useState<any>();
  const [caption, setCaption] = useState<any>();
  const [updateFileName, setUpdateFileName] = useState<any>();
  const [singleFileInfo, setSingleFileInfo] = useState<any>({
    loading: false,
    data: null,
  });
  const [visiblePopconfirm, setVisiblePopconfirm] = useState(undefined);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [directoryOptions, setDirectoryOptions] = useState({
    loading: false,
    list: [],
  });
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const showPopconfirm = (id: any) => {
    setVisiblePopconfirm(id);
  };
  const [fileManagerData, setFileManagerData] = useState<any>({
    loading: false,
    data: null,
  });

  const [fileManagerOptions, setFileManagerOptions] = useState({
    loading: false,
    list: [],
  });

  const getFileManagers = useCallback(async () => {
    setFileManagerData({ loading: true, data: null });

    const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
    axios
      .get(
        `${encodedUri}/file-manager/all?limit=${limit}` +
          (page
            ? `&page=${
                page == fileManagerData.data?.currentPageNumber ? 0 : page || 0
              }`
            : ``) +
          (fileDirectoryId ? `&directoryName=${fileDirectoryId}` : ``) +
          (fileName ? `&key=${fileName}` : ``),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setFileManagerData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setFileManagerData({ loading: false, data: [] });
        console.error("Managers: Error", err);
      });
  }, [limit, page, fileDirectoryId, fileName]);

  const getFileManagerOptions = useCallback(
    async (key: any) => {
      setFileManagerOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url = `?page=0&limit=20` + (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/file-manager/all${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFileManagerOptions({
            loading: false,
            list: res.data?.files?.map(
              (fileManager: { fileName: any; id: any }) => ({
                label: fileManager.fileName,
                value: fileManager.fileName,
              })
            ),
          });
        })
        .catch((err) => {
          setFileManagerOptions({ loading: false, list: [] });
          console.error("FileManagers: Error", err);
        });
    },
    [key, page, limit]
  );
  const onFileManagerRemove = async (id: any) => {
    if (id) {
      await fetch(`${process.env.REACT_APP_RIDER_API}/file-directory`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("File Manager Remove Successfully", "success");
            getFileManagers();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [generatedUrl, setGeneratedUrl] = useState();

  const textRef = useRef<any>(null);

  const onChange = ({ fileList: newFileList }: any) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("file", fileList?.[0]?.originFileObj);
    formData.append("directoryName", directoryId);
    formData.append("customFileName", customFileName);
    await fetch(`${process.env.REACT_APP_RIDER_API}/file-manager/file-upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        setGeneratedUrl(res.url);
        setFileList([]);
        form.resetFields();
      })
      .catch((err) => {
        console.error("err", err);
      });
  };

  const getDirectoryOptions = useCallback(
    async (key: any) => {
      setDirectoryOptions({ loading: true, list: [] });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      let url = `?page=0&limit=20` + (key ? `&key=${key}` : ``);
      url = encodeURI(url);

      return axios
        .get(`${encodedUri}/file-directory/all${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDirectoryOptions({
            loading: false,
            list: res.data?.fileDirectories?.map((directory: any) => ({
              label: directory.name,
              value: directory.name,
            })),
          });
        })
        .catch((err) => {
          setDirectoryOptions({ loading: false, list: [] });
          console.error("Products: Error", err);
        });
    },
    [key]
  );

  const onFileManagerUpdate = async (id: any) => {
    if (id) {
      await fetch(
        `${process.env.REACT_APP_RIDER_API}/file-manager/update-info`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            altTag: altTag,
            caption: caption,
            fileName: updateFileName,
            isPrivate: isPrivate,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            responseNotification("Update Successfully", "success");
            getFileManagers();
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchCorporateDetails = useCallback(async () => {
    setSingleFileInfo({ loading: true, data: null });
    axios
      .get(
        `${process.env.REACT_APP_RIDER_API}/file-manager/details?id=${fileManagerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        if (data.status === 200) {
          setSingleFileInfo({
            loading: false,
            data: data?.data?.file,
          });
          setAltTag(singleFileInfo?.data?.altTag);
          setCaption(singleFileInfo?.data?.caption);
          setUpdateFileName(singleFileInfo?.data?.fileName);
          setIsPrivate(singleFileInfo?.data?.isPrivate);
        } else {
          setSingleFileInfo({ loading: false, data: null });
          responseNotification(
            data.statusText || "something went wrong",
            "error"
          );
        }
      })
      .catch((err) => {
        setSingleFileInfo({ loading: false, data: null });
      });
  }, [fileManagerId]);

  useEffect(() => {
    fetchCorporateDetails();
  }, [fetchCorporateDetails]);
  useEffect(() => {
    if (singleFileInfo?.data) {
      form.resetFields(Object.keys(singleFileInfo?.data));
      setAltTag(singleFileInfo?.data?.altTag);
      setCaption(singleFileInfo?.data?.caption);
      setUpdateFileName(singleFileInfo?.data?.fileName);
      setIsPrivate(singleFileInfo?.data?.isPrivate);
    }
  }, [singleFileInfo?.data, form]);

  const fetchRef = useRef(0);
  const handleSearch = useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (value) {
        if (field === "fileManager") getFileManagerOptions(value);
        if (field === "directory") getDirectoryOptions(value);
        if (field === "filedirectory") getDirectoryOptions(value);
      }
    };

    return debounce(loadOptions, 800);
  }, [getFileManagerOptions, getDirectoryOptions]);

  useEffect(() => {
    if (showSearch) {
      getFileManagerOptions("");
    }
  }, [showSearch]);

  useEffect(() => {
    getFileManagers();
  }, [getFileManagers]);

  const onClose = () => {
    getFileManagers();
  };
  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(false);
    setVisiblePopconfirm(undefined);
  };
  const reseAllFieldData = () => {
    form.resetFields();
    setKey("");
    setFileName("");
    setGeneratedImgUrl(undefined);
  };

  useEffect(() => {
    getDirectoryOptions("");
  }, [getDirectoryOptions]);
  const success = () => {
    message.success("Image URL Copied!");
  };

  return (
    <>
      <BreadCrumb
        title="File Manager List"
        subTitle={`${
          fileManagerData?.data?.totalElements ||
          fileManagerData?.data?.files?.length ||
          0
        } Manager(s)`}
        extra={[<AddBtn onClick={showModal} key={2} />]}
      />
      {showSearch && (
        <div className={styles?.searchBox}>
          <Form layout="inline" form={form} className={styles.formInline}>
            <Form.Item name="search" className="pb-4">
              <AutoComplete
                style={{ width: 300 }}
                onSearch={(e) => handleSearch(e, "fileManager")}
                onSelect={(val: { toString: () => SetStateAction<string> }) =>
                  setKey(val?.toString())
                }
                options={fileManagerOptions?.list}
                defaultActiveFirstOption={false}
                notFoundContent={
                  fileManagerOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by Name"
                  onSearch={(val) => setKey(val)}
                  enterButton
                  loading={fileManagerOptions.loading}
                />
              </AutoComplete>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            danger
            size="large"
            htmlType="reset"
            onClick={reseAllFieldData}
          >
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div
              className={
                showSearch ? `content-body-withSearch` : `content-body`
              }
            >
              {fileManagerData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Name</th>
                      <th scope="col">File Type</th>
                      <th scope="col">Updated At</th>
                      <th scope="col">Updated By</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {fileManagerData?.data?.files?.length ? (
                      fileManagerData?.data?.files?.map(
                        (fileManager: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <Avatar size={40} src={fileManager?.fileUrl} />
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {fileManager?.fileName}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {fileManager?.fileType}
                              </span>
                            </td>

                            <td>
                              <small
                                className={`font-capitalize ${
                                  moment().diff(
                                    fileManager?.updatedAt,
                                    "minutes"
                                  ) >= 60
                                    ? "text-red-600"
                                    : "text-gray-800"
                                }`}
                              >
                                {moment(fileManager?.updatedAt).fromNow()}
                              </small>
                              <br />
                              <span className="name">
                                {moment(fileManager?.updatedAt).format("lll")}
                              </span>
                            </td>
                            <td>
                              <span className="font-medium text-gray-500 ml-2">
                                {fileManager?.updatedBy?.name ||
                                  fileManager?.updatedBy?.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <div className="flex gap-2">
                                <Popconfirm
                                  placement="left"
                                  title="Are you sure to remove?"
                                  visible={
                                    fileManager?.id === visiblePopconfirm
                                  }
                                  onConfirm={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onFileManagerRemove(fileManager?.id);
                                  }}
                                  okButtonProps={{
                                    loading: confirmLoading,
                                    className: "bg-blue-400",
                                  }}
                                  onCancel={handleCancel}
                                >
                                  <Button
                                    className="bg-white"
                                    type="dashed"
                                    danger
                                    shape="round"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      showPopconfirm(fileManager?.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...fileManagerData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />

        <Modal
          title={<div className="d-flex-l">File Manager</div>}
          open={isModalOpen}
          okText="Assign"
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={loading}
          destroyOnClose={true}
          width={"98%"}
        >
          <div>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Upload Files" key="1">
                <Form
                  name="control-hooks"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className={styles.formStyles}
                  onFinish={uploadImage}
                  form={form} // like ref
                  layout="vertical"
                  //   initialValues={{
                  //     ...directoryData,
                  //   }}
                >
                  <div className="w-1/4">
                    <Form.Item
                      hasFeedback
                      label="Custom File Name"
                      rules={[
                        {
                          required: false,
                          message: "Custom File Name is Required!",
                        },
                      ]}
                      name="customFileName"
                    >
                      <Input
                        id="customFileName"
                        type="text"
                        placeholder="Enter Custom File Name"
                        onChange={(e) => setCustomFileName(e.target.value)}
                      />
                    </Form.Item>
                  </div>
                  <div className="w-1/4">
                    <Form.Item name="directoryName" label="Directory Name">
                      <Select
                        allowClear
                        showSearch
                        placeholder={`Filter by Directory`}
                        optionFilterProp="children"
                        defaultValue={directoryId}
                        onChange={(val) => setDirectoryId(val)}
                        onSearch={(e) => handleSearch(e, "directory")}
                        options={directoryOptions?.list}
                      ></Select>
                    </Form.Item>
                  </div>
                  <div className="flex items-start p-8 pb-0">
                    <div className="mr-20">
                      <Form.Item name="image">
                        <Upload
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture-card"
                          onChange={onChange}
                          onPreview={onPreview}
                          multiple
                        >
                          {fileList.length < 1 && "+ Upload"}
                        </Upload>
                      </Form.Item>
                    </div>
                    {generatedUrl && (
                      <div className="flex items-start">
                        <div className="flex items-center d-flex-nowrap">
                          <img
                            src={getImgUrl(generatedUrl)}
                            alt=""
                            className="mr-2 rounded-md h-28"
                          />
                          <small ref={textRef}>{generatedUrl}</small>
                        </div>
                        <Button
                          type="dashed"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              textRef?.current?.innerText
                            );
                            success();
                          }}
                        >
                          <CopyOutlined title="Copy" />
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className="buttons-container">
                    <Button
                      disabled={loading || !!error}
                      loading={(loading ? "loading" : undefined) as any}
                      type="primary"
                      htmlType="submit"
                      className="add-submit-btn text-center mr-2"
                    >
                      Submit
                    </Button>

                    <Button
                      htmlType="button"
                      onClick={() => {
                        form?.resetFields();
                      }}
                      className="reset-submit-btn text-center mr-2"
                    >
                      Reset
                    </Button>
                  </div>
                </Form>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Media Library" key="2">
                <div className="w-2/3 float-left">
                  <div className={styles?.searchBox}>
                    <Form
                      layout="inline"
                      form={form}
                      className={styles.formInline}
                    >
                      <Form.Item name="fileDirectoryName">
                        <Select
                          allowClear
                          showSearch
                          placeholder={`Filter by Directory`}
                          optionFilterProp="children"
                          defaultValue={fileDirectoryId}
                          onChange={(val) => setFileDirectoryId(val)}
                          onSearch={(e) => handleSearch(e, "filedirectory")}
                          options={directoryOptions?.list}
                        ></Select>
                      </Form.Item>
                      <Form.Item name="search" className="pb-4">
                        <AutoComplete
                          style={{ width: 300 }}
                          onSearch={(e) => handleSearch(e, "fileManager")}
                          onSelect={(val: {
                            toString: () => SetStateAction<string>;
                          }) => setFileName(val?.toString())}
                          options={fileManagerOptions?.list}
                          defaultActiveFirstOption={false}
                          notFoundContent={
                            fileManagerOptions?.loading ? (
                              <Spin size="small" />
                            ) : null
                          }
                        >
                          <Input.Search
                            placeholder="Search by Name"
                            onSearch={(val) => setFileName(val)}
                            enterButton
                            loading={fileManagerOptions.loading}
                          />
                        </AutoComplete>
                      </Form.Item>
                    </Form>
                    <Button
                      type="primary"
                      danger
                      size="large"
                      htmlType="reset"
                      onClick={reseAllFieldData}
                    >
                      Reset
                    </Button>
                  </div>
                  <div>
                    {fileManagerData?.data?.files?.length ? (
                      fileManagerData?.data?.files?.map(
                        (fileManager: any, index: any) => (
                          <div className="m-2 float-left rounded-xl">
                            <img
                              src={getImgUrl(fileManager?.fileUrl)}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setFileManagerId(fileManager?.id);
                                //setSelectedFileForEdit(fileManager);
                              }}
                              className="mb-5 rounded-2xl _shadow-lg shadow-gray-300"
                            />
                          </div>
                        )
                      )
                    ) : (
                      <Empty />
                    )}
                  </div>
                </div>
                <div className="w-1/3 float-left p-8">
                  {singleFileInfo?.data && (
                    <>
                      <h1>Attachment Details</h1>
                      <div>
                        <img
                          src={getImgUrl(singleFileInfo?.data?.fileUrl)}
                          className="mb-5 rounded-2xl _shadow-lg shadow-gray-300"
                        />
                        <h1>{singleFileInfo?.data?.fileName}</h1>
                        <Form
                          name="control-hooks"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          className={styles.formStyles}
                          form={form} // like ref
                          layout="vertical"
                          initialValues={{
                            ...singleFileInfo?.data,
                          }}
                          autoComplete="off"
                        >
                          <Form.Item
                            hasFeedback
                            label="Alt Tag"
                            rules={[
                              {
                                required: false,
                                message: "Alt Tag is Required!",
                              },
                            ]}
                            name="altTag"
                          >
                            <Input
                              id="altTag"
                              type="text"
                              placeholder="Enter Alt Tag"
                              defaultValue={altTag}
                              onChange={(e) => setAltTag(e.target.value)}
                              onBlur={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onFileManagerUpdate(singleFileInfo?.data?.id);
                              }}
                            />
                          </Form.Item>

                          <Form.Item
                            hasFeedback
                            label="Title"
                            rules={[
                              {
                                required: false,
                                message: "Title is Required!",
                              },
                            ]}
                            name="fileName"
                          >
                            <Input
                              id="fileName"
                              type="text"
                              placeholder="Enter Title"
                              defaultValue={updateFileName}
                              onChange={(e) =>
                                setUpdateFileName(e.target.value)
                              }
                              onBlur={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onFileManagerUpdate(singleFileInfo?.data?.id);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            label="Caption"
                            rules={[
                              {
                                required: false,
                                message: "Caption is Required!",
                              },
                            ]}
                            name="caption"
                          >
                            <Input
                              id="caption"
                              type="text"
                              placeholder="Enter Caption"
                              defaultValue={caption}
                              onChange={(e) => setCaption(e.target.value)}
                              onBlur={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onFileManagerUpdate(singleFileInfo?.data?.id);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            label="IS Private"
                            name="isPrivate"
                          >
                            <Switch
                              checkedChildren={`true`}
                              unCheckedChildren={`false`}
                              defaultChecked={isPrivate}
                              onChange={() => setIsPrivate(!isPrivate)}
                              onBlur={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onFileManagerUpdate(singleFileInfo?.data?.id);
                              }}
                            />
                          </Form.Item>
                        </Form>
                      </div>
                    </>
                  )}
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default FileManagerList;
