import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import SingleReturnOrder from "../../components/Orders/ReturnDetails";
import React from "react";

const ReturnOrderDetailsPage = (): ReactElement => {
  return (
    <MainLayout>
      <SingleReturnOrder inShop={false} />
    </MainLayout>
  );
};

export default ReturnOrderDetailsPage;
