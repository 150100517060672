import { QuestionCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Input, Row, Select, Switch, Tooltip } from "antd";
import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../utils/notify";
import { ImageUploader, SubmitResetBtn } from "../common";
import JoditEditor from "jodit-react";
import { jodiConfig } from "../../utils/editor-config";
import { debounce } from "lodash";
import axios from "axios"; 

const AddCourseQuiz = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const editor = useRef(null);

  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload2, setStartUpload2] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [startUpload3, setStartUpload3] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();
  const [shopsOptions, setShopsOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [shopId, setShopId] = useState<any>("");
  const [programId, setProgramId] = useState<any>("");
  const [courseId, setCourseId] = useState<any>("");
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [courseQuizData, setCourseQuizData] = useState<any>(undefined);
  const [convertedText, setConvertedText] = useState("");
  const [programOptions, setProgramOptions] = useState<any>({
    list: [],
    loading: false,
  });
  const [courseOptions, setCourseOptions] = useState<any>({
    list: [],
    loading: false,
  });
  useEffect(() => {
    if (startUpload === "Uploaded" && startUpload2 === "Uploaded") {
      form?.submit();
    }
  }, [startUpload, startUpload2]);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const readyData = data && {
      name: data.name,
      numberOfQuestions: data.numberOfQuestions,
      description: convertedText || data.description,
      courseId: courseId || data.courseId,
      shopId: shopId || data.shopId,
      totalQuizTimeInSeconds: data.totalQuizTimeInSeconds,
    };
    if (courseQuizData) {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/course-quiz`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: courseQuizData?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Course Quiz Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_ELARNING_API}/admin/course-quiz`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Course Quiz Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  useEffect(() => {
    if (visibleData) {
      setCourseQuizData(visibleData);
    }
  }, [visibleData]);

  useEffect(() => {
    if (courseQuizData) {
      form.resetFields(Object.keys(visibleData));
    }
  }, [courseQuizData, form, visibleData]);

  const getProgramOptions = useCallback(async (key?: any) => {
    setProgramOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/program?type=${type}&page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setProgramOptions({
      loading: false,
      list: res?.data?.programs?.map((program: any) => {
        return {
          value: program?.id,
          label: program?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getProgramOptions();
  }, [getProgramOptions]);

  const getShopsOptions = useCallback(async (key?: any) => {
    setShopsOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_CATALOG_READER_API}/shop/admin/search?type=JC_COMMERCE&page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShopsOptions({
      loading: false,
      list: res?.data?.shops?.map((shop: any) => {
        return {
          value: shop?.id,
          label: shop?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getShopsOptions();
  }, [getShopsOptions]);
  const getCourseOptions = useCallback(async (key?: any) => {
    setCourseOptions({ loading: true, list: [] });

    const res = await axios.get(
      `${process.env.REACT_APP_ELARNING_API}/admin/course?type=${type}&page=0&limit=20` +
        (key ? `&name=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCourseOptions({
      loading: false,
      list: res?.data?.courses?.map((course: any) => {
        return {
          value: course?.id,
          label: course?.name,
        };
      }),
    });
  }, []);
  useEffect(() => {
    getCourseOptions();
  }, [getCourseOptions]);
  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }
      if (type) {
        if (value) {
          if (field === "program") getProgramOptions(value);
          if (field === "course") getCourseOptions(value);
          if (field === "shop") getShopsOptions(value);
        }
      } else {
        responseNotification("Select a type before search", "warning");
      }
    };

    return debounce(loadOptions, 800);
  }, [getProgramOptions, getCourseOptions, getShopsOptions, type]);
  const resetData = () => {
    form?.resetFields();
  };

  return (
    <>
      <Form
        name="control-hooks"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="ant-form ant-form-vertical"
        onFinish={onSubmit}
        form={form} // like ref
        layout="vertical"
        initialValues={{
          ...courseQuizData,
        }}
      >
        <Form.Item
          hasFeedback
          label="Name"
          rules={[
            {
              required: true,
              message: "Name is Required!",
            },
          ]}
          name="name"
        >
          <Input id="name" type="text" placeholder="Enter Name" />
        </Form.Item>
        <Form.Item hasFeedback label="Description" name="description">
          <JoditEditor
            ref={editor}
            value={convertedText}
            config={jodiConfig as any}
            onBlur={(newContent) => setConvertedText(newContent)} // preferred to use only this option to update the content for performance reasons
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          label="Number Of Questions"
          rules={[
            {
              required: true,
              message: "Number Of Questions Required!",
            },
          ]}
          name="numberOfQuestions"
        >
          <Input
            id="numberOfQuestions"
            type="text"
            placeholder="Enter Number Of Questions"
          />
        </Form.Item>

        <div className="">
          <Form.Item
            hasFeedback
            label="Course"
            rules={[
              {
                required: true,
                message: "Course is required!",
              },
            ]}
            name="courseId"
          >
            <Select
              showSearch
              placeholder="Select Course"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "course");
              }}
              onChange={(val) => setCourseId(val)}
              filterOption={() => {
                return true;
              }}
              options={courseOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <div className="">
          <Form.Item
            hasFeedback
            label="Organization"
            rules={[
              {
                required: true,
                message: "Organization is required!",
              },
            ]}
            name="shopId"
          >
            <Select
              showSearch
              placeholder="Select Organization"
              optionFilterProp="children"
              onSearch={(val) => {
                handleSearch(val, "shop");
              }}
              onChange={(val) => setShopId(val)}
              filterOption={() => {
                return true;
              }}
              options={shopsOptions?.list}
            ></Select>
          </Form.Item>
        </div>
        <Form.Item
          hasFeedback
          label="Total Quiz Time In Seconds"
          rules={[
            {
              required: true,
              message: "Total Quiz Time In Seconds Required!",
            },
          ]}
          name="totalQuizTimeInSeconds"
        >
          <Input
            id="totalQuizTimeInSeconds"
            type="text"
            placeholder="Enter Total Quiz Time In Seconds"
          />
        </Form.Item>

        <div className="mt-2">
          <SubmitResetBtn
            onClickReset={() => {
              form?.resetFields();
            }}
            disabled={loading || !!error}
            loading={loading}
          />
        </div>
      </Form>
    </>
  );
};

export default AddCourseQuiz;
