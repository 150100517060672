import MainLayout from "../../components/Layouts";
import { ReactElement } from "react";
import ReturnOrderList from "../../components/Orders/ReturnOrderList";
import React from "react";

const ReturnOrderPage = (): ReactElement => {
  return (
    <MainLayout>
      <ReturnOrderList />
    </MainLayout>
  );
}; 

export default ReturnOrderPage;
