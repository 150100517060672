import { QuestionCircleOutlined } from "@ant-design/icons";
import { Divider, Form, Input, Select, Tooltip } from "antd";
import axios from "axios";
import { debounce } from "lodash";
import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { responseNotification } from "../../../utils/notify";
import ImageUploader from "../../common/ImageUploader";
import SubmitResetBtn from "../../common/SubmitBtn";

const AddGioftCardTemplate = ({ visibleData, onCloseMethod }: any) => {
  const { type, token } = useSelector((state) => (state as any)?.authReducer);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [templateCategoryId, setTemplateCategoryId] = useState<any>();
  const [templateType, setTemplateType] = useState("STANDARD");
  const [generatedImgUrl, setGeneratedImgUrl] = useState<string>();
  const [startUpload, setStartUpload] = useState<
    "Initiated" | "Uploading" | "Uploaded"
  >();

  const [categorieOptions, setCategorieOptions] = useState({
    loading: false,
    list: [],
  });
  const getCategoriesOptions = useCallback(async (key?: string) => {
    setCategorieOptions({ loading: true, list: [] });
    const encodedUri = `${process.env.REACT_APP_CATALOG_READER_API}`;
    const res = await axios.get(
      `${encodedUri}/gift-card-template-category/all?page=0&limit=20` +
        (key ? `&key=${key}` : ``),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setCategorieOptions({
      loading: false,
      list: res?.data?.categories?.map((categorie: any) => ({
        label: categorie.title,
        value: categorie.id,
      })),
    });
  }, []);

  useEffect(() => {
    if (startUpload === "Uploaded") {
      form?.submit();
    }
  }, [startUpload]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const readyData = data && {
      name: data.name,
      defaultAmount: data.defaultAmount,
      templateCategoryId: templateCategoryId,
      templateType: templateType,
      url: generatedImgUrl,
    };

    if (visibleData) {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/gift-card-template`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...readyData,
          id: visibleData?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Git Card Template Updated Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    } else {
      await fetch(`${process.env.REACT_APP_CATALOG_WRITER_API}/gift-card-template`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(readyData),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);

          if (res.statusCode === 200) {
            responseNotification("Git Card Template Create Successfully", "success");
            form.resetFields();
            if (onCloseMethod) {
              onCloseMethod();
            }
          } else if (res.status === 500) {
            responseNotification("Internal server error", "error");
          } else {
            responseNotification(res.message || "something wrong", "warning");
          }
        })
        .catch((err) => {
          setLoading(false);
          responseNotification(`${"Internal server error"} ${err}`, "error");
          console.error("err", err);
        });
    }
  };

  const fetchRef = useRef(0);
  const handleSearch = React.useMemo(() => {
    const loadOptions = (value: string, field: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      if (fetchId !== fetchRef.current) {
        return;
      }

      if (value) {
         if (field === "categorie") getCategoriesOptions(value);
      }
    };

    return debounce(loadOptions, 1200);
  }, [getCategoriesOptions]);

  useEffect(() => {
    if (visibleData) {
      form.resetFields();
    }
  }, [form, visibleData]);

  useEffect(() => {
    if (visibleData) {
      setGeneratedImgUrl(visibleData?.url);
    }
  }, [visibleData]);

  useEffect(() => {
    getCategoriesOptions();
  }, [getCategoriesOptions]);

  const resetData = () => {
    form?.resetFields();
    setGeneratedImgUrl(undefined);
    setStartUpload(undefined);
  };

  return (
    <div className="add-product drawer-toggle-wrapper">
      <div className="drawer-toggle-inner-wrapper">
        <Form
          name="control-hooks"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="ant-form ant-form-vertical"
          onFinish={onSubmit}
          form={form} // like ref
          layout="vertical"
          autoComplete="off"
          initialValues={{
            ...visibleData,
            type: type,
          }}
        >
          <div className="grid grid-cols-2 xl:gap-x-4">
            <Form.Item
              hasFeedback
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Name is Required!",
                },
              ]}
              name="name"
            >
              <Input id="name" type="text" placeholder="Enter Name" />
            </Form.Item>

            <Form.Item hasFeedback label="Default Amount" name="defaultAmount">
              <Input
                id="defaultAmount"
                type="text"
                placeholder="Enter Default Amount"
              />
            </Form.Item>
            <Form.Item hasFeedback label="Template Category" name="templateCategoryId">
              <Select
                showSearch
                placeholder="Select brand"
                optionFilterProp="children"
                onSelect={(val: any) => setTemplateCategoryId(val)}
                onSearch={(val) => getCategoriesOptions(val)}
                filterOption={() => {
                  return true;
                }}
                options={categorieOptions.list}
              ></Select>
            </Form.Item>
            <Form.Item label="Template Type" name="templateType">
              <Select
                placeholder="Select Template Type"
                onChange={(val) => setTemplateType(val)}
              >
                <Option value="STANDARD">STANDARD</Option>
                <Option value="ANIMATION">ANIMATION</Option>
              </Select>
            </Form.Item>
            <div className="col-span-2 mb-6">
              <Divider orientation="left">
                Image
                <Tooltip
                  placement="bottom"
                  title={
                    "image must be transparent & max height 100px and width auto"
                  }
                >
                  <QuestionCircleOutlined className="px-2 -mt-2" />
                </Tooltip>
              </Divider>
              <ImageUploader
                imgUrl={generatedImgUrl || ""}
                startUpload={startUpload}
                setStartUpload={(val: any) => setStartUpload(val)}
                setGeneratedImgUrl={(url: any) => setGeneratedImgUrl(url)}
              />
            </div>
          </div>
          <SubmitResetBtn
            onClickSubmit={(e) => {
              e.preventDefault();
              setStartUpload("Uploading");
            }}
            onClickReset={resetData}
            disabled={loading || startUpload === "Uploading" || !!error}
            loading={loading || startUpload === "Uploading" || !!error}
          />
        </Form>
      </div>
    </div>
  );
};

export default AddGioftCardTemplate;
