import React from "react";
import MainLayout from "../../../components/Layouts";
import AdvertiseList from "../../../components/Promotions/Advertise";

const AdvertisePage = () => {
  return (
    <MainLayout>
      <AdvertiseList />
    </MainLayout>
  );
};

export default AdvertisePage;
