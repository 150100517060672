import { ReactElement } from "react";
import { Avatar, Image } from "antd";
import React from "react";

const SingleAdvertise = ({ TemplateDetails }: any): ReactElement => {
  return (
    <>
      <div className="">
        <Image
          // height={140}
          width="auto"
          src={TemplateDetails?.url}
          className="rounded-lg shadow-lg"
        />
        <div className="flex flex-no-wrap py-2 mt-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{TemplateDetails?.name}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleAdvertise;
