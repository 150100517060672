import { Image } from "antd";
import React from "react";
import { ReactElement } from "react";

const SinglePackage = ({
    packageDetails,
}: {
    packageDetails: any;
}): ReactElement => {
  return (
    <>
      <div className="">
        <div className="flex flex-no-wrap py-2 mt-8">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Package Name</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.name}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Package Unit  Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.packageUnitType}</span>
            </div>
          </div>
        </div>


        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Package Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.packageType}</span>
            </div>
          </div>
        </div>
<h1 className=" text-lg text-center">Package Size & Weight</h1>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Unit Per Box</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.packageSizeAndWeight?.unitPerBox}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Length</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.packageSizeAndWeight?.length}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Width</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.packageSizeAndWeight?.width}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Height</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.packageSizeAndWeight?.height}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Unit Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.packageSizeAndWeight?.unitType}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">unitPerBox</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.packageType}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Size Unit Type</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.packageSizeAndWeight?.sizeUnitType}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Who Preps Units</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.whoPrepsUnits}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-no-wrap py-2">
          <div className="w-2/5 flex-none">
            <div className="text-gray-500">
              <span className="font-bold">Who Labels Units</span>
            </div>
          </div>
          <div className="w-2/5 flex-none">
            <div className="text-gray-700">
              <span>{packageDetails?.whoLabelsUnits}</span>
            </div>
          </div>
        </div>



      </div>
    </>
  );
};

export default SinglePackage;
