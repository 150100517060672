import React from "react";
import DirectoryList from "../../components/FileDirectorys/index";
import MainLayout from "../../components/Layouts";

const FileDirectoryPage = () => {
  return (
    <MainLayout>
      <DirectoryList />
    </MainLayout>
  );
};

export default FileDirectoryPage;
