import { DatePicker, Empty, Avatar, Form, Button, AutoComplete, Input, Spin } from "antd";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import moment from "moment";
import BreadCrumb from "../Layouts/Breadcrumb";
import { getPage, getParamValue } from "../../utils";
import Loader from "../common/Loader";
import Pagination from "../common/Pagination/Pagination";
import styles from "../../styles/tailwind/List.module.css";
import React from "react";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
const { RangePicker } = DatePicker;

const MySettlementList = () => {
  const { token,type } = useSelector((state) => (state as any)?.authReducer);
  const [limit, setLimit] = useState(20);
  const loc = useLocation();
  const page = getParamValue(loc.search, "page");
  const [showSearch, setShowSearch] = useState(true);
  const [form] = Form.useForm();
  const [mobileNumber, setMobileNumber] = useState("");
  const [settlementData, setSettlementData] = useState<any>({
    loading: false,
    data: null,
  });
  const [range, setRange] = useState<any>({
    from: null,
    to: null,
  });
  const [driversOptions, setDriversOptions] = useState({
    loading: false,
    list: null,
  });
  const [selectedSettlement, setSelectedSettlement] = useState<any>(undefined);
  

  const getSettlements = useCallback(async () => {
    const from = moment(range.from)?.toISOString();
    const to = moment(range.to)?.toISOString();
    setSettlementData({ loading: true, data: null });
    const encodedUri = `${process.env.REACT_APP_ORDER_API}`;
    axios
      .get(`${encodedUri}/admin/settlement/settlement-requested-by-deliveryMan?type=${type}`+
        (mobileNumber
          ? `&deliveryManId=${mobileNumber}`
          : ``)+
        (from ? `&startAt=${from}` : ``) +
        (to ? `&endAt=${to}` : ``) +
        (page ? `&page=${page}` : ``) +
        (limit ? `&limit=${limit}` : ``), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSettlementData({ loading: false, data: res.data });
      })
      .catch((err) => {
        setSettlementData({ loading: false, data: [] });
        console.error("Drivers: Error", err);
      });
  }, [type,mobileNumber,range.from, range.to, page, limit]);
  const getDriversOptions = useCallback(
    async (mobileNumber: string) => {
      setDriversOptions({ loading: true, list: null });
      const encodedUri = `${process.env.REACT_APP_RIDER_API}`;
      axios
        .get(
          `${encodedUri}/admin/driver?` +
            (mobileNumber
              ? `&mobileNumberOrName=${mobileNumber?.replace("+88", "")}`
              : ``) +
            (status ? `&status=${status}` : ``) +
            `&page=${0}` +
            `&limit=${20}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setDriversOptions({
            loading: false,
            list: res.data?.drivers?.map((driver: any) => ({
              label: `${driver.mobileNumber.replace("+88", "")} - ${
                driver.name
              }`,
              value: driver.id,
            })),
          });
        })
        .catch((err) => {
          setDriversOptions({ loading: false, list: null });
          console.error("Drivers: Error", err);
        });
    },
    [status]
  );


  const fetchRef = useRef<any>(null);
  const getDriversOptionsDebounce = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setDriversOptions({ loading: false, list: null });
      if (fetchId !== fetchRef.current) {
        return;
      }
      getDriversOptions(value);
    };

    return debounce(loadOptions, 800);
  }, [getDriversOptions]);
  

  useEffect(() => {
    getSettlements();
  }, [getSettlements]);
  useEffect(() => {
    if (showSearch) {
      getDriversOptions("");
    }
  }, [showSearch]);
  const onClose = () => {
    setSelectedSettlement(null);
  };
  const reseAllFieldData = () => {
    form.resetFields();
    setMobileNumber("");
    setRange({
      from: null,
      to: null,
    });
  };
  return (
    <>
      <BreadCrumb
        title="My Settlement List"
        subTitle={`${settlementData?.data?.totalElements} Data(s)`}
      />
 {showSearch && (
        <div className={`${styles.searchBox}`}>
          <Form form={form} layout="inline" className={styles.formInline}>
          <Form.Item name="mobile">
              <AutoComplete
                dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={250}
                style={{ width: 250 }}
                onSearch={getDriversOptionsDebounce}
                onSelect={(val: any) => setMobileNumber(val?.toString())}
                options={driversOptions?.list || undefined}
                defaultActiveFirstOption={false}
                notFoundContent={
                  driversOptions?.loading ? <Spin size="small" /> : null
                }
              >
                <Input.Search
                  placeholder="Search by ID"
                  onSearch={(val) => setMobileNumber(val)}
                  enterButton
                  loading={driversOptions.loading}
                  size="large"
                />
              </AutoComplete>
            </Form.Item>
            
            <Form.Item name="range">
              <RangePicker
                defaultValue={
                  range.from != null && range.to != null
                    ? [moment(range.from), moment(range.to)]
                    : null
                }
                onChange={(e, f) => {
                  setRange({
                    from: moment.utc(f[0])?.toISOString(),
                    to: moment.utc(f[1])?.toISOString(),
                  });
                }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("23:59:59", "HH:mm:ss"),
                  ],
                }}
                inputReadOnly
                allowClear
              />
            </Form.Item>
          </Form>
          <Button type="primary" danger size="large" onClick={reseAllFieldData}>
            Reset
          </Button>
        </div>
      )}
      <div className={styles.contentWrapper}>
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div>
              {settlementData?.loading ? (
                <Loader />
              ) : (
                <table className={styles.mainTable}>
                  <thead className="bg-white border-b">
                    <tr>
                      <th scope="col">Delivery Hero</th>
                      <th scope="col">DH Contact</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {settlementData?.data?.settlementRequests?.length ? (
                      settlementData?.data?.settlementRequests?.map(
                        (settlement: any, index: any) => (
                          <tr
                            className="border-t hover:bg-gray-100"
                            key={index}
                          >
                            <td>
                              <span className="name">
                                <Avatar
                                  src={settlement?.deliveryMan?.profilePicture}
                                  size={45}
                                />{" "}
                                {settlement?.deliveryMan?.name}
                              </span>
                            </td>
                            <td>
                              <span className="name">
                                {settlement?.deliveryMan?.mobileNumber?.replace(
                                  "+88",
                                  ""
                                )}
                              </span>
                            </td>

                            <td>
                              <span className="name text-capitalize">
                                {moment(settlement?.createdAt).format("lll")}
                              </span>
                            </td>

                            <td>
                              <span className="name">
                                &#2547; {settlement?.amount}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`radius-btn ${settlement?.status?.toLowerCase()}-btn`}
                              >
                                {settlement?.status?.split("-").join(" ")}
                              </span>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td>
                          <Empty />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <Pagination
          {...settlementData?.data}
          limit={limit}
          page={getPage(loc.search)}
        />
      </div>
    </>
  );
};

export default MySettlementList;
