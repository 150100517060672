import React from "react";
import MainLayout from "../../components/Layouts";
import UserOrderHistory from "../../components/Users/OrderHistory/index";

const UserOrderHistoryPage = () => {
  return (
    <MainLayout>
      <UserOrderHistory />
    </MainLayout>
  );
};

export default UserOrderHistoryPage;
